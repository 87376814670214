import Promo from "components/cards/Promo";
import TokenSelector from "components/cards/TokenSelector";
import { Gift } from "components/icons";
import { Icon } from "components/icons/Icon";
import useTimeUntil from "hooks/useTimeUntil";
import { useAtom } from "jotai";
import React, { useState } from "react";
import {
  buyModalAtom,
  isLandAtom,
  isLkLandAtom,
  isPromoActiveAtom,
  referralCodeAtom,
  selectedTokenBalanceAtom,
  selectedTokenIdAtom,
  specificTotalAtom,
} from "store/atoms";

const Info = () => {
  const [selectedTokenId] = useAtom(selectedTokenIdAtom);
  const [selectedTokenBalance] = useAtom(selectedTokenBalanceAtom);
  const [referralCode] = useAtom(referralCodeAtom);
  const [buyModal, setBuyModal] = useAtom(buyModalAtom);
  const [isPromoActive, setIsPromoActive] = useAtom(isPromoActiveAtom);

  const [isLand] = useAtom(isLandAtom);
  const [isLkLand] = useAtom(isLkLandAtom);

  const [total] = useAtom(specificTotalAtom);

  const { tiles } = buyModal;

  function toFixedIfNecessary(value: number) {
    return +parseFloat(value.toString()).toFixed(4);
  }

  return (
    <>
      <ul>
        <li className="font-bold">
          <span>PRICE:</span>
          <span className="text-right">
            {/* @ts-ignore */}
            {tiles && (total / tiles.length).toFixed(5)}{" "}
            {selectedTokenId.split("-")[0]}
          </span>
        </li>
      </ul>
      <div className="z-[1000] flex items-end w-full gap-2 mt-auto">
        <TokenSelector />
      </div>
      <p>
        Balance: {selectedTokenBalance.toFixed(4)}{" "}
        {selectedTokenId.split("-")[0]}
      </p>
      <hr />
      {isPromoActive && <Promo isMini={false} />}
      <h3>
        TOTAL:{" "}
        <span className="text-purple">
          {referralCode ? (
            isLand || isLkLand ? (
              toFixedIfNecessary(total - 0.03 * total)
            ) : (
              toFixedIfNecessary(total)
            )
          ) : isPromoActive ? (
            <>
              <span className="ml-2 text-white">
                {toFixedIfNecessary(total - 0.15 * total)}{" "}
                {selectedTokenId.split("-")[0]}
              </span>
              <span className="ml-3 line-through text-gray">
                ({toFixedIfNecessary(total)} {selectedTokenId.split("-")[0]})
              </span>
            </>
          ) : (
            toFixedIfNecessary(total)
          )}{" "}
          {!isPromoActive && selectedTokenId.split("-")[0]}
        </span>
        {referralCode && (
          <p>2% cashback {!(isLkLand || isLand) && "in LAND"} applied!</p>
        )}
      </h3>
    </>
  );
};

export default Info;
