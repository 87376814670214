interface TinyPlaceCardProps {
  name: string;
  owner: string;
  tileCount: number;
  lastPrice: string;
  image: string;
}

const TinyPlaceCard = ({
  name,
  owner,
  tileCount,
  lastPrice,
  image,
}: TinyPlaceCardProps) => {
  return (
    <div className="tiny-place-card">
      <img src={image} alt={name} width={80} height={80} />
      {/* <span className="tiny-place-card__price">{lastPrice} LAND</span> */}
      <div className="tiny-place-card__info">
        {/* <span>{tileCount} tiles</span> */}
        <h2>{name}</h2>
        <span>Owner: {owner}</span>
      </div>
    </div>
  );
};

export default TinyPlaceCard;
