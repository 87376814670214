import { Icon } from "components/icons/Icon";
import { ReactNode, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { fadeInVariants } from "animation/variants";

interface CopyProps {
  children: ReactNode;
}

const CopyMe = ({ children }: CopyProps) => {
  const [hovered, setHovered] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleHover = () => {
    setHovered((h) => !h);
  };

  const handleCopy = () => {
    const textarea = document.createElement("textarea");
    // @ts-ignore
    textarea.value = children;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <div
      className="copy-container"
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      {children}
      <motion.button
        variants={fadeInVariants}
        initial="hidden"
        exit="hidden"
        animate={hovered ? "visible" : "hidden"}
        className="copy-container__icon"
        onClick={handleCopy}
      >
        <Icon name={copied ? "check" : "copy"} width={20} primary />
      </motion.button>
    </div>
  );
};

export default CopyMe;
