import toast from "react-hot-toast";
import TransactionNotification from "components/notifications";
import axios from "axios";
import { COLLECTION_ID, networkProvider } from "config";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";

interface GetLatestTransactionsType {
  apiAddress: string;
  address: string;
  contractAddress: string;
  timeout: number;
  page?: number;
  url?: string;
}

const fetchTransactions = (url: string) =>
  async function getTransactions({
    apiAddress,
    address,
    contractAddress,
    timeout,
  }: GetLatestTransactionsType) {
    try {
      const { data } = await axios.get(`${apiAddress}${url}`, {
        params: {
          sender: address,
          receiver: contractAddress,
          condition: "must",
          size: 25,
        },
        timeout,
      });

      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  };

export const getTransactions = fetchTransactions("/transactions");
export const getTransactionsCount = fetchTransactions("/transactions/count");

export const fetchTokens = (apiAddress: string, address: string) =>
  axios.get(`${apiAddress}/accounts/${address}/tokens?size=100`);

export const fetchNFTs = (apiAddress: string, address: string) =>
  axios.get(
    `${apiAddress}/accounts/${address}/nfts?collections=${COLLECTION_ID}`
  );

export const getBalance = async (address: string) => {
  const res = await axios.get(
    `${networkProvider["url"]}/accounts/${address}/tokens?size=100`
  );

  const metaRes = await axios.get(
    `${networkProvider["url"]}/accounts/${address}/nfts?type=MetaESDT&withScamInfo=false&computeScamInfo=false`
  );

  return { balance: res.data, metaBalance: metaRes.data };
};
