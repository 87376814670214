import CopyToClipboard from "components/buttons/CopyToClipboard";
import { Icon } from "components/icons/Icon";
import Input from "components/input";
import { useAtom } from "jotai";
import {
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { shareTileModalAtom } from "store/atoms";

const ShareTileCard = () => {
  const [shareTileModal, setShareTileModal] = useAtom(shareTileModalAtom);

  const link = window.location.href;
  const title =
    "Check out this tile listed on Landboard, the newest metaverse application in the Elrond ecosystem!";

  const handleClose = () => {
    setShareTileModal({ isOpen: false });
  };

  return (
    <div className="share-tile-card">
      <h2>Share this tile</h2>
      <button className="buy-place-card__close" onClick={handleClose}>
        <Icon width={28} name="close" primary />
      </button>
      <Input
        label="Tile Link"
        value={link}
        LabelButton={<CopyToClipboard text={link} />}
        readOnly
      />
      <p>Share this tile on social media in a couple clicks!</p>
      <div className="share-tile-card__socials">
        <TelegramShareButton url={link} title={title} className="">
          <TelegramIcon round size={50} />
        </TelegramShareButton>
        <TwitterShareButton
          url={link}
          title={`Check out this tile listed on @landboard_io, the newest metaverse application in the Elrond ecosystem!\n\n🟣`}
          className=""
          hashtags={["landboard", "metaverse"]}
        >
          <TwitterIcon round size={50} />
        </TwitterShareButton>
        <WhatsappShareButton
          url={link}
          title={title}
          separator=":: "
          className=""
        >
          <WhatsappIcon round size={50} />
        </WhatsappShareButton>
      </div>
    </div>
  );
};

export default ShareTileCard;
