import { fadeInVariants } from "animation/variants";
import { request } from "api/request";
import { getNFTs } from "api/supabase-client";
import axios from "axios";
import Button from "components/buttons";
import ImageCard from "components/cards/ImageCard";
import ShareTileCard from "components/cards/tiles/ShareTileCard";
import { Marketplace } from "components/icons";
import { Icon } from "components/icons/Icon";
import Input from "components/input";
import Spinner from "components/loading/Spinner";
import NotFound from "components/notFound";
import Select from "components/select";
import { Option } from "components/select/types";
import Table from "components/table";
import Tabs from "components/tabs";
import ImageUpload from "components/upload/ImageUpload";
import {
  API_URL,
  CONTRACT_ADDRESS,
  ENVIRONMENT,
  OLD_CONTRACT_ADDRESS,
  STORAGE_URL,
  XOXNO_ADDRESS,
  XOXNO_API,
} from "config";
import { AnimatePresence, motion } from "framer-motion";
import { useAtom } from "jotai";
import { cloneDeep } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage, useMedia } from "react-use";
import {
  isProfileOwnerAtom,
  loadingAtom,
  offerModalAtom,
  shareTileModalAtom,
  listModalAtom,
  startLocationAtom,
  userAtom,
  xoxnoDataAtom,
} from "store/atoms";
import { TabType } from "types/components";
import { notifySuccess } from "utils/notifications";

interface DetailsProps {
  name: string;
  tileValue?: string;
  owner: string;
  owner_address: string;
  link: string;
  address: string;
  center?: any[];
  views: number;
  rank?: number;
  image_nft_id?: string;
  identifier?: string;
}

const Details = ({
  owner,
  address,
  tileValue,
  name,
  link,
  center,
  views,
  rank,
  owner_address,
  image_nft_id,
  identifier,
}: DetailsProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm();

  const nft_id = watch("nft_id");

  const { id } = useParams();
  const navigate = useNavigate();

  const [user] = useAtom(userAtom);
  const [, setStartLocation] = useAtom(startLocationAtom);
  const [, setShareTileModal] = useAtom(shareTileModalAtom);

  const [tileImage, setTileImage] = useState(
    `${STORAGE_URL}/nft/${id}.webp?t=${Date.now()}`
  );
  const [nftOptions, setNftOptions] = useState<Option<null>[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const [imageNFTId, setImageNFTId] = useState(image_nft_id);
  const [isProfileOwner, setIsProfileOwner] = useAtom(isProfileOwnerAtom);

  useEffect(() => {
    setIsProfileOwner(user?.address === owner_address);
  }, [user, owner_address]);

  const handleGoToMap = () => {
    if (center) {
      setStartLocation(center);
      navigate("/");
    }
  };

  const handleUploadTileImage = (image: string, uploadAll?: boolean) => {
    setLoadingUpload(true);
    request(`/nfts/${uploadAll ? "multi-" : ""}upload`, {
      [uploadAll ? "address" : "quad_key"]: uploadAll ? address : id,
      image: image
        .replace("data:image/png;base64,", "")
        .replace("data:image/jpeg;base64,", ""),
    })
      .then((response) => {
        if (response) {
          setTileImage(`${STORAGE_URL}/nft/${id}.webp?t=${Date.now()}`);
          notifySuccess("Image updated.");
        }
      })
      .finally(() => setLoadingUpload(false));
  };

  const onSubmit = (data: any) => {
    const { name, link } = data;
    setLoading(true);
    request("/nfts", { name, link, quad_key: id }, "PUT")
      .then(() => {
        notifySuccess("Update tile succesful!");
      })
      .finally(() => setLoading(false));
  };

  const handleUpdateNFTImage = async () => {
    setLoadingUpload(true);

    request(`/nfts/nft-image`, {
      nft_id,
      quad_key: id,
    })
      .then((response) => {
        if (response) {
          setTileImage(`${STORAGE_URL}/nft/${id}.webp?t=${Date.now()}`);
          notifySuccess("Image updated.");
          setImageNFTId(nft_id);
        }
      })
      .finally(() => setLoadingUpload(false));
  };

  const handleShowShareModal = () => setShareTileModal({ isOpen: true });

  useEffect(() => {
    register("name");
    register("link");
    register("nft_id");
  }, []);

  useEffect(() => {
    setImageNFTId(image_nft_id);
  }, [image_nft_id]);

  useEffect(() => {
    if (owner_address != "") {
      axios
        .get(`${API_URL}/accounts/${owner_address}/nfts`)
        .then(({ data: nfts }) => {
          setNftOptions(
            nfts.map((nft: any) => ({
              value: nft.identifier,
              label: nft.name,
            }))
          );
        })
        .catch((err) => {});
    }
  }, [owner_address]);

  return (
    <div className="details">
      {!isProfileOwner && (
        <ImageCard
          image={tileImage}
          handleGoToMap={handleGoToMap}
          nftId={imageNFTId}
        />
      )}
      {isProfileOwner && (
        <div className="image-card-upload">
          <ImageUpload
            defaultImage={tileImage}
            onUpload={handleUploadTileImage}
            loading={loadingUpload}
            nftId={imageNFTId}
          />
          <div className="image-card__links">
            {handleGoToMap && (
              <button onClick={handleGoToMap}>
                <Icon name="map-location" primary />
              </button>
            )}
            <button onClick={handleShowShareModal}>
              <Icon name="share" primary />
            </button>
          </div>
        </div>
      )}
      {!isProfileOwner && (
        <div className="details__content">
          <h1>{name}</h1>
          <ul className="w-2/3">
            {rank && (
              <li>
                <Icon name="leaderboard" primary />
                <span>
                  Rank {rank} (top {((rank / 248845) * 100).toFixed(2)}%)
                </span>
              </li>
            )}
            <li>
              <Icon name="user" primary />
              <span>
                Owned by{" "}
                <a target="_blank" href={`/users/${owner}`} className="lnk">
                  {owner}
                </a>
              </span>
            </li>
            {tileValue && (
              <li>
                <Icon name="land" primary />
                {tileValue}
              </li>
            )}
            {address && (
              <li>
                <Icon name="map-pin" primary />
                <span>{address}</span>
              </li>
            )}
            {link && (
              <li>
                <Icon name="chrome" primary />
                <a className="lnk" href={link}>
                  {link}
                </a>
              </li>
            )}
            <li>
              <Icon name="eye" primary />
              <span>{views > 0 ? views : "No "} views</span>
            </li>

            <div className="flex justify-between w-full mt-6">
              {identifier && (
                <div className="w-3/3">
                  <Button
                    className="filled"
                    newTab
                    external={`https://xoxno.com/nft/${identifier}`}
                  >
                    <div className="flex items-center justify-center m-2">
                      <Icon name="market" width={25} />{" "}
                      <p style={{ marginLeft: 10 }}>View on XOXNO</p>
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </ul>
        </div>
      )}
      {isProfileOwner && (
        <form onSubmit={handleSubmit(onSubmit)} className="details__form">
          <Input
            label="Tile Name"
            defaultValue={name}
            placeholder="Unnamed Land"
            onChange={(e) => setValue("name", e.target.value)}
          />
          <Input
            label="Link"
            defaultValue={link}
            placeholder="https://yourbusiness.com/"
            onChange={(e) => setValue("link", e.target.value)}
          />
          <Button
            containerClassname="flex-1 w-fit"
            className="filled"
            type="submit"
            disabled={loading}
            hideComingSoon
          >
            {!loading && <Icon name="save" />}
            <AnimatePresence>{loading && <Spinner />}</AnimatePresence>
            Save
          </Button>

          {nftOptions.length > 0 && (
            <>
              <div className="flex items-end w-full gap-5 mt-5">
                <Select
                  options={nftOptions}
                  defaultValue={nftOptions.find(
                    (o) => o.value === image_nft_id
                  )}
                  label="NFT as image"
                  onChange={(option) => setValue("nft_id", option.value)}
                />
                <Button
                  containerClassname="w-fit"
                  className="outline"
                  type="button"
                  onClick={handleUpdateNFTImage}
                  disabled={loadingUpload}
                  hideComingSoon
                >
                  <AnimatePresence>
                    {loadingUpload && <Spinner />}
                  </AnimatePresence>
                  {loadingUpload ? "Updating" : "Update"}
                </Button>
              </div>
              <span className="text-purple">
                Update all your tiles in one click with your favorite NFT.
              </span>

              {identifier && (
                <Button
                  className="filled"
                  newTab
                  external={`https://xoxno.com/collection/${
                    identifier?.split("-")[0]
                  }-${identifier?.split("-")[1]}?activeNFTId=${identifier}`}
                >
                  View tile on XOXNO
                </Button>
              )}
            </>
          )}
        </form>
      )}
    </div>
  );
};

const Offers = (isOwner: any) => {
  const [offerModal, setOfferModal] = useAtom(offerModalAtom);
  const [xoxnoData, setXoxnoData] = useAtom(xoxnoDataAtom);
  const [canBuyNow, setCanBuyNow] = useState(false);
  const [buyNowInfo, setBuyNowInfo] = useState({ price: 0, token: "" });
  const [isProfileOwner, setIsProfileOwner] = useAtom(isProfileOwnerAtom);
  const [listModal, setListModal] = useAtom(listModalAtom);

  const [offers, setOffers] = useState([]);

  const handleMakeOffer = () => {
    setOfferModal({ ...offerModal, isOpen: true });
  };

  useEffect(() => {
    // @ts-ignore
    if (xoxnoData.onSale) {
      setCanBuyNow(true);
      setBuyNowInfo({
        // @ts-ignore
        price: xoxnoData.saleInfoNft.min_bid_short,
        // @ts-ignore
        token: xoxnoData.saleInfoNft.accepted_payment_token,
      });
    }
  }, [xoxnoData]);

  const data = useMemo(
    () => [
      {
        price: "250 LAND",
        expiration: "in 21 days",
        from: "ultra.fox",
      },
    ],
    []
  );

  const columns = useMemo<any>(
    () => [
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Expiration",
        accessor: "expiration",
      },
      {
        Header: "From",
        accessor: "from",
      },
    ],
    []
  );

  return (
    <div className="offers">
      <div className="offers__header">
        <Icon name="tag2" primary />
        <h1>Offers</h1>

        {!isProfileOwner && (
          <Button className="filled" onClick={handleMakeOffer}>
            <Icon name="discount" />
            Make Offer
          </Button>
        )}
      </div>

      {canBuyNow && (
        <>
          <div className="flex items-center justify-between">
            <p>Buy now price:</p>
            <div>
              {buyNowInfo.price} {buyNowInfo.token}
            </div>
          </div>
          <Button>Buy now</Button>
        </>
      )}
      {isProfileOwner && (
        <Button
          onClick={(e: any) => {
            e.preventDefault();
            setListModal({ ...listModal, isOpen: true });
          }}
        >
          List for sale
        </Button>
      )}
      {offers.length > 0 ? (
        <Table columns={columns} data={data} />
      ) : (
        <p className="text-xs text-gray-light">No offers for this tile.</p>
      )}
    </div>
  );
};

const TradingHistory = ({ nftIdentifier }: any) => {
  const [tradingData, setTradingData] = useState<any>([]);
  const [tradingDataRaw, setTradingDataRaw] = useState([]);

  useEffect(() => {
    nftIdentifier !== "" &&
      axios
        .get(`${API_URL}/transfers?token=${nftIdentifier}`)
        .then((res) => setTradingDataRaw(res.data));
  }, [nftIdentifier]);

  const getHerotagForAddress = async (address: string) => {
    const { data } = await axios.get(`${API_URL}/accounts/${address}`);

    return data?.username ? data.username : address;
  };

  const minimizeAddress = (address: string) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  useEffect(() => {
    const array: any[] = [];

    const getData = async () => {
      if (tradingDataRaw.length > 0 && nftIdentifier !== "") {
        if (tradingData.length == 0) {
          for (const item of tradingDataRaw) {
            const sender = await getHerotagForAddress(item["sender"]);
            const receiver = await getHerotagForAddress(item["receiver"]);

            const isMint =
              item["sender"] == CONTRACT_ADDRESS ||
              item["sender"] == OLD_CONTRACT_ADDRESS;

            const data =
              isMint &&
              (await axios.get(
                `${API_URL}/transactions/${item["originalTxHash"]}`
              ));

            const price =
              data &&
              Number(data?.data?.action?.arguments?.transfers[0].value) /
                10 ** 18;

            const token =
              data && data?.data?.action?.arguments?.transfers[0].ticker;

            // TODO: make this less hacky
            array.push({
              event: isMint
                ? "Mint"
                : item["function"] == "listing"
                ? "Listing"
                : "Transfer",
              txLink: `https://${
                ENVIRONMENT == "devnet" ? "devnet-" : ""
              }explorer.elrond.com/transactions/${item["originalTxHash"] ??
                item["txHash"]}`,
              price: !isMint
                ? "-"
                : `${price} ${
                    token.split("-").length > 1 ? token.split("-")[0] : token
                  }`,
              from: isMint
                ? "Minting contract"
                : item["sender"] == XOXNO_ADDRESS
                ? "XOXNO"
                : sender.length > 50
                ? minimizeAddress(item["sender"])
                : sender,
              to:
                item["receiver"] == XOXNO_ADDRESS
                  ? "XOXNO"
                  : receiver.length > 50
                  ? minimizeAddress(item["receiver"])
                  : receiver,
              date: new Date(item["timestamp"] * 1000).toLocaleDateString(
                "en-GB",
                {
                  timeZone: "UTC",
                  hour: "2-digit",
                  minute: "2-digit",
                }
              ),
            });
          }
        }
      }

      setTradingData(array);
    };

    getData();
  }, [tradingDataRaw, nftIdentifier]);

  // const data = useMemo(
  //   () => [
  //     {
  //       event: "buy",
  //       price: "250 LAND",
  //       expiration: "in 21 days",
  //       from: "ultra.fox",
  //       to: "ultra.dog",
  //       date: "02-04-2022",
  //     },
  //   ],
  //   []
  // );

  const columns = useMemo<any>(
    () => [
      {
        Header: "Event",
        accessor: "event",
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "From",
        accessor: "from",
      },
      {
        Header: "To",
        accessor: "to",
      },
      {
        Header: "Date",
        accessor: "date",
      },
    ],
    []
  );

  return (
    <div className="trading">
      <div className="trading__header">
        <Icon name="history" primary />
        <h1>Trading History</h1>
      </div>

      {/* <NotFound>
        <motion.h1 variants={fadeInVariants}>
          Oh no. <br />
          No history <span className="text-purple">yet.</span>
        </motion.h1>
        <motion.p variants={fadeInVariants}>
          Maybe Dracula has scared the buyers. Go back to the map.{" "}
        </motion.p>
        <Button className="w-[13.75rem] mt-6 filled" link="/" animate>
          <Icon name="map" />
          Go To Map
        </Button>
      </NotFound> */}
      {tradingData && (
        <Table columns={columns} data={tradingData} className="large" />
      )}
    </div>
  );
};

const PlaceDetails = () => {
  const { id } = useParams();
  const isPhone = useMedia("(max-width: 767px)");

  const [loading, setLoading] = useAtom(loadingAtom);
  const [xoxnoData, setXoxnoData] = useAtom(xoxnoDataAtom);
  const [herotag, setHerotag] = useState(null);
  const [nftIdentifier, setNftIdentifier] = useState("");
  const [isOnSale, setIsOnSale] = useState(false);
  const [viewedTiles, setViewedTiles] = useLocalStorage<
    { id: string; timestamp: number }[]
  >("viewedTiles", []);

  const handleError = useErrorHandler();

  const [placeData, setPlaceData] = useState<DetailsProps>({
    name: "Unnamed Tile",
    owner: "",
    owner_address: "",
    views: 0,
    address: "Unknown",
    link: "Unnamed Land",
    identifier: "",
  });

  useEffect(() => {
    getNFTs([id ?? ""]).then((nfts) => {
      if (nfts) {
        const tileDetails = nfts[0];

        axios
          .get(`${API_URL}/accounts/${tileDetails.owner_address}`)
          .then((res) => {
            setPlaceData({
              name: tileDetails.name,
              owner_address: tileDetails.owner_address,
              owner: res.data?.username
                ? res.data.username.split(".")[0]
                : tileDetails.owner_address.substring(0, 7) +
                  "..." +
                  tileDetails.owner_address.substring(
                    tileDetails.owner_address.length - 1,
                    tileDetails.owner_address.length - 7
                  ),
              address: tileDetails.location,
              center: JSON.parse(tileDetails.center),
              link: tileDetails.link,
              views: tileDetails.view_count,
              rank: tileDetails.rank,
              image_nft_id: tileDetails.image_nft_id,
              identifier: tileDetails.identifier,
            });
          });
      } else {
        handleError("Tile not found.");
      }
    });
  }, [id]);

  useEffect(() => {
    if (placeData.identifier) {
      setNftIdentifier(placeData.identifier);
    }
  }, [placeData]);

  // console.log("placeData", placeData);

  const { ...detailsProps } = placeData;

  const tabs: TabType[] = useMemo(
    () => [
      {
        id: 1,
        title: "Details",
        content: <Details {...detailsProps} />,
      },
      // {
      //   id: 2,
      //   title: "Offers",
      //   content: <Offers />,
      // },
      {
        id: 3,
        title: "History",
        content: <TradingHistory />,
      },
    ],
    [detailsProps]
  );

  useEffect(() => {
    if (placeData?.owner)
      axios
        .get(`${API_URL}/accounts/${placeData.owner}`)
        .then(
          (res: any) =>
            res.data?.username && setHerotag(res.data.username.split(".")[0])
        );
  }, [placeData]);

  useEffect(() => {
    nftIdentifier !== "" &&
      axios.get(`${XOXNO_API}nfts/${nftIdentifier}`).then((res: any) => {
        res.data.onSale && setIsOnSale(true);
        setXoxnoData(res.data);
      });
  }, [nftIdentifier]);

  useEffect(() => {
    if (id) {
      const tileViewedTimestamp =
        viewedTiles && viewedTiles.find((tile) => tile?.id === id)?.timestamp;
      if (
        (tileViewedTimestamp && Date.now() - tileViewedTimestamp > 300000) ||
        !tileViewedTimestamp
      ) {
        const newViewedTiles = viewedTiles
          ? viewedTiles.filter((tile) => tile?.id !== id)
          : [];
        setViewedTiles([...newViewedTiles, { id, timestamp: Date.now() }]);
        request(`/nfts/${id}/view`, {});
      }
    }
  }, [id]);

  return (
    <div className="container place-details">
      {isPhone && !loading && <Tabs tabs={tabs} />}
      {!isPhone && !loading && (
        <div className="place-details__content">
          <div className="row">
            <Details {...detailsProps} />
            {/* <Offers /> */}
          </div>
          <TradingHistory nftIdentifier={nftIdentifier} />
        </div>
      )}
    </div>
  );
};

export default PlaceDetails;
