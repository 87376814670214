import withPageTitle from "components/seo/PageTitle";
import BuyTokens from "pages/BuyTokens";
import Hunt from "pages/Hunt";
import HuntLanding from "pages/HuntLanding";
import Leaderboard from "pages/Leaderboard";
import NFTs from "pages/NFTs";
import PlaceDetails from "pages/PlaceDetails";
import User from "pages/User";

export const routeNames = {
  home: "/?",
  transaction: "/transaction",
  unlock: "/unlock",
  ledger: "/ledger",
  walletconnect: "/walletconnect",
  leaderboard: "/leaderboard",
  rewards: "/rewards",
  users: "/users/:address",
  tiles: "/nfts/:id",
  "buy-land": "/buy-land",
  hunt: "/hunt/:index",
  huntLanding: "/hunt",
};

const routes: Array<any> = [
  {
    path: routeNames.leaderboard,
    component: Leaderboard,
    exact: true,
  },
  {
    path: routeNames.users,
    component: User,
    exact: true,
  },
  {
    path: routeNames.tiles,
    component: PlaceDetails,
    exact: true,
  },
  {
    path: routeNames.rewards,
    component: NFTs,
    exact: true,
  },
  {
    path: routeNames["buy-land"],
    component: BuyTokens,
    exact: true,
  },
  {
    path: routeNames.hunt,
    component: Hunt,
    exact: true,
  },
  {
    path: routeNames.huntLanding,
    component: HuntLanding,
    exact: true,
  },
];

const mappedRoutes = routes.map((route) => {
  const title = route.title ? `${route.title} | Landboard` : "Landboard";

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth,
  };
});

export default mappedRoutes;
