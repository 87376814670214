import { useTrackTransactionStatus } from "@multiversx/sdk-dapp/hooks";
import { request } from "api/request";
import { useAtom } from "jotai";
import "mapbox-gl/dist/mapbox-gl.css";
import { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { refreshCountAtom, userAtom, WaitingTransaction } from "store/atoms";
import { notifyError, notifySuccess } from "utils/notifications";
import indexes from "../../new_indexes.json";

interface TransactionListenerProps {
  handleClose: () => void;
  removeTransaction: () => void;
  transaction: WaitingTransaction;
}

const TransactionListener = ({
  handleClose,
  removeTransaction,
  transaction,
}: TransactionListenerProps) => {
  const [refeshCount, setRefeshCount] = useAtom(refreshCountAtom);
  const [user] = useAtom(userAtom);

  const [submitted, setSubmitted] = useState(false);

  const transactionStatus = useTrackTransactionStatus({
    transactionId: transaction.sessionId,
    onSuccess: () => {
      notifySuccess("Transaction succesful.");
      removeTransaction();
    },
    onFail: () => {
      // notifyError("Transaction failed.");
      removeTransaction();
    },
    onCancelled: () => {
      notifyError("Transaction cancelled.");
      removeTransaction();
    },
  });

  const handleChangeTransactionStatus = async () => {
    if (transaction && user && transactionStatus.isSuccessful && !submitted) {
      if (transaction.type === "random" || transaction.type === "specific") {
        let tiles = transaction.tiles;

        if (!tiles) {
          request("/nfts/sync", { address: user?.address }).then(() => {
            notifySuccess("Tiles minted successfully.");
          });
          return;
        }

        if (tiles) {
          const newNFTs = tiles.map(({ id, ...tile }) => {
            return {
              // @ts-ignore
              collection_index: indexes.indexOf(tile.quad_key, 0) + 1,
              quad_key: tile.quad_key,
              owner_address: user.address,
              center: tile.center,
            };
          });
          try {
            await request("/nfts/mint", {
              nfts: newNFTs,
              buyer_address: user.address,
            });

            notifySuccess("Tiles minted successfully");
            setRefeshCount(refeshCount + 1);
            setSubmitted(true);

            removeTransaction();
            handleClose();
          } catch (e) {
            notifyError("Failed to mint tiles");
          }
        }
      } else if (transaction.status === "buy-land") {
        notifySuccess("LAND bought successfully");
      }
    }
  };

  useEffect(() => {
    handleChangeTransactionStatus();
  }, [transactionStatus]);

  return <></>;
};

export default TransactionListener;
