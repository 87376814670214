import Lottie from "react-lottie-player";
import loaderJson from "assets/lottie/loader2.json";

const HandLoader = () => {
	return (
		<div className="loading-card">
			<h2>Loading</h2>
			<Lottie animationData={loaderJson} style={{ width: 512, height: 512, zIndex: 9 }} loop play />
		</div>
	);
};

export default HandLoader;
