import {
  ExtensionLoginButton,
  WebWalletLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
} from "@multiversx/sdk-dapp/UI";
import { walletConnectV2ProjectId } from "config";
import React from "react";
import { routeNames } from "routes";

const Unlock = () => {
  return (
    <div className="flex items-center justify-center w-full">
      <div className="self-center w-3/4 md:ml-40 mt-36 home d-flex flex-fill align-items-center unlock-page_card">
        <div className="m-auto" data-testid="unlockPage">
          <div className="my-4 text-center card">
            <div className="px-2 py-4 card-body px-sm-2 mx-lg-4">
              <h4 className="mb-4 unlock-page_title">Login</h4>
              <p className="mb-4">Please choose a login method!</p>
              <div>
                <ExtensionLoginButton
                  // @ts-ignore
                  callbackRoute={routeNames.home}
                  loginButtonText={"Extension"}
                />
                <WebWalletLoginButton
                  // @ts-ignore
                  callbackRoute={routeNames.home}
                  loginButtonText={"Web wallet"}
                />
                <LedgerLoginButton
                  // @ts-ignore
                  callbackRoute={routeNames.home}
                  loginButtonText={"Ledger"}
                  className={"text-black"}
                />
                <WalletConnectLoginButton
                  // token={walletConnectV2ProjectId}
                  // @ts-ignore
                  callbackRoute={routeNames.home}
                  loginButtonText={"xPortal"}
                  className={"text-black"}
                  isWalletConnectV2={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unlock;
