import { ReactNode, useEffect, useState } from "react";
import { motion, AnimateSharedLayout } from "framer-motion";
import { TabType } from "types/components";
import { tabContentVariant } from "animation/variants";

interface TabContentProps {
  id: string;
  active: boolean;
  children: ReactNode;
}

const TabContent = ({ id, active, children }: TabContentProps) => {
  return (
    <motion.div
      role="tabpanel"
      id={id}
      className="tab-content"
      variants={tabContentVariant}
      animate={active ? "active" : "inactive"}
      initial="inactive"
    >
      {children}
    </motion.div>
  );
};

interface TabsProps {
  tabs: TabType[];
}

const Tabs = ({ tabs }: TabsProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    const tabFromHash = tabs.findIndex(
      (tab) => `#${tab.title.toLowerCase()}` === window.location.hash
    );
    setActiveTabIndex(tabFromHash !== -1 ? tabFromHash : 0);
  }, [tabs]);

  const onTabClick = (index: number) => {
    setActiveTabIndex(index);
  };

  return (
    <div className="tabs-component">
      <AnimateSharedLayout>
        <ul className="tab-links" role="tablist">
          {tabs.map((tab, index) => (
            <li
              key={tab.title.toLowerCase()}
              className={`tab ${index === activeTabIndex ? "active" : ""}`}
              role="presentation"
            >
              {index === activeTabIndex && (
                <motion.div
                  layoutId="background"
                  className="tab-background"
                  initial={false}
                  transition={{
                    type: "spring",
                    stiffness: 500,
                    damping: 30,
                  }}
                />
              )}
              <a
                href={`#${tab.title.toLowerCase()}`}
                onClick={() => onTabClick(index)}
              >
                <span>{tab.title}</span>
              </a>
            </li>
          ))}
        </ul>
      </AnimateSharedLayout>
      {tabs.map((tab, index) => (
        <TabContent
          key={tab.title.toLowerCase()}
          id={`${tab.title.toLowerCase()}-content`}
          active={activeTabIndex === index}
        >
          {tab.content}
        </TabContent>
      ))}
    </div>
  );
};

export default Tabs;
