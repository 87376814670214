import { slideUpMenuVariants } from "animation/variants";
import Geocoder from "components/geocoder";
import { Icon } from "components/icons/Icon";
import Input from "components/input";
import Picker from "components/picker";
import { motion } from "framer-motion";
import { useAtom } from "jotai";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { forwardRef, useRef, useState } from "react";
import { useDebounce, useMedia } from "react-use";
import { opacityAtom } from "store/atoms";

const rotateVariants = {
  initial: {
    rotate: 0,
  },
  rotate: {
    rotate: 180,
  },
};

interface DraggableCardProps {}

const DraggableCard = forwardRef((props: DraggableCardProps, mapRef: any) => {
  const isPhone = useMedia("(max-width: 767px)");

  const containerRef = useRef<any>(null);
  const [lngLat, setLngLat] = useState([0, 0]);

  const [isExpanded, setIsExpanded] = useState(!isPhone);
  const [opacityValue, setOpacityValue] = useAtom(opacityAtom);

  const handleToggleMenu = () => {
    setIsExpanded(!isExpanded);
  };

  const handleResult = (result: any) => {};

  const handleSliderChange = (value: any) => {
    setOpacityValue(value);
  };

  useDebounce(
    () => {
      if (lngLat[0] !== 0 && lngLat[1] !== 0) {
        mapRef.current.flyTo({ center: lngLat, duration: 2000 });
      }
    },
    1000,
    [lngLat]
  );

  return (
    <motion.div
      className="draggable-card"
      animate={isExpanded ? "expanded" : "collapsed"}
      variants={isPhone ? slideUpMenuVariants : {}}
      initial="collapsed"
    >
      <div className="draggable-card__expand">
        <motion.button
          animate={isExpanded ? "initial" : "rotate"}
          initial="rotate"
          variants={rotateVariants}
          onClick={handleToggleMenu}
        >
          <Icon name="expand" />
        </motion.button>
      </div>
      <div className="col">
        <label className="font-bold">Search</label>
        <div className="w-full" ref={containerRef}></div>
        <Geocoder
          mapRef={mapRef}
          containerRef={containerRef}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN ?? ""}
          onResult={handleResult}
          onViewportChange={({ longitude, latitude }: any) => {
            mapRef.current.flyTo({
              center: [longitude, latitude],
              duration: 2000,
            });
            setIsExpanded(false);
          }}
          countries="ro"
        />
      </div>
      <div className="row">
        <Input
          label="Lat"
          type="number"
          onChange={(e: any) => setLngLat([lngLat[0], e.target.value])}
        />
        <Input
          label="Lng"
          type="number"
          onChange={(e: any) => setLngLat([e.target.value, lngLat[1]])}
        />
      </div>
      <div className="row">
        <div className="col">
          <label className="font-bold">Opacity</label>
          <Slider
            value={opacityValue}
            onChange={handleSliderChange}
            onAfterChange={handleSliderChange}
            max={1}
            min={0}
            step={0.1}
          />
        </div>
        <Picker />
      </div>
    </motion.div>
  );
});

export default DraggableCard;
