import Button from "components/buttons";
import { Icon } from "components/icons/Icon";
import { STORAGE_URL } from "config";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { useMedia } from "react-use";
import { buyModalAtom, offerModalAtom } from "store/atoms";
import ImageCard from "./ImageCard";

interface PlaceCardProps {
  id: string;
  name: string;
  pricePerTile: string;
  location?: string;
  link?: string;
  center?: string;
  nftId?: string;
  handleGoToMap?: () => void;
}

const PlaceCard = (props: PlaceCardProps) => {
  const {
    name,
    location,
    pricePerTile,
    link,
    center,
    handleGoToMap,
    nftId,
  } = props;

  const [offerModal, setOfferModal] = useAtom(offerModalAtom);

  const navigate = useNavigate();

  const handleOffer = () => {
    setOfferModal({ ...offerModal, isOpen: true });
  };

  const handleView = () => {
    navigate(`/nfts/${props.id}`);
  };

  return (
    <div className="place-card">
      <span className="place-card__title">{name}</span>
      <div className="place-card__header">
        <ImageCard
          image={STORAGE_URL + "/nft/" + props.id + `.webp?t=${Date.now()}`}
          handleGoToMap={handleGoToMap}
          nftId={nftId}
        />
        <div className="place-card__actions">
          <Button onClick={handleView} className="filled">
            <Icon name="info" />
            View
          </Button>
        </div>
      </div>
      <ul>
        {location && (
          <li>
            <Icon name="map-pin" primary />
            <span>{location}</span>
          </li>
        )}
        {center && (
          <li>
            <Icon name="map-location" primary />
            <span>{center}</span>
          </li>
        )}
        {link && (
          <li>
            <Icon name="chrome" primary />
            <a className="lnk" href={link}>
              {link}
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

export default PlaceCard;
