import {
  defaultMotionContainerProps,
  scaleFadeInVariants,
} from "animation/variants";
import axios from "axios";
import Button from "components/buttons";
import { Icon } from "components/icons/Icon";
import { API_URL, huntAddresses, MINT_TIME } from "config";
import { motion } from "framer-motion";
import useTimeUntil from "hooks/useTimeUntil";
import { request } from "api/request";
import { useAtom } from "jotai";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from "react-use";
import {
  buyModalAtom,
  offerModalAtom,
  startLocationAtom,
  userAtom,
} from "store/atoms";
import { Tile } from "types/Tiles";

interface PopupContentProps {
  id?: string;
  name?: string;
  owner?: string;
  pricePerTile?: number;
  totalPrice?: string;
  address?: string;
  link?: string;
  views?: number;
  rank?: number;
  nftId?: string;
  tiles: Tile[];
  handleClose: () => void;
}

const PopupContent = ({
  id,
  name,
  owner,
  pricePerTile,
  tiles,
  totalPrice,
  address,
  views = 0,
  rank,
  nftId,
  handleClose,
  link,
}: PopupContentProps) => {
  const [buyModal, setBuyModal] = useAtom(buyModalAtom);
  const [offerModal, setOfferModal] = useAtom(offerModalAtom);
  const [user] = useAtom(userAtom);
  const [herotag, setHerotag] = useState("");
  const [isHuntTile, setIsHuntTile] = useState(false);
  const [huntStage, setHuntStage] = useState(-1);
  const navigate = useNavigate();

  const [, setStartLocation] = useAtom(startLocationAtom);

  const egldPrice = (tiles.length * 0.3).toPrecision(2);
  const isConnected = !isEmpty(user);

  const { timeLeft, hours, minutes, seconds } = useTimeUntil(MINT_TIME);

  const handleBuyLand = () => {
    if (owner) {
      const center = JSON.parse(tiles[0].center);
      setStartLocation(center);
      navigate(`/nfts/${id}`);
    } else {
      setBuyModal({
        ...buyModal,
        isOpen: true,
        landPrice: totalPrice,
        egldPrice,
        tiles,
        address,
      });
    }
  };

  useEffect(() => {
    axios
      .get(`${API_URL}/accounts/${owner}`)
      .then(
        (res) =>
          res.data?.username && setHerotag(res.data.username.split(".")[0])
      );
  }, [owner]);

  useEffect(() => {
    huntAddresses.map((address, index) => {
      if (address === owner) {
        setIsHuntTile(true);
        setHuntStage(index + 1);
      }
    });
  }, [owner]);

  useEffect(() => {
    const encodedStages = [
      "aslwr12",
      "wr12p325",
      "1251[pfls",
      "r[2kprpo",
      "aspijd3",
      "t87yhuiuu",
    ];

    request("users/whitelist", {
      address: user.address,
      stage: encodedStages[huntStage],
    }).then((res) => console.log("res", res));
  }, [huntStage, isHuntTile]);

  return (
    <motion.div
      className="lboard-popup"
      exit="hidden"
      variants={scaleFadeInVariants}
      {...defaultMotionContainerProps}
    >
      <span className="lboard-popup__price">
        {totalPrice} <span className="text-purple">LAND</span>
      </span>
      <button className="buy-place-card__close" onClick={handleClose}>
        <Icon width={28} name="close" primary />
      </button>
      <h3>
        {nftId && (
          <a
            href={`https://explorer.elrond.com/nfts/${nftId}`}
            className="badge"
          >
            <Icon name="star" width={16} primary />
            {nftId}
          </a>
        )}
        <span>{name}</span>
      </h3>
      <ul className="popup-details">
        {rank && (
          <li>
            <Icon name="leaderboard" primary />
            <span>
              Rank {rank} (top {((rank / 248845) * 100).toFixed(2)}%)
            </span>
          </li>
        )}
        {owner && (
          <li>
            <Icon name="user" primary />
            <span>
              Owned by{" "}
              <a
                target="_blank"
                href={`/users/${herotag ?? owner}`}
                className="lnk"
              >
                {!herotag
                  ? owner.substring(0, 7) +
                    "..." +
                    owner.substring(owner.length, owner.length - 7)
                  : herotag}
              </a>
            </span>
          </li>
        )}
        {!isEmpty(address) && address != "Unknown" && (
          <li>
            <Icon name="map-pin" primary />
            <span>
              {" "}
              {address
                ?.split(",")
                .splice(1)
                .join()
                .slice(0, 40)}{" "}
              ...
            </span>
          </li>
        )}
        {tiles.length > 1 && (
          <li>
            <Icon name="tile" primary />
            <span>{tiles.length} Tiles</span>
          </li>
        )}
        {link && (
          <li>
            <Icon name="chrome" primary />
            <span>
              <a href={link} className="lnk">
                {link}
              </a>
            </span>
          </li>
        )}
        {owner && (
          <li>
            <Icon name="eye" primary />
            <span>{views > 0 ? views + " views" : "No views"}</span>
          </li>
        )}
      </ul>
      <Button
        className="w-full filled"
        disabled={!isConnected && !owner}
        onClick={handleBuyLand}
        hideComingSoon
      >
        <Icon name={owner ? "info2" : "tag1"} />
        {timeLeft > 0
          ? "Buy in " + hours + "H : " + minutes + "M : " + seconds + "S"
          : owner
          ? "View"
          : isConnected
          ? "Buy"
          : "Connect to buy"}
      </Button>
    </motion.div>
  );
};

export default PopupContent;
