import { fadeInVariants } from "animation/variants";
import {
  getAllUsersNFTsCount,
  getNFTCollectionsLeaderboard,
  getNFTViewsLeaderboard,
  getUsersNFTsCount,
} from "api/supabase-client";
import Button from "components/buttons";
import CopyMe from "components/copy";
import Select from "components/select";
import { Icon } from "components/icons/Icon";
import NotFound from "components/notFound";
import Table from "components/table";
import { motion } from "framer-motion";
import { useAtom } from "jotai";
import groupBy from "lodash/groupBy";
import { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { userAtom } from "store/atoms";
import { API_URL } from "config";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";

const options = [
  {
    value: "tiles",
    label: "Tiles",
  },
  {
    value: "views",
    label: "Views",
  },
  {
    value: "land",
    label: "LAND",
  },
  {
    value: "collections",
    label: "Collections",
  },
];

const Leaderboard = () => {
  const { account } = useGetAccountInfo();

  const [data, setData] = useState([]);
  const [nftData, setNftData] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [topOwnersData, setTopOwnersData] = useState([]);
  const [leaderboardType, setLeaderboardType] = useState("tiles");
  const [loading, setLoading] = useState(false);

  const [user] = useAtom(userAtom);

  const { address } = user;

  const columns = useMemo<any>(
    () => [
      {
        Header: "#",
        accessor: "rank",
      },
      {
        Header: "User",
        accessor: "address",
      },
      {
        Header: "Tiles",
        accessor: "tiles",
      },
      {
        Header: "Land",
        accessor: "land",
      },
    ],
    []
  );
  const viewsColumns = useMemo<any>(
    () => [
      {
        Header: "#",
        accessor: "rank",
      },
      {
        Header: "Owner",
        accessor: "owner",
      },
      {
        Header: "Views",
        accessor: "view_count",
      },
      {
        Header: "Rarity",
        accessor: "rarity",
      },
      {
        Header: "Location",
        accessor: "location",
      },
    ],
    []
  );
  const collectionsColumns = useMemo<any>(
    () => [
      {
        Header: "#",
        accessor: "rank",
      },
      {
        Header: "Collection",
        accessor: "collection",
        Cell: (info: any) => {
          return (
            <a href={info.row.original["website"] ?? "/#"}>
              <img
                src={info.row.original["url"] ?? "/#"}
                alt={info.row.values["name"]}
                width={60}
              />
              {info.value}
            </a>
          );
        },
      },
      {
        Header: "NFT count",
        accessor: "nft_count",
      },
    ],
    []
  );
  const topOwnersColumns = useMemo<any>(
    () => [
      {
        Header: "#",
        accessor: "rank",
      },
      {
        Header: "Owner",
        accessor: "address",
        Cell: (info: any) => {
          return (
            <a
              target="_blank"
              href={`/users/${info.value}`}
              className={`${(info.value == address ||
                info.value == account.username?.split(".")[0] ||
                info.value == account.username) &&
                "font-bold"}`}
            >
              {info.value}
            </a>
          );
        },
      },
      {
        Header: "Tiles count",
        accessor: "tiles",
      },
    ],
    []
  );

  const getTopTilesOwners = () => {
    setLoading(true);

    getAllUsersNFTsCount().then((data) => {
      const groupedData = groupBy(data, (item) => item.owner_address);

      const addresses = Object.keys(groupedData);

      const addressesWithCount = addresses.map((address) => {
        return {
          address,
          tiles: groupedData[address].length,
        };
      });

      const sorted = addressesWithCount.sort((a, b) => b.tiles - a.tiles);

      const top100 = sorted.slice(0, 100);

      const top100WithRank = top100.map((item, index) => {
        return {
          ...item,
          rank: index + 1,
        };
      });

      // @ts-ignore
      setTopOwnersData(top100WithRank);
    });
  };

  useEffect(() => {
    setLoading(true);
    fetch("https://api.elrond.com/tokens/LAND-40f26f/accounts?size=104").then(
      (res) =>
        res.json().then((data) => {
          let newData = data
            .slice(4, data.length)
            .map((account: any, index: number) => ({
              rank: index + 1,
              address: account.address,
              tiles: "TBD",
              land: Math.floor(account.balance / 10 ** 18),
            }));
          getUsersNFTsCount(data.map((d: any) => d.address))
            .then((nfts) => {
              let usersWithNFTCount: any = groupBy(
                nfts.map((d: any) => ({
                  address: d.owner_address,
                  herotag: d?.users?.herotag,
                })),
                "address"
              );
              usersWithNFTCount = Object.values(usersWithNFTCount).map(
                (d: any) => ({
                  herotag: d[0].herotag,
                  address: d[0].address,
                  count: d.length,
                })
              );
              const leaderboardUsers = newData.map((d: any) => {
                const user = usersWithNFTCount.find(
                  (u: any) => u.address === d.address
                );
                return {
                  ...d,
                  address: (
                    <CopyMe>
                      {address === d.address ? (
                        <span className="font-bold text-purple">
                          {" "}
                          YOU: {d.herotag ?? d.address}{" "}
                        </span>
                      ) : (
                        d.herotag ?? d.address
                      )}
                    </CopyMe>
                  ),
                  tiles: user?.count || 0,
                };
              });
              setData(leaderboardUsers);
            })
            .finally(() => setLoading(false));
        })
    );

    getTopTilesOwners();
    getNFTViewsLeaderboard()
      .then((data: any) => {
        if (data)
          setNftData(
            data.map((d: any, index: number) => ({
              ...d,
              rank: (
                <Link to={`/nfts/${d.quad_key}`}>
                  <a className="lnk">{index + 1}</a>
                </Link>
              ),
              owner: (
                <Link to={`/users/${d.owner}`}>
                  <a>{d.owner}</a>
                </Link>
              ),
            }))
          );
      })
      .finally(() => setLoading(false));

    getNFTCollectionsLeaderboard()
      .then(async (data: any) => {
        if (data) {
          let collections: any[] = [];
          data.forEach((d: any) => {
            const collectionId = d.image_nft_id
              .split("-")
              .slice(0, 2)
              .join("-");
            const collection = collections.find(
              (c: any) => c.collection === collectionId
            );
            if (!collection) {
              collections.push({
                collection: collectionId,
                nft_count: 1,
              });
            } else {
              collection.nft_count++;
            }
          });
          const res = await fetch(
            `${API_URL}/collections?identifiers=${collections
              .map((d: any) => d.collection)
              .join(",")}`
          );
          const collectionsData = await res.json();
          collections = collections.sort(
            (a: any, b: any) => b.nft_count - a.nft_count
          );

          collections = collections.map((c: any, index: number) => {
            const data = collectionsData.filter(
              (col: any) => col.collection == c.collection
            )[0];

            return {
              ...c,
              collection: data?.name,
              // website: data?.assets?.website,
              website: `https://explorer.elrond.com/collections/${data?.collection}`,
              url: `https://media.xoxno.com/nftmedia/${data?.collection}/${data?.collection}-01.avif`,
              // url: data?.assets?.pngUrl,
              rank: index + 1,
            };
          });

          // @ts-ignore
          setCollectionData(collections.filter((a) => a.collection));
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="container leaderboard">
      <div className="leaderboard__header">
        <h1>
          Leader<span className="text-purple">board</span>
        </h1>
        <Select
          // className="w-10"
          defaultValue={options.filter((o) => o.value === leaderboardType)[0]}
          onChange={(option) => setLeaderboardType(option.value)}
          options={options}
        />
      </div>
      {loading && <Skeleton count={20} height={40} />}
      {!data && !loading && (
        <NotFound>
          <motion.h1 variants={fadeInVariants}>
            Oh no. <br />
            No rankings <span className="text-purple">yet.</span>
          </motion.h1>
          <motion.p variants={fadeInVariants}>
            Maybe Dracula has scared the competition. Go back to the map.{" "}
          </motion.p>
          <Button className="w-[13.75rem] mt-6 filled" link="/" animate>
            <Icon name="map" />
            Go To Map
          </Button>
        </NotFound>
      )}
      {leaderboardType === "land" && data && !loading && (
        <Table columns={columns} data={data} />
      )}
      {leaderboardType === "views" && nftData && !loading && (
        <Table columns={viewsColumns} data={nftData} />
      )}
      {leaderboardType === "collections" && collectionData && !loading && (
        <Table columns={collectionsColumns} data={collectionData} />
      )}
      {leaderboardType === "tiles" && collectionData && !loading && (
        <Table columns={topOwnersColumns} data={topOwnersData} />
      )}
    </div>
  );
};

export default Leaderboard;
