import { Rewards } from "pages/User";
import React from "react";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";

const Referral = () => {
  const { address } = useGetAccountInfo();

  return (
    <div className="px-5 mt-10 referrals-wrapper">
      <div className="mb-5 ">
        <h1 className="text-center">Referrals</h1>
        <h2 className="text-lg text-center">
          Invite your friends to mint Tiles and earn 10% of their first
          purchase.
        </h2>
      </div>

      <Rewards
        address={address}
        herotag="lucasmanea"
        totalRewards={10}
        isMinimal={true}
      />
    </div>
  );
};

export default Referral;
