import Button from "components/buttons";
import React from "react";
import hunt1 from "assets/images/hunt1.png";
import hunt2 from "assets/images/hunt2.png";

const HuntLanding = () => {
  return (
    <div
      className="flex flex-col items-center justify-center w-full text-center "
      style={{ margin: "150px 0" }}
    >
      <div
        className="flex flex-col items-center justify-center w-2/3 text-center"
        // style={{ width: "90%" }}
      >
        <div style={{ marginBottom: 100 }} className="flex flex-col max-w-md">
          <div style={{ marginBottom: 50 }}>
            <h1 className="text-3xl text-center" style={{ marginBottom: 35 }}>
              Landboard Treasure Hunt
            </h1>
            <p className="text-md">
              Meet Treasure Hunt: our newest challange and event. We've hidden 6
              tiles on the Landboard map - each tile contains 4 words of a
              secret phrase.
            </p>
            <br />
            <br />
            <p className="text-md">
              {" "}
              Find all 6 tiles and you'll get access to the entire treasury
              wallet, containing $100+ in LAND and EGLD
            </p>
          </div>
          <Button
            containerClassname="flex-1"
            link="/hunt/1"
            className="filled"
            hideComingSoon={false}
            disabled={true}
            type="button"
          >
            Go to first hint
          </Button>
        </div>

        <div>
          <div className="flex flex-col gap-16 text-center md:gap-20 md:text-start">
            <div className="hero-container">
              <div className="hero-container__text">
                <h3>
                  6 tiles. 24 words.
                  <span className="text-purple"> 1 treasure.</span>
                </h3>
                <p className="text-center md:text-left">
                  Find each hidden tile and get one step closer to the treasure.
                </p>
              </div>
              {/* <img src=''/> */}
              <img
                src={hunt1}
                alt="hunt1"
                style={{ maxWidth: 350 }}
                className="w-full md:w-1/2"
              />
              {/* <HeroIllustration2 /> */}
            </div>
            <div className="reverse hero-container">
              <div className="hero-container__text">
                <h3>
                  Follow the hints. Be a
                  <span className="text-purple"> hunter.</span>
                </h3>
                <p className="text-center md:text-left">
                  Find wanted tiles, track your results, and share your progress
                  to the community. You might want to keep the words to
                  yourself!
                </p>
              </div>
              <img
                src={hunt2}
                alt="hunt2"
                style={{ maxWidth: 350 }}
                className="w-full sm:1/3 md:w-1/2"
              />

              {/* <HeroIllustration3 /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HuntLanding;
