import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import Button from "components/buttons";
import { request } from "api/request";
import React, { useEffect, useState } from "react";
import {
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
// @ts-ignore
import ReactSpoiler from "react-spoiler";
import { hints } from "data/hints";

const Hunt = () => {
  const [canClaimBadge, setCanClaimBadge] = useState(true);
  const [isConected, setIsConnected] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const { account } = useGetAccountInfo();
  const [isFirst, setIsFirst] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [canAccessNext, setCanAccessNext] = useState(false);

  useEffect(() => {
    setIsConnected(account.address !== "");
  }, [account]);

  const link = window.location.href;
  const shareUrl = "app.landboard.io/hunt";
  const stage = link.split("/")[4];
  const title = `Just got to Stage ${stage} on @landboard_io's Treasure Hunt!`;

  useEffect(() => {
    stage == "1" && setIsFirst(true);
    stage == "1" && setHasAccess(true);
    stage == "7" && setIsLast(true);
  }, [stage]);

  useEffect(() => {
    const encodedStages = [
      "aslwr12",
      "wr12p325",
      "1251[pfls",
      "r[2kprpo",
      "aspijd3",
      "t87yhuiuu",
    ];
    account &&
      request(
        "/users/whitelist",
        // TODO: fix here
        // @ts-ignore
        {
          params: {
            address: account.address,
            stage: encodedStages[Number(stage)],
          },
        },
        "GET"
      ).then((res: any) => res.status == 201 && setCanAccessNext(true));
  }, [account]);

  useEffect(() => {
    const encodedStages = [
      "aslwr12",
      "wr12p325",
      "1251[pfls",
      "r[2kprpo",
      "aspijd3",
      "t87yhuiuu",
    ];
    account &&
      request(
        "/users/whitelist",
        // TODO: fix here
        // @ts-ignore
        {
          params: {
            address: account.address,
            stage: encodedStages[Number(stage) - 1],
          },
        },
        "GET"
      ).then((res: any) => res.status == 201 && setHasAccess(true));
  }, [account]);

  return isConected ? (
    hasAccess ? (
      <div
        className="flex flex-col items-center justify-center h-full mt-5 md:w-full"
        style={{ marginTop: 150 }}
      >
        <div className="flex flex-col items-center justify-center w-3/4">
          <div className="flex flex-col items-center mt-10 text-center">
            <h1>Hunt: Stage {stage}</h1>
            {!isLast ? (
              !isFirst ? (
                <p className="flex items-center justify-center text-center">
                  You've made it so far! Just {6 - Number(stage)} tiles left
                  until you can get access to the entire treasury!
                </p>
              ) : (
                <p className="flex items-center justify-center text-center">
                  Welcome to the hunt! Good luck on the journey; may the best
                  hunter win.
                </p>
              )
            ) : (
              <p className="flex items-center justify-center text-center">
                You won! Congratulations!
              </p>
            )}
            <div className="flex flex-col items-center p-5 mt-5 rounded-md bg-purple-darkest">
              <p style={{ marginTop: 20 }}>
                The hunt has been completed!{" "}
                <a
                  href="https://explorer.elrond.com/accounts/erd1awl8lgs8ff0w4ke202h74q0vuzfylavyz0h78dm9af4t09f2vyvsc5tfp0"
                  target="_blank"
                >
                  (see treasure wallet)
                </a>
                <br />
                Thank you for participating; every participant will be
                airdropped a commemorative NFT badge soon. <br /> Stick by for
                more events! 🚀
              </p>
              <div className="w-1/2">
                <Button className="mt-10 filled" link="/">
                  Mint Tiles
                </Button>
              </div>
            </div>
          </div>
          <div className="p-5 my-2 rounded">
            <p className="mt-10 mb-2 filled">Share your progress!</p>
            <div className="share-tile-card__socials">
              <TelegramShareButton url={shareUrl} title={title} className="">
                <TelegramIcon round={true} size={50} />
              </TelegramShareButton>
              <TwitterShareButton
                url={shareUrl}
                title={`Just got to Stage ${stage} on @landboard_io's Treasure Hunt! \n\n Participate for a chance at $100 in prizes 👉`}
                hashtags={["landboard", "metaverse"]}
              >
                <TwitterIcon round={true} size={50} />
              </TwitterShareButton>
              <WhatsappShareButton
                url={shareUrl}
                title={title}
                separator=":: "
                className=""
              >
                <WhatsappIcon round={true} size={50} />
              </WhatsappShareButton>
            </div>
          </div>

          <div className="flex flex-col p-5 mt-3 rounded-md bg-purple-darkest ">
            {!isFirst && (
              <>
                <div
                  // style={{ marginTop: 50 }}
                  className="flex flex-col items-center justify-center"
                >
                  <h3>You've found 4 more words.</h3>
                  <p className="text-sm" style={{ marginTop: 10 }}>
                    Make sure to save them somewhere. In order to access the
                    treasury wallet, you need all of the words.
                  </p>
                  <ReactSpoiler
                    blur={10}
                    hoverBlur={0}
                    style={{ marginTop: 40 }}
                  >
                    {
                      hints[
                        hints.indexOf(
                          hints.filter((a) => a.stage == Number(stage))[0]
                        )
                      ]?.words
                    }
                  </ReactSpoiler>
                </div>
                <hr
                  className="block w-full my-4 bg-white border-t-1"
                  style={{ margin: "50px 0", paddingBottom: 5 }}
                />{" "}
              </>
            )}
            {hints.filter((a) => a.stage == Number(stage))[0]?.hint && (
              <div
                // style={{ marginTop: 50 }}
                className="flex flex-col items-center justify-center "
              >
                <h3>Hint for the {isFirst ? "first" : "next"} tile</h3>
                <ReactSpoiler
                  blur={10}
                  style={{ margin: "20px 0", fontSize: 24 }}
                  className="max-w-md"
                >
                  {
                    hints[
                      hints.indexOf(
                        hints.filter((a) => a.stage == Number(stage))[0]
                      )
                    ]?.hint
                  }
                </ReactSpoiler>
                <Button className="w-full mt-10 filled" link="/">
                  Start looking
                </Button>
                {canAccessNext && (
                  <Button
                    className="w-full mt-10 filled"
                    external={`https://app.landboard.io/hunt/${Number(stage) +
                      1}`}
                  >
                    Go to next stage
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    ) : (
      <div
        className="flex flex-col items-center justify-center w-full h-full mt-5"
        style={{ marginTop: 150 }}
      >
        Oops, no access. Try going to a lower stage.
      </div>
    )
  ) : (
    <div
      className="flex flex-col items-center justify-center w-full h-full mt-5"
      style={{ marginTop: 150 }}
    >
      Oops, no access. Try connecting your wallet.
    </div>
  );
};

export default Hunt;
