import DraggableCard from "components/cards/DraggableCard";
import RandomMintCard from "components/cards/mint/RandomMintCard";
import { Icon } from "components/icons/Icon";
import Map from "components/map";
import { TOKEN_ID } from "config";
import { useAtom } from "jotai";
import { useRef } from "react";
import { useMedia } from "react-use";
import { randomMintModalAtom } from "store/atoms";
import useMintContract from "utils/useMintContract";

const Home = () => {
  const mapRef = useRef(null);
  const [, setRandomMintModal] = useAtom(randomMintModalAtom);
  const isPhone = useMedia("(max-width: 767px)");
  const { mintContract } = useMintContract(TOKEN_ID);

  // const getEquivalent = async () => {
  //   mintContract && mintContract.getEquivalent();
  // };

  // getEquivalent();
  return (
    <div className="map-page-container">
      <Map ref={mapRef} />
      <div className="lboard-map-controls">
        {isPhone && (
          <button
            className="lboard-map-control"
            onClick={() => setRandomMintModal({ isOpen: true })}
          >
            <Icon name="dice-3" primary />
          </button>
        )}
      </div>
      <div className="menu-container">
        <DraggableCard ref={mapRef} />
        {!isPhone && <RandomMintCard />}
      </div>
    </div>
  );
};

export default Home;
