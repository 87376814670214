import {
  fadeInVariants,
  motionContainerProps,
  scaleFadeInVariants,
} from "animation/variants";
import { motion } from "framer-motion";
import { ReactNode } from "react";

interface ModalProps {
  onClose: () => void;
  children: ReactNode;
  hideBackdrop?: boolean;
}
const Modal = ({ onClose, children, hideBackdrop }: ModalProps) => {
  return (
    <motion.div
      className="lboard-modal"
      {...motionContainerProps}
      exit="hidden"
      variants={fadeInVariants}
    >
      {!hideBackdrop && (
        <div className="lboard-modal__backdrop" onClick={onClose} />
      )}
      {children}
    </motion.div>
  );
};

export default Modal;
