const delay = 0.33;

export const defaultMotionContainerProps = {
	initial: "hidden",
	whileInView: "visible",
	viewport: { once: false },
};

export const motionSlideUpContainerProps = {
	...defaultMotionContainerProps,
	variants: {
		hidden: {
			y: "100%",
			opacity: 0,
		},
		visible: {
			y: 0,
			opacity: 1,
			transition: {
				delayChildren: delay,
				staggerChildren: delay,
			},
		},
	},
};

export const motionContainerProps = {
	...defaultMotionContainerProps,
	variants: {
		hidden: {
			opacity: 0,
		},
		visible: {
			opacity: 1,
			transition: {
				delayChildren: delay,
				staggerChildren: delay,
			},
		},
	},
};

export const fadeInVariants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
	},
};

export const scaleFadeInVariants = {
	hidden: {
		opacity: 0,
		scale: 0,
	},
	visible: {
		opacity: 1,
		scale: 1,
		transition: {
			duration: delay,
		},
	},
};

export const scaleInVariants = {
	hidden: {
		scale: 0,
	},
	visible: {
		scale: 1,
	},
};

export const slideUpMenuVariants = {
	expanded: {
		y: "20px",
	},
	collapsed: {
		y: "320px",
	},
};

export const notificationsVariants = {
	expanded: {
		y: 0,
		opacity: 1,
	},
	collapsed: {
		opacity: 0,
		y: 20,
	},
};

export const tabContentVariant = {
	active: {
		display: "block",
		transition: {
			staggerChildren: delay,
		},
	},
	inactive: {
		display: "none",
	},
};

export const cardVariant = {
	active: {
		opacity: 1,
		y: 0,
		transition: {
			duration: delay,
		},
	},
	inactive: {
		opacity: 0,
		y: 10,
		transition: {
			duration: delay,
		},
	},
};
