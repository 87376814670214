import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import { Option, OptionGroup, Select } from "components/dropdown";
import { PRESALE_1_LKLAND, PRESALE_2_LKLAND, tokenOptions } from "config";
import { useAtom } from "jotai";
import React, { useState } from "react";
import {
  allTokensAtom,
  balanceAtom,
  biggestMetaBalanceAtom,
  blacklistedTokensAtom,
  favoriteTokensAtom,
  metaBalanceAtom,
  selectedTokenIdAtom,
  userAtom,
} from "store/atoms";

const TokenSelector = () => {
  const [favoriteTokens] = useAtom(favoriteTokensAtom);
  const { account } = useGetAccountInfo();
  const [allTokens] = useAtom(allTokensAtom);
  const [balance] = useAtom(balanceAtom);
  const [metaBalance, setMetaBalance] = useAtom(metaBalanceAtom);
  const [biggestMetaBalance, setBiggestMetaBalance] = useAtom(
    biggestMetaBalanceAtom
  );
  const [metaBalanceWanted, setMetaBalanceWanted] = useAtom(metaBalanceAtom);

  const [blacklistTokens] = useAtom(blacklistedTokensAtom);
  const [selectedTokenId, setSelectedTokenId] = useAtom(selectedTokenIdAtom);
  const [readCount, setReadCount] = useState(6);

  let displayedTokens = allTokens.filter(
    (token: any) =>
      favoriteTokens.filter((t) => token.value === t.value).length == 0
  );

  const tokens = [...displayedTokens, ...favoriteTokens];

  displayedTokens = displayedTokens.filter(
    (token: any) =>
      blacklistTokens.filter((t) => token.value.split("-")[0] === t).length == 0
  );

  return (
    <Select
      value={selectedTokenId}
      render={() => {
        return (
          <div className="flex items-center">
            <div className="w-10 h-full mr-5">
              <img
                className="object-fill w-full"
                src={tokens.filter((t) => selectedTokenId == t.value)[0]?.url}
              />
            </div>
            {tokens.filter((t) => selectedTokenId == t.value)[0]?.label ??
              "Select..."}{" "}
          </div>
        );
      }}
      onChange={(value) => {
        setSelectedTokenId(value);
      }}
      readCount={readCount}
      setReadCount={setReadCount}
    >
      <OptionGroup label="Popular">
        {favoriteTokens.map((token: any) => {
          const isEgld = token.label === "EGLD";
          const isMetaLkland =
            token.value == PRESALE_1_LKLAND || token.value == PRESALE_2_LKLAND;

          let metaBalanceWanted = metaBalance.filter(
            (a) =>
              `${a["identifier"].split("-")[0]}-${
                a["identifier"].split("-")[1]
              }` == token.value
          );

          // setMetaBalanceWanted(metaBalanceWanted);

          let metaBalanceTotal = metaBalanceWanted[0];

          const specificBalance = !isEgld
            ? !isMetaLkland
              ? balance.filter((a) => a["identifier"] == token.value)[0]
              : metaBalanceWanted.length > 0 &&
                metaBalanceWanted.reduce((prev, current) =>
                  Number(prev.balance) > Number(current.balance)
                    ? prev
                    : current
                )
            : { balance: Number(account["balance"]), decimals: 18 };

          return (
            <Option key={token.label} value={token.value}>
              <div className="flex items-center">
                <div className="w-10 h-full mr-5">
                  <img className="object-fill w-full" src={token.url} />
                </div>
                <div className="w-1/2">{token.label}</div>
              </div>
              <p className="text-xs">
                {specificBalance
                  ? (
                      specificBalance["balance"] /
                      10 ** specificBalance["decimals"]
                    ).toFixed(4)
                  : 0}
              </p>
            </Option>
          );
        })}
      </OptionGroup>
      <OptionGroup label={"All tokens"}>
        {displayedTokens &&
          displayedTokens?.map((token: any) => {
            const specificBalance = balance.filter(
              (a) => a["identifier"] == token.value
            )[0];
            return (
              <Option key={token.label} value={token.value}>
                <div className="flex items-center">
                  <div className="w-10 h-full mr-5">
                    <img className="object-fill w-full " src={token.url} />
                  </div>
                  <div className="w-1/2">{token.label}</div>
                </div>
                <p className="text-xs">
                  {specificBalance
                    ? (
                        specificBalance["balance"] /
                        10 ** specificBalance["decimals"]
                      ).toFixed(4)
                    : 0}
                </p>
              </Option>
            );
          })}
      </OptionGroup>
    </Select>
  );
};

export default TokenSelector;
