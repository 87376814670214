import Button from "components/buttons";
import { Icon } from "components/icons/Icon";
import { STORAGE_URL } from "config";
import { useAtom } from "jotai";
import {
	TelegramIcon,
	TelegramShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from "react-share";
import { afterBuyModalAtom, userAtom } from "store/atoms";

const AfterBuyCard = () => {
	const [afterBuyModal, setAfterBuyModal] = useAtom(afterBuyModalAtom);
	const [user] = useAtom(userAtom);
	const { address, herotag } = user;

	const link = window.location.href;
	const title = "I just bought a tile on Landboard, the newest metaverse application in the Elrond ecosystem!";

	const handleClose = () => {
		setAfterBuyModal({ isOpen: false, tiles: [] });
	};

	return (
		<div className="after-buy-card">
			<h2>
				You bought {afterBuyModal.tiles.length} tile{afterBuyModal.tiles.length > 1 ? "s" : ""}!
			</h2>
			<button className="after-buy-card__close" onClick={handleClose}>
				<Icon width={28} name="close" primary />
			</button>
			<div className="after-buy-card__grid">
				{afterBuyModal.tiles.map((tile, index) => {
					<img src={STORAGE_URL + "/nft/" + tile.quad_key + ".webp"} alt="nft-image" />;
				})}
			</div>
			<div className="after-buy-card__socials">
				<TelegramShareButton url={link} title={title} className="">
					<TelegramIcon size={60} />
				</TelegramShareButton>
				<TwitterShareButton url={link} title={title + `\n\n🟣`} className="" hashtags={["landboard", "metaverse"]}>
					<TwitterIcon size={60} />
				</TwitterShareButton>
				<WhatsappShareButton url={link} title={title} separator=":: " className="">
					<WhatsappIcon size={60} />
				</WhatsappShareButton>
			</div>
			<div className="after-buy-card__actions">
				<Button className="filled" onClick={handleClose}>
					Keep buying
				</Button>
				<Button className="outline" link={"/users/" + (herotag ?? address)}>
					Go to profile
				</Button>
			</div>
		</div>
	);
};

export default AfterBuyCard;
