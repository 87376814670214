import {
  //   @ts-ignore
  useGetAccountInfo,
  //   @ts-ignore
  useGetLoginInfo,
  useGetNetworkConfig,
} from "@multiversx/sdk-dapp/hooks";
import { logout } from "@multiversx/sdk-dapp/utils";
import { motionContainerProps, scaleInVariants } from "animation/variants";
//   @ts-ignore
import { fetchTokens } from "api/transaction";
import { request } from "api/request";
import { getUserData, supabase } from "api/supabase-client";
import Button from "components/buttons";
import { Icon } from "components/icons/Icon";
import {
  API_URL,
  BUCKET_NAME,
  LKTOKEN_ID1,
  LKTOKEN_ID2,
  STORAGE_URL,
  TOKEN_ID,
} from "config";
import { AnimatePresence, motion } from "framer-motion";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { landBalanceAtom, userAtom } from "store/atoms";
import isEmpty from "lodash/isEmpty";
import axios from "axios";

const AddressButton = ({ onClick }: any) => {
  const { isLoggedIn } = useGetLoginInfo();
  const { address, account } = useGetAccountInfo();

  const [landBalance, setLandBalance] = useAtom(landBalanceAtom);
  const [user, setUser] = useAtom(userAtom);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const isInUnlock = window.location.pathname.includes("unlock");
  const { network } = useGetNetworkConfig();

  const herotag = user?.herotag;

  const handleLogout = () => {
    logout(`${window.location.origin}`);
  };

  const handleUnlock = () => {
    if (isInUnlock) {
      navigate("/");
    } else {
      navigate("/unlock");
    }
    if (onClick) onClick();
  };

  useEffect(() => {
    if (account.address != "") {
      fetchTokens(network.apiAddress, account.address).then((res: any) => {
        if (res.data?.length > 0) {
          const tokens = res.data.filter(
            (a: any) => a?.identifier === TOKEN_ID || a?.ticker === TOKEN_ID
          );
          const lkLand1 = res.data.filter(
            (a: any) =>
              a?.identifier === LKTOKEN_ID1 || a?.ticker === LKTOKEN_ID1
          );
          const lkLand2 = res.data.filter(
            (a: any) =>
              a?.identifier === LKTOKEN_ID2 || a?.ticker === LKTOKEN_ID2
          );
          setLandBalance({
            [TOKEN_ID]:
              tokens.length > 0 ? Math.floor(tokens[0]?.balance / 10 ** 18) : 0,
            [LKTOKEN_ID1]:
              lkLand1.length > 0
                ? Math.floor(lkLand1[0]?.balance / 10 ** 18)
                : 0,
            [LKTOKEN_ID2]:
              lkLand2.length > 0
                ? Math.floor(lkLand2[0]?.balance / 10 ** 18)
                : 0,
          });
        }
      });
    }
  }, [account]);

  useEffect(() => {
    if (isLoggedIn) {
      getUserData(address)
        .then((user: any) => {
          const { publicURL, error } = supabase.storage
            .from(BUCKET_NAME)
            .getPublicUrl(`avatars/${address}.webp`);

          setUser({
            ...user,
            address,
            avatar: error ? null : publicURL + `?t=${Date.now()}`,
          });
        })
        .finally(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn && !loading && isEmpty(user?.username)) {
      axios.get(`${API_URL}/accounts/${address}`).then((res) => {
        const herotag = res.data?.username
          ? res.data.username.split(".")[0]
          : null;
        request("/users", { address, username: herotag, herotag });
      });
    }
  }, [user, loading]);

  return (
    <div className="address-button">
      {!isLoggedIn && (
        <Button className="filled" onClick={handleUnlock} animate>
          <Icon name="unlock" />
          {isInUnlock ? "Back" : "Connect"}
        </Button>
      )}
      <AnimatePresence>
        {isLoggedIn && user.address && (
          <motion.div
            className="flex"
            {...motionContainerProps}
            variants={scaleInVariants}
          >
            <div className="account-button__address">
              <span className="font-bold text-purple">
                {herotag ?? address.slice(0, 6) + "..." + address.slice(-4)}
              </span>
              <div className="account-button__tag-container">
                <span className="account-button__tag">
                  {landBalance[TOKEN_ID].toFixed(2)} LAND
                </span>
              </div>
            </div>
            <Button className="filled w-[8.75rem]" onClick={handleLogout}>
              <Icon name="unlock" />
              Disconnect
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AddressButton;
