import { sendTransactions } from "@multiversx/sdk-dapp/services";
import { refreshAccount } from "@multiversx/sdk-dapp/utils";
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks";
import {
  Address,
  Transaction,
  TransactionPayload,
} from "@elrondnetwork/erdjs/out";
import { fadeInVariants } from "animation/variants";
import { request } from "api/request";
import { getUserData, getUserNFTs } from "api/supabase-client";
import { fetchTokens } from "api/transaction";
import axios from "axios";
import Button from "components/buttons";
import CopyButton from "components/buttons/CopyButton";
import ImageCard from "components/cards/ImageCard";
import PlaceCard from "components/cards/PlaceCard";
import { Icon } from "components/icons/Icon";
import Input from "components/input";
import Spinner from "components/loading/Spinner";
import NotFound from "components/notFound";
import Badges from "components/sections/Badges";
import Tabs from "components/tabs";
import ImageUpload from "components/upload/ImageUpload";
import {
  BADGE_NFT_ID,
  COLLECTION_ID,
  CONTRACT_ADDRESS,
  STORAGE_URL,
  TOKEN_ID,
} from "config";
import { AnimatePresence, motion } from "framer-motion";
import { useAtom } from "jotai";
import get from "lodash/get";
import { Fragment, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounce, useMedia } from "react-use";
import {
  badgesOwnedAtom,
  loadingAtom,
  shareReferralModalAtom,
  startLocationAtom,
  totalRewardsAtom,
  userAtom,
  userProfileAtom,
} from "store/atoms";
import { TabType } from "types/components";
import { Badge, NFT } from "types/Tiles";
import { notifyLoading, notifySuccess } from "utils/notifications";
import useMintContract from "utils/useMintContract";

interface DetailsProps {
  username: string;
  totalLand: string;
  address: string;
  avatar: string;
  tileCount: number;
}

const DetailsForm = ({
  address,
  username,
}: {
  address: string;
  username: string;
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useFormContext();
  const [userProfile, setUserProfile] = useAtom(userProfileAtom);
  const [loading, setLoading] = useState(false);
  const [bridgeNfts, setBridgeNfts] = useState([]);
  const [transactionSessionId, setTransactionSessionId] = useState("");

  useEffect(() => {
    register("username");
  }, []);

  const buildNftPayLoad = (nft: any) => {
    const hexid = Buffer.from(nft.collection).toString("hex");
    let hexnonce = parseInt(nft.nonce).toString(16);
    if (hexnonce.length % 2 !== 0) {
      hexnonce = "0" + hexnonce;
    }
    return "@" + hexid + "@" + hexnonce + "@01";
  };

  const api = "https://api.elrond.com";
  useEffect(() => {
    axios
      .get(`${api}/accounts/${address}/nfts?search=${COLLECTION_ID}&size=100`)
      .then((res) => {
        setBridgeNfts(res.data);
      });
  }, []);

  const sendBridgeTransaction = async (ev: any) => {
    const sliceEnd = 20;
    ev.preventDefault();
    if (bridgeNfts.length > 0) {
      let hexamount = bridgeNfts.slice(0, sliceEnd).length.toString(16);
      if (hexamount.length % 2 !== 0) {
        hexamount = "0" + hexamount;
      }
      let data =
        "MultiESDTNFTTransfer" +
        "@" +
        new Address(CONTRACT_ADDRESS).hex() +
        "@" +
        hexamount;
      bridgeNfts.slice(0, sliceEnd).forEach((nft) => {
        data += buildNftPayLoad(nft);
      });
      data += "@" + Buffer.from("bridgeNfts").toString("hex");

      const bridgeNftTransaction = new Transaction({
        value: 0,
        data: new TransactionPayload(data),
        receiver: new Address(address),
        gasLimit: 20000000 * (bridgeNfts.slice(0, sliceEnd).length + 1),
        chainID: "1",
        sender: new Address(address),
      });

      await refreshAccount();
      const { sessionId, error } = await sendTransactions({
        transactions: bridgeNftTransaction,
        transactionsDisplayInfo: {
          processingMessage: "Bridging tiles to V2...",
          errorMessage: "Error occured during tile bridging",
          successMessage: "Tiles bridged successfully",
        },
        redirectAfterSign: false,
      });
      if (sessionId != null) {
        setTransactionSessionId(sessionId);
      }
    }
  };

  const onSubmit = (data: any) => {
    const { username } = data;
    setLoading(true);
    request("/users", { address, username })
      .then(() => {
        setUserProfile({ ...userProfile, username });
        notifySuccess("Update profile succesful");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Username"
          defaultValue={username}
          placeholder="ultra_lander"
          onChange={(e) => setValue("username", e.target.value)}
        />
        <div className="flex gap-5">
          <Button
            containerClassname="flex-1"
            className="filled"
            type="submit"
            disabled={loading}
            hideComingSoon
          >
            {!loading && <Icon name="save" />}
            <AnimatePresence>{loading && <Spinner />}</AnimatePresence>
            Save
          </Button>
          <Button
            containerClassname="flex-1"
            className="outline"
            disabled={loading || !bridgeNfts.length}
            type="button"
            hideComingSoon
            // disabled
            onClick={sendBridgeTransaction}
          >
            <AnimatePresence>{loading && <Spinner />}</AnimatePresence>
            Bridge NFTs
          </Button>
        </div>
      </form>
    </>
  );
};

const Details = ({
  username,
  totalLand,
  tileCount,
  avatar,
  address: addressParam,
}: DetailsProps) => {
  const methods = useForm();

  const [user, setUser] = useAtom(userAtom);
  const { address, herotag } = user;

  const isProfileOwner = address === addressParam || herotag === addressParam;

  const [loadingUpload, setLoadingUpload] = useState(false);

  const handleUploadAvatar = (image: string) => {
    setLoadingUpload(true);
    request("/users/upload", {
      address,
      image: image
        .replace("data:image/png;base64,", "")
        .replace("data:image/jpeg;base64,", ""),
    })
      .then((response) => {
        if (response) {
          notifySuccess("Image updated.");
          setUser({
            ...user,
            avatar: `${STORAGE_URL}/avatars/${address}.webp?t=${Date.now()}`,
          });
        }
      })
      .finally(() => setLoadingUpload(false));
  };

  return (
    <div className="details">
      <FormProvider {...methods}>
        {!isProfileOwner && <ImageCard image={avatar} />}
        {isProfileOwner && (
          <ImageUpload
            defaultImage={avatar}
            onUpload={handleUploadAvatar}
            loading={loadingUpload}
          />
        )}

        <div className="details__content">
          <h1>{username}</h1>
          <ul>
            <li>
              <Icon name="tag1" primary />
              <span>
                Token Owned: <span className="text-purple">{totalLand}</span>
              </span>
            </li>
            <li>
              <Icon name="tile" primary />
              <span>
                Tiles Owned: <span className="text-purple">{tileCount}</span>
              </span>
            </li>
          </ul>
          {address && isProfileOwner && (
            <DetailsForm address={address} username={username} />
          )}
        </div>
      </FormProvider>
    </div>
  );
};

interface RewardsProps {
  address: string;
  herotag: string;
  totalRewards: number;
  isMinimal: boolean;
}

export const Rewards = ({
  address,
  herotag,
  totalRewards,
  isMinimal,
}: RewardsProps) => {
  const [heroTag, setHeroTag] = useState(null);
  const [referralMoney, setReferralMoney] = useState(0);
  const [referralCount, setReferralCount] = useState(0);
  const [badges, setBadges] = useAtom(badgesOwnedAtom);
  const { mintContract } = useMintContract(TOKEN_ID);
  const [shareReferralModal, setShareReferralModal] = useAtom(
    shareReferralModalAtom
  );

  const getAmountEarned = () => {
    address !== "" &&
      axios
        .get(
          `https://api.elrond.com/accounts/${CONTRACT_ADDRESS}/transfers?receiver=${address}&token=LAND-40f26f`
        )
        .then(({ data }) => {
          setReferralCount(data.length);
          // setReferralMoney to all action.arguments from the data
          let total = 0;
          data.forEach((tx: any) => {
            // console.log("action", action);
            total += tx.action.arguments.transfers[0].value / 10 ** 18;
          });

          setReferralMoney(total);
          // setReferralMoney(data.reduce((acc, curr) => acc + curr.value, 0));
        });
  };

  useEffect(() => {
    getAmountEarned();
  }, []);

  useEffect(() => {
    if (address != "") {
      axios
        .get(`https://api.elrond.com/accounts/${address}`)
        .then((res: any) => {
          res.data?.username && setHeroTag(res.data?.username);
        });
    }
  }, [address]);

  useEffect(() => {
    if (address != "") {
      axios
        .get(
          `https://api.elrond.com/accounts/${address}/nfts?collection=${BADGE_NFT_ID}`
        )
        .then((res: any) => {
          setBadges(
            res.data.map((nft: any) => ({
              src: nft.media[0].url,
              name: nft.name,
              id: nft.identifier,
            }))
          );
        });
    }
  }, [address]);

  const referralCode = heroTag ?? address;

  return (
    <div className="rewards">
      {!isMinimal && (
        <motion.div className="home__title">
          <motion.h1 variants={fadeInVariants}>
            REFERRAL <span className="text-purple">&</span> REWARDS
          </motion.h1>
          <motion.p variants={fadeInVariants}>
            Earn 5% of each referred user that mints at least a tile. Rewards
            will be sent soon after.
          </motion.p>
        </motion.div>
      )}

      <div className="rewards-container">
        <Input
          placeholder="0"
          className="text-purple"
          label="Referral Code"
          value={referralCode}
          readOnly
          LabelButton={
            <CopyButton
              onClick={() => {
                try {
                  navigator.clipboard.writeText(
                    `Hey! I own virtual land NFTs on Landboard, the newest metaverse application built on the Elrond blockchain. You should try it too! Use my referral code 3% discount on the first mint: https://app.landboard.io?referral=` +
                      referralCode
                  );
                } catch (err) {
                  console.error("Failed to copy text: ", err);
                }
              }}
            />
          }
        />

        <div className="w-full">
          <Button
            className="w-full filled"
            onClick={() => setShareReferralModal({ isOpen: true })}
          >
            Share
          </Button>
        </div>
        <motion.h2 className="text-xl font-bold" variants={fadeInVariants}>
          YOUR REWARDS
        </motion.h2>
        <motion.ul variants={fadeInVariants} className="rewards-list">
          <li>
            <Icon primary name="user" />
            <span>{referralCount} referred friends</span>
          </li>
          <li>
            <Icon primary name="land" />
            <span>{referralMoney} LAND earned</span>
          </li>
          {!isMinimal && (
            <li>
              <Icon primary name="tile" />
              <span>tiles: earned {totalRewards} LAND</span>
            </li>
          )}
        </motion.ul>
        {/* {referralCount !== 0 && <span>See referral history</span>} */}
      </div>
      {/* {badges.length > 0 && <Badges badges={badges} />} */}
    </div>
  );
};

interface LandsProps {
  tiles: NFT[];
  address: string;
}

const Tiles = ({ tiles, address }: LandsProps) => {
  const notFound = tiles.length === 0;

  const [, setStartLocation] = useAtom(startLocationAtom);
  const [filteredTiles, setFilteredTiles] = useState(tiles);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");

  const navigate = useNavigate();

  const handleFilter = (e: any) => setFilter(e.target.value);

  const handleGoToMap = (center: []) => {
    if (center) {
      setStartLocation(center);
      navigate("/");
    }
  };

  const handleSync = () => {
    setLoading(true);
    notifyLoading("Sync started...");
    request("/nfts/sync", { address })
      .then(() => {
        notifySuccess("Sync finished.");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => setFilteredTiles(tiles), [tiles]);

  useDebounce(
    () => {
      const filteredTiles = tiles.filter((tile) => {
        return (
          tile.name.toLowerCase().includes(filter.toLowerCase()) ||
          tile?.location?.toLowerCase().includes(filter.toLowerCase())
        );
      });
      setFilteredTiles(filteredTiles);
    },
    500,
    [filter]
  );

  return (
    <div className="lands">
      <div className="filter-card">
        <Input
          label="Search"
          placeholder="Tile name or location"
          onChange={handleFilter}
        />
        <Button
          className="filled"
          onClick={handleSync}
          disabled={loading}
          hideComingSoon
        >
          {!loading && <Icon name="refresh" />}
          {loading && <Spinner />}
          Sync
        </Button>
      </div>
      <div className={`lands-grid${notFound ? "__not-found" : ""}`}>
        {filteredTiles.map((tile) => {
          let center;
          try {
            center =
              JSON.parse(tile.center)[0].toFixed(6) +
              ", " +
              JSON.parse(tile.center)[1].toFixed(6);
          } catch (err) {}

          return (
            <PlaceCard
              id={tile.quad_key}
              key={tile.quad_key}
              center={center}
              name={tile.name}
              location={tile.location}
              link={tile.link}
              nftId={tile.image_nft_id}
              pricePerTile={800 + " LAND"}
              handleGoToMap={() => handleGoToMap(JSON.parse(tile.center))}
            />
          );
        })}
        {(notFound || filteredTiles.length == 0) && (
          <NotFound>
            <motion.h1 variants={fadeInVariants}>
              Oh no. <br />
              No Tiles <span className="text-purple">found.</span>
            </motion.h1>
            <motion.p variants={fadeInVariants}>
              Maybe Dracula has stolen all the tiles.{" "}
            </motion.p>
          </NotFound>
        )}
      </div>
    </div>
  );
};

const User = () => {
  const isPhone = useMedia("(max-width: 767px)");
  const { network } = useGetNetworkConfig();

  const { address } = useParams();
  const navigate = useNavigate();
  const [, setLoading] = useAtom(loadingAtom);
  const [userProfile, setUserProfile] = useAtom(userProfileAtom);
  const [totalRewards, setTotalRewards] = useAtom(totalRewardsAtom);

  const [realAddress, setRealAddress] = useState<any>(address);

  useEffect(() => {
    if (address && address.length < 60) {
      axios
        .get(`https://api.elrond.com/usernames/${address}`)
        .then((res) => setRealAddress(res.data.address));
    } else {
      setRealAddress(address);
    }
  }, [address]);

  useEffect(() => {
    if (realAddress) {
      getUserData(realAddress)
        .then((user: any) => {
          Promise.all([
            fetchTokens(network.apiAddress, user.address),
            getUserNFTs(user.address),
          ]).then(([res, nfts]) => {
            if (!user) {
              navigate("/not-found");
            }
            const totalLand =
              get(
                res.data.filter(
                  (a: any) =>
                    a?.identifier === TOKEN_ID || a?.ticker === TOKEN_ID
                )[0],
                "balance",
                0
              ) /
                10 ** 18 +
              " LAND";
            setUserProfile({
              ...userProfile,
              ...user,
              avatar: `${STORAGE_URL}/avatars/${
                user?.address
              }.webp?t=${Date.now()}`,
              totalLand,
              tiles: nfts.map((nft: any) => ({
                ...nft,
                center: nft.tiles.center,
                location: nft.tiles.location,
              })),
            });
            setTotalRewards(
              nfts.reduce((acc: number, nft: any) => acc + nft.reward, 0)
            );
          });
        })
        .finally(() => setLoading(false));
    }
  }, [realAddress]);

  const { tiles, avatar, ...detailsProps } = userProfile;

  const addressToShow = userProfile?.address ?? realAddress ?? "";

  const tabs: TabType[] = useMemo(
    () => [
      {
        id: 1,
        title: "Details",
        content: (
          <Details
            {...detailsProps}
            address={addressToShow}
            tileCount={tiles.length}
            avatar={avatar}
          />
        ),
      },
      {
        id: 2,
        title: "Tiles",
        content: <Tiles tiles={tiles} address={addressToShow} />,
      },
      {
        id: 3,
        title: "Rewards",
        content: (
          <Rewards
            totalRewards={totalRewards}
            herotag={userProfile?.herotag}
            address={addressToShow}
            isMinimal={false}
          />
        ),
      },
    ],
    [userProfile, tiles, addressToShow]
  );

  return (
    <div className="profile">
      {isPhone && <Tabs tabs={tabs} />}
      {!isPhone && (
        <Fragment>
          <Details {...detailsProps} avatar={avatar} tileCount={tiles.length} />
          <Tiles tiles={tiles} address={addressToShow} />
          <Rewards
            totalRewards={totalRewards}
            herotag={userProfile?.herotag}
            address={addressToShow}
            isMinimal={false}
          />
        </Fragment>
      )}
    </div>
  );
};

export default User;
