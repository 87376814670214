import { Icon } from "components/icons/Icon";
import { useAtom } from "jotai";
import { mapStyleAtom, mapStyles } from "store/atoms";

const Picker = () => {
	const [mapStyle, setMapStyle] = useAtom(mapStyleAtom);

	return (
		<div className="theme-picker">
			<div className="theme-picker__label">
				<Icon name="palette" primary />
				<span>Theme</span>
			</div>
			<ul className="theme-picker__themes">
				<li className="theme-picker__theme bg-purple" onClick={() => setMapStyle(mapStyles[0])}></li>
				<li className="bg-white theme-picker__theme" onClick={() => setMapStyle(mapStyles[1])}></li>
			</ul>
		</div>
	);
};

export default Picker;
