import { scaleFadeInVariants } from "animation/variants";
import Button from "components/buttons";
import Checkbox from "components/checkbox";
import { Select } from "components/dropdown";
import { Icon } from "components/icons/Icon";
import Input from "components/input";
import { motion } from "framer-motion";
import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { offerModalAtom, listModalAtom } from "store/atoms";
import TinyPlaceCard from "./TinyPlaceCard";
import TokenSelector from "../TokenSelector";

const ListPlaceCard = () => {
  const [listModal, setListModal] = useAtom(listModalAtom);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const isChecked = watch("isChecked");
  const amount = watch("amount");
  const days = watch("days");

  useEffect(() => {
    register("isChecked", {
      required: "You must agree to the terms and conditions",
    });
    register("amount", {
      // required: "You must enter an amount",
    });
    register("days", {
      // required: "You must enter the number of days",
    });
  }, []);

  const handleClose = () => {
    setListModal({ ...listModal, isOpen: false });
  };

  const { name, tileCount, owner, lastPrice, image } = listModal;

  const onSubmit = (data: unknown) => console.log(data);

  return (
    <motion.div
      className="offer-place-card"
      variants={scaleFadeInVariants}
      animate="visible"
      initial="hidden"
      exit="hidden"
    >
      <button className="offer-place-card__close" onClick={handleClose}>
        <Icon width={20} name="close" primary />
      </button>
      <h1>List tile for sale</h1>
      <TinyPlaceCard
        name={name}
        owner={owner}
        tileCount={tileCount}
        lastPrice={lastPrice}
        image={image}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TokenSelector />
        <Input
          label="Amount"
          placeholder="Token Amount"
          onChange={(e) => setValue("amount", e.target.value)}
        />
        <Input
          label="Expires in (DAYS)"
          placeholder="30"
          onChange={(e) => setValue("days", e.target.value)}
        />

        <div className="text-white offer-place-card__info">
          <span>
            Important: by listing a tile, you will lose the staking rewards for
            owning it and you will not be able to use it in the app.
          </span>
        </div>
        <Checkbox
          onChange={(isChecked) => {
            setValue("isChecked", isChecked);
          }}
          defaultChecked={isChecked}
          label="By checking this box, I agree to LandBoard’s Terms of Service."
        />
        <div className="offer-place-card__actions">
          <Button
            containerClassname="flex-1"
            className="filled"
            type="submit"
            disabled={!(isChecked && amount && days)}
            hideComingSoon
          >
            <Icon name="tag2" />
            List for sale
          </Button>
        </div>
      </form>
    </motion.div>
  );
};

export default ListPlaceCard;
