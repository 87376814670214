import Button from "components/buttons";
import { Icon } from "components/icons/Icon";
import Spinner from "components/loading/Spinner";
import { useState } from "react";
import ReactImageUploading, { ImageListType } from "react-images-uploading";
import { AnimatePresence } from "framer-motion";
import Checkbox from "components/checkbox";

interface ImageUploadProps {
  defaultImage?: string;
  onUpload: (image: string, updateAll?: boolean) => void;
  loading?: boolean;
  nftId?: string;
}

const maxNumber = 1;

const ImageUpload = ({
  defaultImage,
  onUpload,
  loading,
  nftId,
}: ImageUploadProps) => {
  const [images, setImages] = useState<ImageListType>([]);
  const [updateAll, setUpdateAll] = useState(false);

  const onChange = (imageList: ImageListType, addUpdateIndex?: number[]) => {
    setImages(imageList);
  };

  const hasImage = images.length > 0;

  return (
    <ReactImageUploading
      multiple
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
      dataURLKey="data_url"
      maxFileSize={5_000_000}
    >
      {({
        errors,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        <div className="avatar-uploader">
          <button onClick={onImageUpload} {...dragProps}>
            <figure
              className={hasImage ? "bg-cover" : "bg-contain"}
              style={{
                backgroundImage: !hasImage
                  ? `url(${defaultImage})`.replace(`"`, "")
                  : `url(${images[0].data_url})`.replace(`"`, ""),
              }}
            />
            <img
              className="avatar-uploader__overlay"
              src="/assets/images/upload-overlay.png"
              alt="upload overlay"
              height={80}
            />
          </button>
          {nftId && (
            <a
              href={`https://explorer.elrond.com/nfts/${nftId}`}
              className="badge"
            >
              <Icon name="star" width={16} primary />
              {nftId}
            </a>
          )}
          {hasImage && (
            <div className="upload-button">
              <Checkbox
                defaultChecked={updateAll}
                onChange={(isChecked) => setUpdateAll(isChecked)}
                label="Update all tiles"
              />
              <Button
                className="filled small"
                onClick={() => onUpload(images[0].data_url, updateAll)}
                disabled={loading}
                hideComingSoon
              >
                <AnimatePresence>{loading && <Spinner />}</AnimatePresence>
                {!loading && "Upload"}
              </Button>
            </div>
          )}
          {hasImage && (
            <button className="avatar-close" onClick={onImageRemoveAll}>
              <Icon name="close" primary width={28} />
            </button>
          )}
          {errors?.maxFileSize && (
            <span className="avatar-uploader__error">
              {"Selected file size > 5 MB"}
            </span>
          )}
        </div>
      )}
    </ReactImageUploading>
  );
};

export default ImageUpload;
