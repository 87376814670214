import {
  useGetAccountInfo,
  useTrackTransactionStatus,
} from "@multiversx/sdk-dapp/hooks";
import { scaleFadeInVariants } from "animation/variants";
import { getBalance } from "api/transaction";
import axios from "axios";
import Button from "components/buttons";
import { Option, OptionGroup, Select } from "components/dropdown";
import { Icon } from "components/icons/Icon";
import Input from "components/input";
import Spinner from "components/loading/Spinner";
import {
  API_URL,
  LKTOKEN_ID1,
  MINT_TIME,
  PRICES,
  tokenOptions,
  TOKEN_ID,
  WEGLD_ID,
} from "config";
import { AnimatePresence, motion } from "framer-motion";
import useTimeUntil from "hooks/useTimeUntil";
import { useAtom } from "jotai";
import { clone, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMedia } from "react-use";
import {
  allTokensAtom,
  balanceAtom,
  biggestMetaBalanceAtom,
  favoriteTokensAtom,
  isLandAtom,
  isLkLandAtom,
  isLoadingAtom,
  isPromoActiveAtom,
  landBalanceAtom,
  landPairAtom,
  metaBalanceAtom,
  pairsAtom,
  randomMintModalAtom,
  selectedTokenIdAtom,
  tokenAmountAtom,
  transactionsAtom,
  userAtom,
} from "store/atoms";
import { notifyError, notifyLoading, notifySuccess } from "utils/notifications";
import { convertFromUSDC, getEquivalent } from "utils/swap";
import {
  getPairs,
  handleChangeTileCount,
  handleGetBalance,
  handleRandomMint,
  handleTokenChange,
  handleUpdateInputsReverse,
  handleWrapEGLD,
} from "utils/swapTransactions";
import useMintContract from "utils/useMintContract";
import Promo from "../Promo";
import TokenSelector from "../TokenSelector";

const RandomMintCard = () => {
  const [user] = useAtom(userAtom);
  const [transactions, setTransactions] = useAtom(transactionsAtom);
  const [favoriteTokens] = useAtom(favoriteTokensAtom);
  const [allTokens] = useAtom(allTokensAtom);
  const [isPromoActive, setIsPromoActive] = useAtom(isPromoActiveAtom);

  const [balance, setBalance] = useAtom(balanceAtom);
  const [pairs, setPairs] = useAtom(pairsAtom);

  const [selectedTokenId, setSelectedTokenId] = useAtom(selectedTokenIdAtom);
  const [isLand, setIsLand] = useAtom(isLandAtom);
  const [isLkLand, setIsLkLand] = useAtom(isLkLandAtom);
  const [landPair, setLandPair] = useAtom(landPairAtom);
  const [tokenAmount, setTokenAmount] = useAtom(tokenAmountAtom);
  const [metaBalance, setMetaBalance] = useAtom(metaBalanceAtom);

  const [metaBalanceWanted, setMetaBalanceWanted] = useAtom(metaBalanceAtom);

  const [usdcAmount, setUsdcAmount] = useState(0);
  const [wegldAmount, setWegldAmount] = useState(0);

  const [loading, setLoading] = useAtom(isLoadingAtom);
  const [selectedTokenBalance, setSelectedTokenBalance] = useState(0);

  const [totalPrice, setTotalPrice] = useState(533);

  const [res, setRes] = useState(null);

  const [tileCount, setTileCount] = useState(1);
  const [referralCode, setReferralCode] = useState("");
  const [canReferral, setCanReferral] = useState(true);
  const [hasWrapped, setHasWrapped] = useState(false);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [hasShownPending, setHasShownPending] = useState(false);

  const account = useGetAccountInfo();

  const [, setRandomMintModal] = useAtom(randomMintModalAtom);

  const isPhone = useMedia("(max-width: 767px)");
  const { mintContract } = useMintContract(TOKEN_ID);
  const [searchParams] = useSearchParams();

  const { timeLeft, hours, minutes, seconds } = useTimeUntil(MINT_TIME);

  const transactionStatus = useTrackTransactionStatus({
    transactionId: sessionId,
    onSuccess: () => {
      notifySuccess("Transaction successful");
      if (selectedTokenId === "EGLD") {
        setSelectedTokenId(WEGLD_ID);
        setHasWrapped(true);
      }
    },
    onFail: () => {
      notifyError("Transaction failed");
    },
  });

  useEffect(() => {
    if (
      !hasShownPending &&
      transactionStatus.isPending &&
      transactionStatus.transactions
    ) {
      notifyLoading(
        "Transaction pending",
        // @ts-ignore
        transactionStatus.transactions[0].hash
      );
      setHasShownPending(true);
    }

    // @ts-ignore
    // console.log(transactionStatus.transactions[0].hash);
  }, [transactionStatus]);

  const isConnected = !isEmpty(user);
  useEffect(() => {
    setTotalPrice(tileCount * (!isLand ? 710 : PRICES[TOKEN_ID].random));
  }, [isLkLand, isLand]);

  useEffect(() => {
    user.address &&
      getBalance(user.address).then((res) => {
        setMetaBalance(res.metaBalance);
        setBalance(res.balance);
      });
  }, [user]);

  useEffect(() => {
    handleGetBalance(
      setSelectedTokenBalance,
      selectedTokenId,
      account.account,
      balance
    );
  }, [selectedTokenId, balance]);

  useEffect(() => {
    setReferralCode(searchParams.get("referral") ?? "");
  }, [searchParams]);

  useEffect(() => {
    if (transactions.every((transaction) => transaction.type !== "random")) {
      setLoading(false);
    }
  }, [transactions]);

  useEffect(() => {
    if (user.address && mintContract) {
      axios
        .get(
          `${API_URL}/accounts/${user.address}/nfts/count?collections=TILE-4aaa4f%2C%20TILE-9d6c87`
        )
        .then((res) => {
          res.data !== 0 && setCanReferral(false);
        });
    }
  }, [user, mintContract]);

  useEffect(() => {
    getPairs().then((res) => {
      setPairs(res.data.data.pairs);
      setLandPair(
        res.data.data.pairs.filter(
          (pair: any) =>
            pair.firstToken.identifier === TOKEN_ID && pair.state === "Active"
        )[0]
      );
    });
  }, [TOKEN_ID, selectedTokenId]);

  useEffect(() => {
    landPair &&
      selectedTokenId &&
      selectedTokenId.split("-")[0] !== "LAND" &&
      Object.keys(allTokens).length > 1 &&
      handleUpdateInputsReverse(
        533,
        selectedTokenId,
        allTokens,
        pairs,
        landPair,
        setUsdcAmount,
        setWegldAmount,
        setTokenAmount,
        true
      );
  }, [landPair, selectedTokenId, allTokens, selectedTokenId]);

  const handleChangeReferralCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReferralCode(e.target.value);
  };

  const handleClose = () => {
    setRandomMintModal({ isOpen: false });
  };

  const handleClickRandomMint = async () => {
    if (needsWrap) {
      const sessionId = await handleWrapEGLD({
        mintContract,
        totalPrice: tokenAmount,
        tileCount,
        userShard: account.shard ?? 1,
      });
      setSessionId(sessionId);
    } else {
      handleRandomMint(
        tileCount,
        referralCode,
        isLand || isLkLand
          ? totalPrice * tileCount
          : Number(tokenAmount.toFixed(5)),
        allTokens,
        setSessionId,
        selectedTokenId,
        pairs,
        user,
        transactions,
        setLoading,
        isLand,
        isLkLand,
        tokenAmount,
        usdcAmount,
        wegldAmount,
        setTransactions,
        mintContract,
        true,
        null,
        account.shard ?? 1,
        metaBalance
      );
    }
  };

  useEffect(() => {
    isLkLand && setTokenAmount(710);
    isLkLand && setTotalPrice(710);
  }, [isLkLand]);

  const hasEnoughEgldForGas = Number(account.account.balance) >= 13000000;

  useEffect(() => {
    handleTokenChange(selectedTokenId, setIsLand, setIsLkLand);
  }, [selectedTokenId]);

  const needsWrap = selectedTokenId == "EGLD" && !hasWrapped;
  const disabled =
    timeLeft > 0 || loading || !hasEnoughEgldForGas || tileCount === 0;

  function toFixedIfNecessary(value: number) {
    return +parseFloat(value.toString()).toFixed(4);
  }

  return (
    <motion.div
      className="random-mint-card"
      variants={scaleFadeInVariants}
      animate="visible"
      initial="hidden"
      exit="hidden"
    >
      {isPhone && (
        <button
          className="absolute buy-place-card__close"
          onClick={handleClose}
        >
          <Icon width={20} name="close" primary />
        </button>
      )}
      <h2>Random Mint</h2>
      {canReferral && isConnected && (
        <Input
          value={referralCode}
          placeholder="landboard"
          label="Referral Code (herotag or address)"
          onChange={handleChangeReferralCode}
        />
      )}

      <div className="random-mint-card__actions">
        <Input
          value={tileCount}
          placeholder="1"
          label="Tile Count"
          onChange={(e) => handleChangeTileCount(e, setTileCount)}
        />
        <Button
          className="filled"
          onClick={handleClickRandomMint}
          disabled={disabled}
          hideComingSoon
        >
          {!loading && timeLeft == -0 && <Icon name="dice-3" />}
          <AnimatePresence>{loading && <Spinner />}</AnimatePresence>
          {/* {timeLeft > 0 ? hours + "H : " + minutes + "M : " + seconds + "S" : loading ? "Minting" : "Mint"} */}
          {loading
            ? needsWrap
              ? "Wrapping"
              : "Minting"
            : needsWrap
            ? "Wrap"
            : "Mint"}
        </Button>
      </div>
      <div className="row">
        <TokenSelector />
      </div>

      {!hasEnoughEgldForGas && (
        <span style={{ color: "#d12323", fontSize: "12px", marginTop: -10 }}>
          Not enough EGLD to cover gas fees!
        </span>
      )}

      <div className="random-mint-card__details">
        {needsWrap && (
          <p>
            Click wrap before sending the random mint transaction to use EGLD.
          </p>
        )}
        <p>
          Balance: {selectedTokenBalance.toFixed(4)}{" "}
          {selectedTokenId.split("-")[0]}
        </p>
        <p>Max. buy per TX: 5 tiles</p>
        <h4>
          TOTAL:{" "}
          <span className="text-purple">
            {referralCode ? (
              toFixedIfNecessary(totalPrice - 0.03 * tokenAmount)
            ) : !isPromoActive ? (
              !(isLand || isLkLand) ? (
                toFixedIfNecessary(tileCount * tokenAmount)
              ) : (
                toFixedIfNecessary(tileCount * totalPrice)
              )
            ) : !(isLand || isLkLand) ? (
              <>
                <span className="ml-2 text-white">
                  {toFixedIfNecessary(
                    tileCount * tokenAmount - tileCount * tokenAmount * 0.15
                  )}{" "}
                  {selectedTokenId.split("-")[0]}
                </span>
                <span className="ml-3 line-through text-gray">
                  ({toFixedIfNecessary(tileCount * tokenAmount)}{" "}
                  {selectedTokenId.split("-")[0]})
                </span>
              </>
            ) : (
              <>
                <span className="ml-2 text-white">
                  {toFixedIfNecessary(
                    tileCount * totalPrice - tileCount * totalPrice * 0.15
                  )}{" "}
                  {selectedTokenId.split("-")[0]}
                </span>
                <span className="ml-3 line-through text-gray">
                  ({toFixedIfNecessary(tileCount * totalPrice)}{" "}
                  {selectedTokenId.split("-")[0]})
                </span>
              </>
            )}{" "}
            {!isPromoActive && selectedTokenId.split("-")[0]}
          </span>
        </h4>
        <div className="mt-3">{isPromoActive && <Promo isMini={true} />} </div>
      </div>
    </motion.div>
  );
};

export default RandomMintCard;
