import { addressIsValid } from "@multiversx/sdk-dapp/utils";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import Button from "components/buttons";
import { Icon } from "components/icons/Icon";
import Spinner from "components/loading/Spinner";
import { MINT_TIME, TOKEN_ID } from "config";
import { AnimatePresence } from "framer-motion";
import useTimeUntil from "hooks/useTimeUntil";
import { useAtom } from "jotai";
import React from "react";
import toast from "react-hot-toast";
import {
  afterBuyModalAtom,
  allTokensAtom,
  buyModalAtom,
  isLandAtom,
  isLkLandAtom,
  metaBalanceAtom,
  pairsAtom,
  referralCodeAtom,
  selectedTokenIdAtom,
  specificTotalAtom,
  userAtom,
} from "store/atoms";
import { handleRandomMint, handleWrapEGLD } from "utils/swapTransactions";
import useMintContract from "utils/useMintContract";
import { U64Value } from "@elrondnetwork/erdjs/out";
import indexes from "../../new_indexes.json";

const Actions = ({
  setTransactions,
  setSessionId,
  setLoading,
  tokenAmount,
  transactions,
  usdcAmount,
  wegldAmount,
  handleClose,
  selectedTokenBalance,
  loading,
  hasWrapped,
}: {
  setTransactions: any;
  setSessionId: any;
  setLoading: any;
  tokenAmount: number;
  transactions: any;
  usdcAmount: number;
  wegldAmount: number;
  handleClose: any;
  selectedTokenBalance: number;
  loading: boolean;
  hasWrapped: boolean;
}) => {
  const { address, account, shard, ...rest } = useGetAccountInfo();
  const { mintContract } = useMintContract(TOKEN_ID);

  const [afterBuyModal, setAfterBuyModal] = useAtom(afterBuyModalAtom);
  const [selectedTokenId] = useAtom(selectedTokenIdAtom);
  const [buyModal] = useAtom(buyModalAtom);
  const [user] = useAtom(userAtom);
  const [allTokens] = useAtom(allTokensAtom);
  const [pairs] = useAtom(pairsAtom);
  const [metaBalance] = useAtom(metaBalanceAtom);
  const [referralCode] = useAtom(referralCodeAtom);

  const [isLand] = useAtom(isLandAtom);
  const [isLkLand] = useAtom(isLkLandAtom);
  const [total] = useAtom(specificTotalAtom);

  const { tiles } = buyModal;

  const needsWrap = selectedTokenId == "EGLD" && !hasWrapped;

  const { timeLeft, hours, minutes, seconds } = useTimeUntil(MINT_TIME);

  const handleMint = async () => {
    let referralAddress = referralCode.length > 0 ? referralCode : undefined;

    if (referralAddress && !addressIsValid(referralAddress)) {
      const res = await fetch(
        `https://api.elrond.com/usernames/${referralCode.split(".")[0]}`
      );
      const data = await res.json();
      referralAddress = data.address;
    }

    if (referralCode && !referralAddress) {
      toast.error("The referral address is incorrect. Please try again.");
      return;
    }

    if (account.address === referralAddress) {
      toast.error("Nice try but you cannot refer yourself");
      return;
    }

    if (tiles) {
      setAfterBuyModal({
        tiles,
      });
      setLoading(true);
      if (needsWrap) {
        const sessionId = await handleWrapEGLD({
          mintContract,
          totalPrice: tokenAmount,
          tileCount: tiles.length,
          userShard: shard ?? 1,
        });
        setSessionId(sessionId);
      } else {
        handleRandomMint(
          tiles.length,
          referralCode,
          isLand || isLkLand ? total : Number(tokenAmount.toFixed(5)),
          allTokens,
          setSessionId,
          selectedTokenId,
          pairs,
          user,
          transactions,
          setLoading,
          isLand,
          isLkLand,
          tokenAmount,
          usdcAmount,
          wegldAmount,
          setTransactions,
          mintContract,
          false,
          [
            tiles.map(
              // @ts-ignore
              (item) => new U64Value(indexes.indexOf(item.quad_key, 0) + 1)
            ),
          ],
          shard ?? 1,
          metaBalance
        );
      }
    }
  };

  return (
    <div className="buy-place-card__actions">
      <Button
        containerClassname="flex-1"
        className="outline"
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button
        disabled={selectedTokenBalance < total}
        hideComingSoon
        containerClassname="flex-1"
        className="filled"
        onClick={handleMint}
      >
        {!loading && timeLeft == 0 && <Icon name="cart" />}
        <AnimatePresence>{loading && <Spinner />}</AnimatePresence>
        {/* {timeLeft > 0
						? hours + "H : " + minutes + "M : " + seconds + "S"
						: isConnected
						? "Buy"
						: loading
						? "Wait..."
						: "Connect"} */}
        {loading
          ? needsWrap
            ? "Wrapping"
            : "Minting"
          : needsWrap
          ? "Wrap"
          : "Buy"}
      </Button>
    </div>
  );
};

export default Actions;
