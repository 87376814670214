import { PRESALE_1_LKLAND, PRESALE_2_LKLAND, TOKEN_ID } from "config";
import { addressIsValid } from "@multiversx/sdk-dapp/utils";
import MintContract from "api";
import axios from "axios";
import { LKTOKEN_ID1, PRICES, WEGLD_ID } from "./../config";
import { notifyError } from "./notifications";
import { convertFromUSDC, getEquivalent } from "./swap";

export const handleRandomMint = async (
  tileCount: number,
  referralCode: string,
  totalPrice: number,
  allTokens: Array<any>,
  setSessionId: any,
  selectedTokenId: string,
  pairs: Array<any>,
  user: any,
  transactions: Array<any>,
  setLoading: any,
  isLand: boolean,
  isLkLand: boolean,
  tokenAmount: number,
  usdcAmount: number,
  wegldAmount: number,
  setTransactions: any,
  mintContract: MintContract | null,
  isRandom: boolean,
  indexes: any,
  userShard: number,
  metaBalance: any,
  tiles?: any
) => {
  setLoading(true);
  let referralAddress = referralCode.length > 0 ? referralCode : undefined;

  if (referralCode && !referralAddress) {
    notifyError("Wrong referral code!");
    setLoading(false);
    return;
  }

  if (referralAddress && !addressIsValid(referralAddress)) {
    const res = await fetch(
      `https://api.elrond.com/usernames/${referralCode.split(".")[0]}`
    );
    const data = await res.json();
    referralAddress = data.address;
  }

  if (user.address === referralAddress) {
    notifyError("Nice try but you cannot refer yourself");
    return;
  }
  const isEgld = selectedTokenId == "EGLD";
  const isMetaLkland =
    selectedTokenId == PRESALE_1_LKLAND || selectedTokenId == PRESALE_2_LKLAND;

  const wantedToken = !isEgld
    ? !isLkLand &&
      allTokens.filter((token: any) => token.value === selectedTokenId)[0]
    : allTokens.filter((token: any) => token.value === WEGLD_ID)[0];

  let wantedPair;
  let metaTokenWanted;

  if (!isLand) {
    wantedPair = pairs.filter(
      (pair: any) =>
        pair.firstToken.identifier === wantedToken.value &&
        pair.state === "Active"
    )[0];

    let metaBalanceWanted = metaBalance.filter(
      (a: any) =>
        `${a["identifier"].split("-")[0]}-${a["identifier"].split("-")[1]}` ==
        selectedTokenId
    );

    metaTokenWanted =
      metaBalanceWanted.length > 0 &&
      metaBalanceWanted.reduce((prev: any, current: any) =>
        Number(prev.balance) > Number(current.balance) ? prev : current
      );
  }

  const sessionId =
    isLand || isLkLand
      ? isRandom
        ? !isMetaLkland
          ? await mintContract?.createRandomMintTransaction(
              selectedTokenId,
              totalPrice,
              false,
              0,
              user.address,
              referralAddress
            )
          : await mintContract?.createRandomMintTransaction(
              selectedTokenId,
              totalPrice,
              true,
              metaTokenWanted.nonce,
              user.address,
              referralAddress
            )
        : !isMetaLkland
        ? await mintContract?.createSpecificMintTransaction(
            selectedTokenId,
            indexes,
            totalPrice,
            false,
            0,
            user.address,
            referralAddress
          )
        : await mintContract?.createSpecificMintTransaction(
            selectedTokenId,
            indexes,
            totalPrice,
            true,
            metaTokenWanted.nonce,
            user.address,
            referralAddress
          )
      : await mintContract?.createProxyRandomMintTransaction(
          selectedTokenId,
          533,
          wantedToken.decimals,
          totalPrice * tileCount,
          usdcAmount,
          wegldAmount,
          wantedPair["address"],
          1,
          selectedTokenId === "EGLD",
          selectedTokenId.split("-")[0] === "WEGLD",
          "",
          userShard,
          isRandom,
          indexes
        );

  if (sessionId) {
    setSessionId(sessionId);
    setTransactions([
      ...transactions,
      {
        sessionId,
        status: "pending",
        type: isRandom ? "random" : "specific",
        tiles: tiles && tiles,
      },
    ]);
  }
};

export const handleUpdateInputsReverse = (
  value: number,
  selectedTokenId: string,
  allTokens: Array<any>,
  pairs: Array<any>,
  landPair: object,
  setUsdcAmount: any,
  setWegldAmount: any,
  setTokenAmount: any,
  isRandom: boolean
) => {
  if (
    selectedTokenId.split("-")[0] !== "LAND" &&
    selectedTokenId.split("-")[0] !== "LKLAND"
  ) {
    const isEgld = selectedTokenId == "EGLD";
    const isUsdc = selectedTokenId.split("-")[0] == "USDC";

    const wantedToken = !isEgld
      ? allTokens.filter((token: any) => token.value === selectedTokenId)[0]
      : allTokens.filter((token: any) => token.value === WEGLD_ID)[0];

    const isWegld = wantedToken && wantedToken.label.split("-")[0] === "WEGLD";

    let equivalent = 0;

    if (isEgld) {
      equivalent = getEquivalent(landPair, value, true);
    } else {
      equivalent =
        selectedTokenId && selectedTokenId?.split("-")[0] == "USDC"
          ? getEquivalent(landPair, value, true)
          : getEquivalent(landPair, value, true);
    }

    setUsdcAmount(equivalent + 0.003 * equivalent);

    const { wegldReceived, tokenReceived } = convertFromUSDC(
      pairs,
      Number(equivalent + 0.003 * equivalent),
      //   100,
      !(selectedTokenId == "EGLD")
        ? wantedToken
        : allTokens.filter((token: any) => token.value === WEGLD_ID)[0]
    );

    setWegldAmount(Number(wegldReceived));
    setTokenAmount(
      !isWegld && !isEgld
        ? isUsdc
          ? equivalent
          : tokenReceived
        : Number(wegldReceived)
    );
  } else if (selectedTokenId.split("-")[0] === "LAND") {
    setTokenAmount(value);
  } else if (selectedTokenId.split("-")[0] === "LKLAND") {
    setTokenAmount(
      isRandom ? PRICES[LKTOKEN_ID1].random : PRICES[LKTOKEN_ID1].specific
    );
  }
};

export const handleWrapEGLD = async ({
  mintContract,
  totalPrice,
  tileCount,
  userShard,
}: any) => {
  const sessionId = await mintContract?.createWrapEGLDTransaction({
    userShard,
    swapFromAmount: totalPrice * tileCount,
  });

  return sessionId;
};

export const getPairs = async () => {
  const res = await axios.post("https://graph.maiar.exchange/graphql", {
    query:
      "\n      {\n        pairs(offset: 0, limit: 500) {\n          address\n          state\n          totalFeePercent\n          info {\n            reserves0\n            reserves1\n            totalSupply\n          }\n          firstToken {\n            identifier\n          }\n          secondToken {\n            identifier\n          }\n          liquidityPoolToken {\n            identifier\n            decimals\n          }\n        }\n      }\n    ",
  });

  return res;
};

export const handleTokenChange = (
  selectedTokenId: string,
  setIsLand: any,
  setIsLkLand: any
) => {
  if (selectedTokenId.split("-")[0] == TOKEN_ID.split("-")[0]) {
    setIsLand(true);
    setIsLkLand(false);
  } else if (selectedTokenId.split("-")[0] == "LKLAND") {
    setIsLand(false);
    setIsLkLand(true);
  } else {
    setIsLand(false);
    setIsLkLand(false);
  }
};

export const handleGetBalance = (
  setSelectedTokenBalance: any,
  selectedTokenId: string,
  account: object,
  balance: Array<any>
) => {
  const wantedTokenBalance = balance.filter(
    (b) => b["identifier"] === selectedTokenId
  )[0];

  if (selectedTokenId !== "EGLD") {
    balance.length > 0 &&
      wantedTokenBalance &&
      setSelectedTokenBalance(
        wantedTokenBalance["balance"] /
          10 ** Number(wantedTokenBalance["decimals"])
      );
  } else {
    // @ts-ignore
    setSelectedTokenBalance(Number(account.balance) / 10 ** 18);
  }
};

export const handleChangeTileCount = (e: any, setTileCount: any) => {
  console.log("e", e.target.value);
  // regex for only numbers

  const regex = /^[0-9]+$/;
  if (regex.test(e.target.value)) {
    const tileCount = Number(e.target.value);

    if (tileCount <= 5) {
      setTileCount(tileCount);
    }
  } else if (Number(e.target.value) > 5) {
    setTileCount(5);
  } else {
    setTileCount(0);
  }
};
