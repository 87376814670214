import { scaleFadeInVariants } from "animation/variants";
import confirmationJson from "assets/lottie/confirmation.json";
import errorLottieJson from "assets/lottie/error.json";
import laoderJson from "assets/lottie/loader.json";
import { Icon } from "components/icons/Icon";
import { motion } from "framer-motion";
import toast from "react-hot-toast";
import Lottie from "react-lottie-player";

export const notifyLoading = (message: string, hash?: string) =>
  toast.custom((t) => (
    <motion.div
      className="toast info"
      animate={t.visible ? "visible" : "hidden"}
      initial="hidden"
      variants={scaleFadeInVariants}
    >
      <Lottie
        className="toast-animation"
        animationData={laoderJson}
        style={{ width: 56, height: 56, top: 3, left: 12 }}
        loop
        play
      />
      <span style={{ marginLeft: "56px" }}>
        {message}
        {hash && (
          <p>
            Hash:{" "}
            <a
              target="_blank"
              href={`https://explorer.elrond.com/transactions/${hash}`}
            >
              {hash.slice(0, 5)}...{hash.slice(hash.length - 5, hash.length)}
            </a>
          </p>
        )}
      </span>

      <button className="toast-close" onClick={() => toast.dismiss(t.id)}>
        <Icon name="close" color="#ffffff" width={32} />
      </button>
    </motion.div>
  ));

export const notifySuccess = (message: string) =>
  toast.custom((t) => (
    <motion.div
      className="toast success"
      animate={t.visible ? "visible" : "hidden"}
      initial="hidden"
      variants={scaleFadeInVariants}
    >
      <div className="toast-header">
        <Lottie
          className="toast-animation"
          animationData={confirmationJson}
          style={{ width: 56, height: 56, top: 3, left: 12 }}
          loop={false}
          play
        />
        <span style={{ marginLeft: "56px" }}>{message}</span>
      </div>
      <button className="toast-close" onClick={() => toast.dismiss(t.id)}>
        <Icon name="close" color="#ffffff" width={32} />
      </button>
    </motion.div>
  ));

export const notifyError = (message: string) =>
  toast.custom((t) => (
    <motion.div
      className="toast error"
      animate={t.visible ? "visible" : "hidden"}
      initial="hidden"
      variants={scaleFadeInVariants}
    >
      <div className="toast-header">
        <Lottie
          className="toast-animation"
          animationData={errorLottieJson}
          style={{ width: 128, height: 128, top: -32, left: -20 }}
          loop={false}
          play
        />
        <span style={{ marginLeft: "64px" }}>{message}</span>
      </div>
      <button className="toast-close" onClick={() => toast.dismiss(t.id)}>
        <Icon name="close" color="#ffffff" width={32} />
      </button>
    </motion.div>
  ));
