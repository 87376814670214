import Nfts from "components/referral/Nfts";
import Referral from "components/referral/Referrals";

const NFTs = () => {
  return (
    <div className="container mt-10">
      <Referral />
      <Nfts />
    </div>
  );
};

export default NFTs;
