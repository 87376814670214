import { ApiNetworkProvider } from "@elrondnetwork/erdjs-network-providers/out";

export const ENVIRONMENT =
  process.env.NODE_ENV === "production" ? "mainnet" : "devnet";

export const TOKEN_ID =
  process.env.NODE_ENV === "production" ? "LAND-40f26f" : "WEB-5d08be";
export const LKTOKEN_ID1 = "LKLAND-6cf78e";
export const LKTOKEN_ID2 = "LKLAND-c617f7";
export const COLLECTION_ID =
  process.env.NODE_ENV === "production" ? "TILE-9d6c87" : "TILE-94683c";

export const PRICES = {
  [TOKEN_ID]: {
    specific: 800,
    random: 533,
  },
  [LKTOKEN_ID1]: {
    specific: 1080,
    random: 710,
  },
  [LKTOKEN_ID2]: {
    specific: 1080,
    random: 710,
  },
};

export const BADGE_NFT_ID = "BADGE-8f04cc";

export const walletConnectV2ProjectId = "04152d1c0f4571d292452022cffd28c2";

export const SHARD_0_WRAP =
  "erd1qqqqqqqqqqqqqpgqvc7gdl0p4s97guh498wgz75k8sav6sjfjlwqh679jy";
export const SHARD_1_WRAP =
  "erd1qqqqqqqqqqqqqpgqhe8t5jewej70zupmh44jurgn29psua5l2jps3ntjj3";
export const SHARD_2_WRAP =
  "erd1qqqqqqqqqqqqqpgqmuk0q2saj0mgutxm4teywre6dl8wqf58xamqdrukln";

export const multiPairSwap = "multiPairSwap";
export const swapTokensFixedInput = "swapTokensFixedInput";

export const USDC_ID = "USDC-c76f1f";

export const MINT_TIME = 1651510800000;

export const CONTRACT_ADDRESS =
  process.env.NODE_ENV === "production"
    ? "erd1qqqqqqqqqqqqqpgqaumlsgypn8qftqhanxyyxjj2pmfsfua9jpmqr7xh0z"
    : // ? "erd1qqqqqqqqqqqqqpgqz5rth288cy2njyetxeyeqqjv08g4ftn2jpmq2sr3jd"
      "erd1ttlrvatyh6nkdl5vyuc42ceg7p94md9zwzvf9gferngxzzkh5znstzvcc0";

export const OLD_CONTRACT_ADDRESS =
  "erd1qqqqqqqqqqqqqpgqz5rth288cy2njyetxeyeqqjv08g4ftn2jpmq2sr3jd";
export const FAUCET_ADDRESS =
  "erd1qqqqqqqqqqqqqpgqzv06r0dk9s9tlzx3zj6jcpaj0knfxg9r23qsa7dmmq";

export const XOXNO_ADDRESS =
  "erd1qqqqqqqqqqqqqpgq6wegs2xkypfpync8mn2sa5cmpqjlvrhwz5nqgepyg8";
export const XOXNO_API = "https://api.xoxno.com/";

export const BUCKET_NAME =
  process.env.REACT_APP_BUCKET_NAME ?? "romania-collection-test";
export const CHAIN_ID = process.env.NODE_ENV === "production" ? "1" : "D";
export const STORAGE_URL =
  "https://pqnmetwwxjifnlqcbmbj.supabase.co/storage/v1/object/public/" +
  BUCKET_NAME;
export const CONTRACT_NAME = "LandboardMint";
export const TIMEOUT = 240_000;

export const REWARDS_START_DATE = "2022-09-09T18:00:00.000Z";

export const tokenOptions: any = [
  {
    name: TOKEN_ID,
  },
  {
    name: LKTOKEN_ID1,
  },
  {
    name: LKTOKEN_ID2,
  },
];

export const huntAddresses = [
  "erd1mzrwyd3fk0ftnvp9g6jd346py64gnrs48h84x3jfe8ccr0mwhmgsucece2",
  "erd1ttlrvatyh6nkdl5vyuc42ceg7p94md9zwzvf9gferngxzzkh5znstzvcc0",
  "erd1dkvdjehfa5qshze7er2aax6d6wes70nd78gnhkt2lerscdavcxyq5hdvh4",
  "erd15mves799mz3hu42nq4qah9uqyp2vdunn7zcsqgjl0t88tglxwhlqyyhn7m",
  "erd1v4wpjgw6wqmsc5x8ljyady5egcah7ekyetzrchav9df8wm0rwngqmu466f",
  "erd1frgnyzp6qnzxrl6rv8n32uycp6u0etn6vtayrswsywrlwzv6rxnshscnhx",
];

export const PAIR_ADDRESS =
  process.env.NODE_ENV === "production"
    ? "erd1qqqqqqqqqqqqqpgqn530ruk9qkffaucewc3rnf2damhptz2r2jpszcf84t"
    : "erd1qqqqqqqqqqqqqpgq3lwucu7dx286sa9zps7ygpwknwmpav8c0n4sjulxqa";

export const WEGLD_ID =
  process.env.NODE_ENV === "production" ? "WEGLD-bd4d79" : "WEGLD-d7c6bb";

export const networkProvider = new ApiNetworkProvider(
  process.env.NODE_ENV === "production"
    ? "https://elrond-api.blastapi.io/c08cc31b-1e76-4fdd-8d0e-82d47b260406"
    : "https://elrond-api-devnet.blastapi.io/c08cc31b-1e76-4fdd-8d0e-82d47b260406"
);

export const liquidityAddress =
  process.env.NODE_ENV === "production"
    ? "erd1qqqqqqqqqqqqqpgqn530ruk9qkffaucewc3rnf2damhptz2r2jpszcf84t"
    : "erd1qqqqqqqqqqqqqpgqn530ruk9qkffaucewc3rnf2damhptz2r2jpszcf84t";

export const EGLDUSDCPairAddress =
  "erd1qqqqqqqqqqqqqpgqeel2kumf0r8ffyhth7pqdujjat9nx0862jpsg2pqaq";

export const MAIAR_ADDRESS =
  process.env.NODE_ENV === "production"
    ? "erd1qqqqqqqqqqqqqpgqq66xk9gfr4esuhem3jru86wg5hvp33a62jps2fy57p"
    : "erd1qqqqqqqqqqqqqpgqnz9ctnn9776xa7ua6u6s0tfz2zvn39ehjpmqr0kp9h";

// export const API_URL =
//   process.env.NODE_ENV === "production"
//     ? "https://elrond-api.blastapi.io/c08cc31b-1e76-4fdd-8d0e-82d47b260406"
//     : "https://elrond-api-devnet.blastapi.io/c08cc31b-1e76-4fdd-8d0e-82d47b260406";

export const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.elrond.com"
    : "https://devnet-api.elrond.com";

export const PRESALE_2_LKLAND = "LKLAND-27462c";
export const PRESALE_1_LKLAND = "LKLAND-516da7";
