import { fadeInVariants, motionContainerProps } from "animation/variants";
import { motion } from "framer-motion";
import { useTable } from "react-table";

interface TableProps {
  columns: any[];
  data?: any[];
  className?: string;
}

const Table = ({ columns, data = [], className = "" }: TableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <motion.table
      {...motionContainerProps}
      {...getTableProps()}
      className={className}
      variants={fadeInVariants}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                const Cell = cell.render("Cell");
                const { className, ...rest } = cell.getCellProps();

                return cell.column.Header !== "Event" ? (
                  <td className={`${className}`} {...rest}>
                    {Cell}
                  </td>
                ) : (
                  <td {...rest}>
                    <a
                      href={data[index].txLink}
                      target="_blank"
                      className={`${className} text-center flex justify-center`}
                    >
                      {Cell}
                    </a>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </motion.table>
  );
};

export default Table;
