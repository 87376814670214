import axios from "axios";
import { notifyError } from "utils/notifications";
import get from "lodash/get";

const client = axios.create({
  baseURL: "https://landboard-app-api.herokuapp.com/api",
  timeout: 10000,
  headers: {},
});

type ErrorType = {
  message: string;
  status: number;
};

export const request = async (
  endpoint: string,
  data: any,
  type: string = "POST"
) => {
  try {
    let response: any;
    switch (type) {
      case "POST":
        response = await client.post(endpoint, data);
        break;
      case "PUT":
        response = await client.put(endpoint, data);
        break;
      case "GET":
        response = await client.get(endpoint, data);
        break;
    }
    return response.data;
  } catch (error) {
    const err: ErrorType = get(error, "response.data", {
      message: "Unknown Error.",
      status: 500,
    });
    const unknownError = get(error, "error", "Unknown Error.");

    if (err.message) {
      notifyError(err.message);
    } else if (unknownError) {
      notifyError(unknownError);
    }
    return null;
  }
};
