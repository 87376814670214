import { createClient } from "@supabase/supabase-js";

const tablePrefix = process.env.REACT_APP_CHAIN
  ? `${process.env.REACT_APP_CHAIN}_`
  : "";

export const getPagination = (page: number, size: number) => {
  const limit = size ? +size : 3;
  const from = page ? page * limit : 0;
  const to = page ? from + size : size;

  return { from, to };
};

export const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL || "",
  process.env.REACT_APP_SUPABASE_ANON_KEY || ""
);

export const getUserData = async (address: string) => {
  let user: any;
  user = await (
    await supabase
      .from("users")
      .select()
      .or("address.eq." + address + ",herotag.eq." + address)
      .single()
  ).data;

  return user;
};

export const getNFTs = async (quad_keys: string[]) => {
  const { data, error } = await supabase.rpc(tablePrefix + "query_nfts", {
    quad_keys,
  });

  if (!(quad_keys.length > 1) && quad_keys.length !== 0) {
    const otherData = await supabase
      .from("nfts")
      .select()
      .or("quad_key.eq." + quad_keys)
      .single();

    //@ts-ignore
    data[0]["identifier"] = otherData?.data?.identifier;
    // @ts-ignore
  }

  // console.log("test, data", test.data, data);

  return data ? data : [];
};

interface Filters {
  sortBy: string;
  sortDirection: string;
  page: number;
  perPage: number;
  search: string;
  owner: string;
}

export const getNFTsByFilters = async (filters: Filters) => {
  const { from, to } = getPagination(filters.page - 1, filters.perPage);
  let query = supabase
    .from(tablePrefix + "nfts")
    .select("*, tiles:quad_key ( * ), users:owner_address ( * )", {
      count: "exact",
    })
    .ilike("name", "%" + filters.search + "%");

  if (filters.owner != "") {
    query = query.or(`owner_address.ilike.%${filters.owner}%`);
  }

  const { data, error } = await query
    .order(filters.sortBy, {
      ascending: filters.sortDirection === "asc",
      foreignTable: "tiles",
    })
    .range(from, to);

  const { count } = await await supabase
    .from(tablePrefix + "nfts")
    .select("*", { count: "exact", head: true })
    .ilike("name", "%" + filters.search + "%")
    .or(`owner_address.ilike.%${filters.owner}%`);

  return data ? { nfts: data, count } : [];
};

export const getUserNFTs = async (address: string) => {
  let nfts: any;
  nfts = await (
    await supabase
      .from(tablePrefix + "nfts")
      .select("*, tiles (*)")
      .eq("owner_address", address)
  ).data;

  return nfts;
};

export const getUserNFTsCount = async (address: string) => {
  let count: any;
  count = await (
    await supabase
      .from(tablePrefix + "nfts")
      .select("*", { count: "exact" })
      .eq("owner_address", address)
  ).data;

  return count;
};

export const getAllUsersNFTsCount = async () => {
  let usersAddress: any;

  usersAddress = await (
    await supabase
      .from(tablePrefix + "nfts")
      .select("owner_address, users (herotag)")
  ).data;

  const users: any = await (await supabase.from("users").select("*")).data;

  return usersAddress.map((item: any) => {
    const user = users.find((u: any) => u.address === item.owner_address);

    return {
      owner_address: user.herotag ?? user.address,
    };
  });
};

export const getUsersNFTsCount = async (addresses: string[]) => {
  let users: any;

  users = await (
    await supabase
      .from(tablePrefix + "nfts")
      .select("owner_address, users (herotag)")
      .in("owner_address", addresses)
  ).data;

  return users;
};

export const getNotifications = async (address: string) => {
  let notifications: any;
  notifications = await (
    await supabase
      .from("notifications")
      .select("*")
      .eq("user", address)
  ).data;

  return notifications;
};

export const getNFTViewsLeaderboard = async () => {
  let nfts: any = await (
    await supabase
      .from("tiles")
      .select("quad_key, location, rarity, view_count")
      .order("view_count", { ascending: false })
      .limit(100)
  ).data;

  const users: any = await (
    await supabase
      .from("nfts")
      .select("owner_address, quad_key, users:owner_address (*)")
      .in(
        "quad_key",
        nfts.map((nft: any) => nft.quad_key)
      )
  ).data;

  return nfts.map((nft: any) => {
    const user = users.find((u: any) => u.quad_key === nft.quad_key)?.users;
    return {
      ...nft,
      owner: user.herotag ?? user.address,
    };
  });
};

export const getNFTCollectionsLeaderboard = async () => {
  let nfts: any = await (
    await supabase
      .from(tablePrefix + "nfts")
      .select("quad_key, image_nft_id, owner_address")
      .not("image_nft_id", "is", null)
  ).data;

  return nfts;
};
