import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import axios from "axios";
import Info from "components/buy-place/Info";
import { Icon } from "components/icons/Icon";
import { API_URL, BADGE_NFT_ID } from "config";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { badgesOwnedAtom } from "store/atoms";
import dayjs from "dayjs";
import useTimeUntil from "hooks/useTimeUntil";

const RewardsCard = ({
  total,
  totalNFTs,
  allTimeRewards,
}: {
  total: number;
  totalNFTs: number;
  allTimeRewards: number;
}) => {
  const { address } = useGetAccountInfo();
  const [isExtended, setIsExtended] = useState(false);
  const [badges, setBadges] = useAtom(badgesOwnedAtom);
  const [uniqueNFTs, setUniqueNFTs] = useState([]);

  const airdropDates = [
    "2022-11-04T00:00:00.000Z",
    "2022-11-18T16:00:00.000Z",
    "2022-12-01T16:00:00.000Z",
  ];

  const { timeLeft, days, hours, minutes, seconds } = useTimeUntil(
    dayjs(airdropDates[2]).unix() * 1000
  );

  const ItemsWrapper = ({ children, extended }: any) => {
    return (
      <div
        className={`flex flex-col justify-center w-full gap-5 md:gap-0 md:justify-between md:flex-row ${extended &&
          "mt-8"}`}
      >
        {children}
      </div>
    );
  };

  const calculateRewardsWithBoost = () => {
    // get user's Badge NFTs
    axios
      .get(`${API_URL}/accounts/${address}/nfts?search=${BADGE_NFT_ID}`)
      .then((res) => {
        // filter the nfts with the unique url
        const uniqueNFTs = res.data.filter(
          (nft: any, index: number, self: any) =>
            index ===
            self.findIndex((t: any) => t.url === nft.url && t.url !== null)
        );

        setUniqueNFTs(uniqueNFTs);
      });
  };

  useEffect(() => {
    calculateRewardsWithBoost();
  }, []);

  const turnToDoubleDigit = (num: number) => {
    return num < 10 ? `0${num}` : num;
  };

  return (
    <div className="flex flex-col items-center w-full px-8 pt-8 pb-3 rounded-xl bg-purple-light">
      {/* <p className="mb-5 text-3xl text-center">Statistics</p> */}
      <div className="w-full">
        <ItemsWrapper>
          <span className="w-2/5 mx-2">
            <p className="text-[1rem] text-gray-light">Tiles Owned</p>{" "}
            <p>{totalNFTs}</p>
          </span>
          <span className="w-2/5 mx-2">
            <p className="text-[1rem] text-gray-light">Next Airdrop</p>
            <p>{`${turnToDoubleDigit(days)}:${turnToDoubleDigit(
              hours
            )}:${turnToDoubleDigit(minutes)}:${turnToDoubleDigit(seconds)}`}</p>
          </span>
          <span className="w-2/5 mx-2">
            <p className="text-[1rem] text-gray-light">Expected rewards</p>{" "}
            <p>
              {Number(
                total +
                  (((uniqueNFTs.length > 5 ? 5 : uniqueNFTs.length) * 2.5) /
                    100) *
                    total
              ).toFixed(3)}{" "}
              LAND
            </p>
          </span>
        </ItemsWrapper>
        {/* </div> */}
        {isExtended && (
          <ItemsWrapper extended className="mt-10">
            <span className="w-2/5 mx-2">
              <p className="text-[1rem] text-gray-light">Total Earned</p>{" "}
              <p>{Number(allTimeRewards).toFixed(3)} LAND</p>
            </span>
            <span className="w-2/5 mx-2">
              <p className="text-[1rem] text-gray-light">Badges owned</p>
              <p>{badges.length}</p>
            </span>
            <span className="w-2/5 mx-2">
              <p className="text-[1rem] text-gray-light">
                Rewards without boost
              </p>{" "}
              <p>{Number(total).toFixed(3)} LAND</p>
            </span>
          </ItemsWrapper>
        )}
      </div>
      <div
        className="mt-2 cursor-pointer"
        onClick={() => {
          setIsExtended((prevState) => !prevState);
        }}
      >
        {!isExtended ? (
          <Icon width={45} color="#fff" name="arrow-down" />
        ) : (
          <Icon width={45} color="#fff" name="arrow-up" />
        )}
      </div>
    </div>
  );
};

export default RewardsCard;
