import * as DappUI from "@multiversx/sdk-dapp/UI";
import { DappProvider } from "@multiversx/sdk-dapp/wrappers";
import axios from "axios";
import AddressButton from "components/buttons/AddressButton";
import AfterBuyCard from "components/cards/mint/AfterBuyCard";
import BuyPlaceCard from "components/buy-place/BuyPlaceCard";
import ListPlaceCard from "components/cards/tiles/ListPlaceCard";
import NotificationsCard from "components/cards/NotificationsCard";
import OfferPlaceCard from "components/cards/tiles/OfferPlaceCard";
import RandomMintCard from "components/cards/mint/RandomMintCard";
import ShareTileCard from "components/cards/tiles/ShareTileCard";
import HandLoader from "components/loading/Hand";
import Modal from "components/modals";
import Sidebar from "components/sidebar";
import TransactionListener from "components/transactions";
import { AnimatePresence } from "framer-motion";
import { useAtom } from "jotai";
import "mapbox-gl/dist/mapbox-gl.css";
import NotFoundPage from "pages/404";
import Error from "pages/Error";
import Home from "pages/Home";
import { Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Toaster } from "react-hot-toast";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useMedia } from "react-use";
import routes, { routeNames } from "routes";
import {
  afterBuyModalAtom,
  allTokensAtom,
  buyModalAtom,
  loadingAtom,
  offerModalAtom,
  randomMintModalAtom,
  shareTileModalAtom,
  listModalAtom,
  showNotificationModalAtom,
  transactionsAtom,
  shareReferralModalAtom,
} from "store/atoms";
import {
  ENVIRONMENT,
  networkProvider,
  walletConnectV2ProjectId,
} from "./config";
import Unlock from "components/unlock/Unlock";
import ShareReferralCard from "components/cards/ShareReferral";
import { decodeBase64 } from "@multiversx/sdk-dapp/utils";

const environment = ENVIRONMENT;

const {
  SignTransactionsModals,
  NotificationModal,
  DappCorePages: { UnlockPage },
} = DappUI;

const App = () => {
  const [buyModal, setBuyModal] = useAtom(buyModalAtom);
  const [offerModal, setOfferModal] = useAtom(offerModalAtom);
  const [randomMintModal, setRandomMintModal] = useAtom(randomMintModalAtom);
  const [shareTileModal] = useAtom(shareTileModalAtom);
  const [shareReferralModal] = useAtom(shareReferralModalAtom);
  const [afterBuyModal] = useAtom(afterBuyModalAtom);
  const [snowNotificationModal, setShowNotificationModal] = useAtom(
    showNotificationModalAtom
  );
  const [listModal, setListModal] = useAtom(listModalAtom);

  const [allTokens, setAllTokens] = useAtom(allTokensAtom);
  const [transactions, setTransactions] = useAtom(transactionsAtom);
  const [loading] = useAtom(loadingAtom);

  const isPhone = useMedia("(max-width: 767px)");

  const testDecode = () => {
    const decoded = decodeBase64(
      "RVNEVE5GVFRyYW5zZmVyQDQ1NDc0YzQ0NTI0OTQ0NDU0NjRjMmQzNzM0NjIzODMxMzlAMGE5ZmJiQDZiOTBmNTA3NDM1NGEwMzlAMDAwMDAwMDAwMDAwMDAwMDA1MDA4MmI3YjYwODNjZDM4NDc4OGEyYzVkZmIyZmQ2NzgwYjg1YWY2MTdjNTQ4M0A2MzZjNjE2OTZkNTI2NTc3NjE3MjY0NzM="
    );

    const decodedSplit = decoded.split("@");

    // map through decodedSplit and check if it's a hexadecimal encoded number or string

    decodedSplit.map((item) => {
      // check whether it's a hexadecimal encoded number or hexadecimal encoded string
      if (item.match(/^[0-9a-fA-F]+$/)) {
        // check if it's a hexadecimal encoded number
        if (!isNaN(parseInt(item, 16))) {
          console.log(item, "number");
        } else {
          console.log(item, "string");
        }
      }

      return null;
    });

    console.log("decoded", decoded);
  };

  testDecode();

  useEffect(() => {
    axios.get(`${networkProvider["url"]}/mex/tokens`).then(async (res: any) => {
      const tokens = res.data.map((token: any) => {
        return { value: token.id, label: token.name };
      });

      const { data: tokenInfos } = await axios.get(
        `${networkProvider["url"]}/tokens?identifiers=${tokens
          .map((token: any) => token.value)
          .join(",")}`
      );

      setAllTokens(
        tokens.map((token: any) => {
          const wantedToken = tokenInfos.find(
            (tokenInfo: any) => tokenInfo.identifier === token.value
          );

          return {
            ...token,
            label: token.value.split("-")[0],
            price: wantedToken?.price,
            decimals: wantedToken?.decimals,
            url: wantedToken?.assets?.pngUrl,
          };
        })
      );
    });
  }, []);

  const handleClose = () => {
    setBuyModal({ ...buyModal, isOpen: false });
    setOfferModal({ ...offerModal, isOpen: false });
    setRandomMintModal({ ...randomMintModal, isOpen: false });
    setListModal({ ...listModal, isOpen: false });
  };

  const handleRemoveTransaction = (transactionId: string) => {
    setTransactions(
      transactions.filter(
        (transaction) => transaction.sessionId !== transactionId
      )
    );
  };

  return (
    <Router>
      <DappProvider
        environment={environment}
        customNetworkConfig={{
          name: "customConfig",
          apiTimeout: 6000,
          walletConnectV2ProjectId: walletConnectV2ProjectId,
        }}
        // completedTransactionsDelay={200}
      >
        <SkeletonTheme baseColor="#14073B" highlightColor="#372277">
          {/* <NotFound>
						<h1>The website is under maintenance.</h1>
						<p>Get ready for the official launch on May 1st.</p>
						<Button className="filled">
							<a href="https://landboard.io/">landboard.io</a>
						</Button>
					</NotFound> */}
          <Sidebar />
          <AddressButton />
          {transactions.map((transaction) => (
            <TransactionListener
              key={transaction.sessionId}
              handleClose={handleClose}
              transaction={transaction}
              removeTransaction={() =>
                handleRemoveTransaction(transaction.sessionId)
              }
            />
          ))}
          {/* @ts-ignore */}
          <ErrorBoundary
            FallbackComponent={Error}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}
          >
            <Suspense fallback="Loading...">
              <AnimatePresence>
                {loading && (
                  <Modal onClose={handleClose}>
                    <HandLoader />
                  </Modal>
                )}
                {buyModal.isOpen && (
                  <Modal onClose={handleClose}>
                    <BuyPlaceCard />
                  </Modal>
                )}
                {offerModal.isOpen && (
                  <Modal onClose={handleClose}>
                    <OfferPlaceCard />
                  </Modal>
                )}
                {listModal.isOpen && (
                  <Modal onClose={handleClose}>
                    <ListPlaceCard />
                  </Modal>
                )}
                {randomMintModal.isOpen && isPhone && (
                  <Modal onClose={handleClose}>
                    <RandomMintCard />
                  </Modal>
                )}
                {shareTileModal.isOpen && (
                  <Modal onClose={handleClose}>
                    <ShareTileCard />
                  </Modal>
                )}
                {shareReferralModal.isOpen && (
                  <Modal onClose={handleClose}>
                    <ShareReferralCard />
                  </Modal>
                )}
                {afterBuyModal.isOpen && (
                  <Modal onClose={handleClose}>
                    <AfterBuyCard />
                  </Modal>
                )}
                {snowNotificationModal && (
                  <NotificationsCard
                    handleCloseNotification={() =>
                      setShowNotificationModal(false)
                    }
                  />
                )}
              </AnimatePresence>
              <main>
                <SignTransactionsModals className="modal-card" />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path={routeNames.unlock} element={<Unlock />} />
                  {routes.map((route: any, index: number) => (
                    <Route
                      path={route.path}
                      key={"route-key-" + index}
                      element={<route.component />}
                    />
                  ))}
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
                <Toaster
                  toastOptions={{
                    className: "z-50",
                    position: "bottom-right",
                  }}
                />
              </main>
            </Suspense>
          </ErrorBoundary>
        </SkeletonTheme>
      </DappProvider>
    </Router>
  );
};

export default App;
