// @ts-nocheck
import ReactPaginate from "react-paginate";

interface PaginationProps {
	page: number;
	setPage: (page: number) => void;
	pageCount: number;
	perPage: number;
}

const Pagination = ({ page, pageCount, perPage, setPage }: PaginationProps) => {
	const handlePageClick = (event: any) => {
		setPage(event.selected + 1);
	};

	return (
		<div className="pagination">
			<ReactPaginate
				breakLabel="..."
				nextLabel=">"
				onPageChange={handlePageClick}
				marginPagesDisplayed={1}
				pageRangeDisplayed={2}
				pageCount={pageCount}
				previousLabel="<"
				renderOnZeroPageCount={null}
				activeClassName="active"
			/>
		</div>
	);
};

export default Pagination;
