import { atom } from "jotai";
import {
  LKTOKEN_ID1,
  LKTOKEN_ID2,
  PRESALE_1_LKLAND,
  PRESALE_2_LKLAND,
  TOKEN_ID,
} from "config";
import { Badge, Tile } from "types/Tiles";
import { Token } from "utils/swap";
import logo from "../assets/images/EGLD.png";

export const mapStyles = [
  {
    label: "Chaos",
    value: "mapbox://styles/razvanel39438/cl0io0gzd003u15p1n3z0wct4",
  },
  {
    label: "Classic",
    value: "mapbox://styles/mapbox/satellite-streets-v11",
  },
];

export const mapStyleAtom = atom(mapStyles[0]);

type BuyModal = {
  isOpen: boolean;
  landPrice: string;
  egldPrice: string;
  address: string;
  tiles: Tile[];
};

export const buyModalAtom = atom<Partial<BuyModal>>({
  tiles: [],
  isOpen: false,
  landPrice: "TBD",
  egldPrice: "TBD",
  address: "Unknown",
});

export const showNotificationModalAtom = atom(false);

export type NotificationType = {
  id: number;
  status: string;
  user: string;
  message: string;
  viewed: boolean;
  created_at: string;
  metadata: any;
};
export const notificationsAtom = atom<NotificationType[]>([]);

export const listModalAtom = atom({
  isOpen: false,
  name: "SnoopDog’s House",
  owner: "Snoop Dog",
  image:
    "https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  lastPrice: "100",
  tileCount: 10,
});

export const offerModalAtom = atom({
  isOpen: false,
  name: "SnoopDog’s House",
  owner: "Snoop Dog",
  image:
    "https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  lastPrice: "100",
  tileCount: 10,
});

export const isProfileOwnerAtom = atom(false);

export const allTokensAtom = atom([
  { decimals: 0, label: "", price: 0, url: "", value: "" },
]);

export const balanceAtom = atom([]);
export const metaBalanceAtom = atom<any[]>([]);
export const biggestMetaBalanceAtom = atom<any[]>([]);
export const metaBalanceWantedAtom = atom<any[]>([]);
export const favoriteTokensAtom = atom([
  {
    decimals: 18,
    label: "LAND",
    price: 0.018905,
    url: "https://media.elrond.com/tokens/asset/LAND-40f26f/logo.png",
    value: "LAND-40f26f",
  },
  {
    decimals: 18,
    label: "LKLAND",
    price: 0.018905,
    url: "https://media.elrond.com/tokens/asset/LAND-40f26f/logo.png",
    value: "LKLAND-6cf78e",
  },
  {
    decimals: 18,
    label: "LKLAND",
    price: 0.018905,
    url: "https://media.elrond.com/tokens/asset/LAND-40f26f/logo.png",
    value: "LKLAND-c617f7",
  },
  {
    decimals: 18,
    label: "LKLAND (Presale 1)",
    price: 0.018905,
    url: "https://media.elrond.com/tokens/asset/LAND-40f26f/logo.png",
    value: PRESALE_1_LKLAND,
  },
  {
    decimals: 18,
    label: "LKLAND (Presale 2)",
    price: 0.018905,
    url: "https://media.elrond.com/tokens/asset/LAND-40f26f/logo.png",
    value: PRESALE_2_LKLAND,
  },
  {
    decimals: 18,
    label: "WEGLD",
    price: 49.55366,
    url: "https://media.elrond.com/tokens/asset/WEGLD-bd4d79/logo.png",
    value: "WEGLD-bd4d79",
  },
  {
    decimals: 18,
    label: "EGLD",
    price: 49.55366,
    url: logo,
    value: "EGLD",
  },
]);

export const blacklistedTokensAtom = atom(["HODL", "LAUNCH", "DEAD", "KRO"]);

export const selectedTokenIdAtom = atom(TOKEN_ID);

export const isLandAtom = atom(false);
export const isLkLandAtom = atom(false);
export const landPairAtom = atom({});
export const tokenAmountAtom = atom(0);

export const isLoadingAtom = atom(false);
export const usdcAmountAtom = atom(0);
export const wegldAmountAtom = atom(0);

export const xoxnoDataAtom = atom<any[]>([]);

export const randomMintModalAtom = atom({
  isOpen: false,
});

export const pairsAtom = atom([]);

export const shareTileModalAtom = atom({
  isOpen: false,
});

export const shareReferralModalAtom = atom({
  isOpen: false,
});

export const loadingAtom = atom(false);

export const refreshCountAtom = atom(0);

export type WaitingTransaction = {
  sessionId: string;
  status: string;
  type: "random" | "specific" | "buy-land";
  tiles?: Tile[];
};
export const transactionsAtom = atom<WaitingTransaction[]>([]);

export const landBalanceAtom = atom({
  [TOKEN_ID]: 0,
  [LKTOKEN_ID1]: 0,
  [LKTOKEN_ID2]: 0,
});

type UserType = {
  username?: "";
  avatar?: string;
  herotag?: string;
  address?: string;
};

export const afterBuyModalAtom = atom<{
  isOpen?: boolean;
  tiles: Tile[];
}>({
  isOpen: false,
  tiles: [],
});

export const userAtom = atom<UserType>({});

export const userProfileAtom = atom({
  username: "",
  herotag: "",
  address: "",
  avatar: "",
  totalLand: "0 LAND",
  tileCount: 0,
  tiles: [],
});

export const startLocationAtom = atom<any[]>([]);

export const opacityAtom = atom(0.8);

export const selectedTokenBalanceAtom = atom(0);
export const referralCodeAtom = atom("");

export const specificTotalAtom = atom(0);

export const badgesOwnedAtom = atom<Badge[]>([]);

export const totalRewardsAtom = atom<any>(0);

export const isPromoActiveAtom = atom(false);
