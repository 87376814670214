export const hints = [
  {
    stage: 1,
    hint: "EGLD city - 40N - 50E",
  },
  {
    stage: 2,
    hint: "🦢🌱🍃🌊",
    words: "tape, volume, cube, topple",
  },
  {
    stage: 3,
    hint:
      "For this stage, we hid the hint on our landing page. Can you find it?",
    words: "next, they, install, note",
  },
  {
    stage: 4,
    hint: "Sig.. 🏰🏙, 20 E or W",
    words: "seven, daring, renew, kind",
  },
  {
    stage: 5,
    hint: "BG 🌉, SOUTH",
    words: "real, keep, ticket, food",
  },
  {
    stage: 6,
    hint:
      "NGU0NDU5NzU0ZTZhNjczNDRlNmE2YjM1NGM0MzQxNzk0ZTQzMzQ3OTRlN2E1NTM1NGY0NDRkM2Q=. Yes, that's the hint. Good luck on the last one! 😉",
    words: "spatial, suspect, middle, ticket",
  },
  {
    stage: 7,
    hint: "No next tile. You won!",
    words: "exhaust, scrub,trip, inform",
  },
];
