import { ReactComponent as ArrowDown } from "./arrow-down.svg";
import { ReactComponent as ArrowUp } from "./arrow-up.svg";
import { ReactComponent as Calendar } from "./calendar.svg";
import { ReactComponent as Cart } from "./cart.svg";
import { ReactComponent as Check } from "./check.svg";
import { ReactComponent as Chrome } from "./chrome.svg";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as Coins } from "./coins.svg";
import { ReactComponent as Copy } from "./copy.svg";
import { ReactComponent as Dice3 } from "./dice-3.svg";
import { ReactComponent as Discord } from "./discord.svg";
import { ReactComponent as Discount } from "./discount.svg";
import { ReactComponent as Expand } from "./expand.svg";
import { ReactComponent as Eye } from "./eye.svg";
import { ReactComponent as Faucet } from "./faucet.svg";
import { ReactComponent as Gift } from "./gift.svg";
import { ReactComponent as History } from "./history.svg";
import { ReactComponent as Info } from "./info.svg";
import { ReactComponent as Info2 } from "./info2.svg";
import { ReactComponent as Land } from "./land.svg";
import { ReactComponent as Leaderboard } from "./leaderboard.svg";
import { ReactComponent as Lock } from "./lock.svg";
import { ReactComponent as MapLocation } from "./map-location.svg";
import { ReactComponent as MapPin } from "./map-pin.svg";
import { ReactComponent as Map } from "./map.svg";
import { ReactComponent as Marketplace } from "./marketplace.svg";
import { ReactComponent as Money } from "./money.svg";
import { ReactComponent as Notification } from "./notification.svg";
import { ReactComponent as Palette } from "./palette.svg";
import { ReactComponent as Refresh } from "./refresh.svg";
import { ReactComponent as Rocket } from "./rocket.svg";
import { ReactComponent as Save } from "./save.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as Share } from "./share.svg";
import { ReactComponent as Star } from "./star.svg";
import { ReactComponent as Tag1 } from "./tag1.svg";
import { ReactComponent as Tag2 } from "./tag2.svg";
import { ReactComponent as Telegram } from "./telegram.svg";
import { ReactComponent as Tile } from "./tile.svg";
import { ReactComponent as Twitter } from "./twitter.svg";
import { ReactComponent as Unlock } from "./unlock.svg";
import { ReactComponent as UserLocation } from "./user-location.svg";
import { ReactComponent as User } from "./user.svg";
import { ReactComponent as Wallet } from "./wallet.svg";
import { ReactComponent as Market } from "./market.svg";
import { ReactComponent as Transfer } from "./transfer.svg";
import { ReactComponent as LinkExternal } from "./link-external.svg";

export default {
  "arrow-down": ArrowDown,
  "arrow-up": ArrowUp,
  calendar: Calendar,
  cart: Cart,
  check: Check,
  chrome: Chrome,
  close: Close,
  coins: Coins,
  copy: Copy,
  "dice-3": Dice3,
  discord: Discord,
  discount: Discount,
  expand: Expand,
  eye: Eye,
  faucet: Faucet,
  gift: Gift,
  history: History,
  info: Info,
  info2: Info2,
  land: Land,
  leaderboard: Leaderboard,
  lock: Lock,
  "map-location": MapLocation,
  "map-pin": MapPin,
  map: Map,
  marketplace: Marketplace,
  money: Money,
  notification: Notification,
  palette: Palette,
  refresh: Refresh,
  rocket: Rocket,
  save: Save,
  search: Search,
  share: Share,
  star: Star,
  tag1: Tag1,
  tag2: Tag2,
  telegram: Telegram,
  tile: Tile,
  twitter: Twitter,
  unlock: Unlock,
  "user-location": UserLocation,
  user: User,
  wallet: Wallet,
  market: Market,
  transfer: Transfer,
  linkExternal: LinkExternal,
};
