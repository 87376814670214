import { supabase } from "./supabase-client";

export const subscribeToNotifications = (
  user_address: string,
  onNotification: any
) => {
  return supabase
    .from("notifications:user=eq." + user_address)
    .on("INSERT", (res) => onNotification(res.new))
    .on("UPDATE", (res) => {
      onNotification(res.new);
    })
    .subscribe();
};
