import {
  useGetAccountInfo,
  useGetNetworkConfig,
} from "@multiversx/sdk-dapp/hooks";
import {
  ApiNetworkProvider,
  ProxyNetworkProvider,
} from "@elrondnetwork/erdjs-network-providers";
import { Address, SmartContract } from "@elrondnetwork/erdjs/out";
import MintContract from "api";
import { useEffect, useState } from "react";
import { CONTRACT_ADDRESS, TIMEOUT } from "../config";
``;

const useMintContract = (tokenId: string) => {
  const [mintContract, setMintContract] = useState<MintContract | null>(null);
  const { account } = useGetAccountInfo();
  const { network } = useGetNetworkConfig();

  const loadContract = async () => {
    if (account.address) {
      const contract = new SmartContract({
        address: new Address(CONTRACT_ADDRESS),
      });
      const provider = new ApiNetworkProvider(network.apiAddress, {
        timeout: TIMEOUT,
      });
      provider
        .getNetworkConfig()
        .then(() =>
          setMintContract(new MintContract(account, contract, provider))
        );
    }
  };

  useEffect(() => {
    loadContract();
  }, [network, account, tokenId]);

  return {
    mintContract,
  };
};

export default useMintContract;
