import Button from "components/buttons";
import { Icon } from "components/icons/Icon";
import differenceInDays from "date-fns/differenceInDays";
import addDays from "date-fns/addDays";
import compareAsc from "date-fns/compareAsc";
import useTimeUntil from "hooks/useTimeUntil";
import { useAtom } from "jotai";
import { Link, useNavigate } from "react-router-dom";
import { shareTileModalAtom, startLocationAtom } from "store/atoms";
import { NFT } from "types/components";
import { REWARDS_START_DATE } from "config";

interface NFTCardProps extends NFT {
  img: string;
  tiles: any;
}

const isRewardsLaunched =
  compareAsc(new Date(), new Date(REWARDS_START_DATE)) >= 0;

const NFTCard = ({
  quad_key,
  center,
  img,
  name,
  rank,
  owner,
  apr,
  stake_timestamp,
  bonus_apr,
  reward,
  final_reward,
  link,
  tiles,
}: NFTCardProps) => {
  const [, setShareTileModal] = useAtom(shareTileModalAtom);
  const [, setStartLocation] = useAtom(startLocationAtom);

  const { days, hours, minutes, seconds } = useTimeUntil(
    isRewardsLaunched
      ? addDays(
          stake_timestamp,
          differenceInDays(new Date(), stake_timestamp)
        ).getTime()
      : new Date(REWARDS_START_DATE).getTime()
  );

  const navigate = useNavigate();

  const handleView = () => {
    navigate(`/nfts/${quad_key}`);
  };

  const handleGoToMap = () => {
    if (center) {
      setStartLocation(JSON.parse(center));
      navigate("/");
    }
  };

  const handleShowShareModal = () => setShareTileModal({ isOpen: true });

  return (
    <div className="nft-card">
      <div className="nft-card__header">
        <img src={img} alt={name} />
        <div className="nft-card__header-text">
          <p>
            Rank <span className="text-purple">#{rank}</span>
          </p>
          <h2>{name}</h2>
          {/* <p>
            Owner:{" "}
            <Link to={`/users/${owner}`} className="text-purple">
              {owner}
            </Link>
          </p> */}
        </div>
      </div>
      <ul className="nft-card__content">
        {/* <li>
          <Icon name="info" /> Accumulated rewards: {reward} LAND
        </li> */}
        <li>
          <Icon name="map-pin" />
          <span>{tiles.location}</span>
        </li>
        <li>
          <Icon name="gift" /> Aprox. rewards: {final_reward} LAND
        </li>
        <li>
          <Icon name="discount" /> APR: {apr}% (30 - 60)
        </li>
        {/* <li>
          <Icon name="calendar" />{" "}
          {!isRewardsLaunched ? "Airdrop" : "Rewards start"} in {days}D {hours}H{" "}
          {minutes}M {seconds}S
        </li> */}
      </ul>
      <div className="nft-card__actions">
        <Button className="flex-1 filled" onClick={handleView}>
          <Icon name="info" />
          View
        </Button>
        <div className="nft-card__actions-extra">
          {link && (
            <a className="lnk" href={link}>
              <Icon name="chrome" primary />
            </a>
          )}
          <button onClick={handleGoToMap}>
            <Icon name="map-location" primary />
          </button>
          <button onClick={handleShowShareModal}>
            <Icon name="share" primary />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NFTCard;
