import { fadeInVariants, motionContainerProps } from "animation/variants";
import Button from "components/buttons";
import Dracula from "components/icons/Dracula";
import { Icon } from "components/icons/Icon";
import { motion } from "framer-motion";
import { FallbackProps } from "react-error-boundary";

const Error = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <motion.div className="page-404" {...motionContainerProps}>
      <Dracula />
      <div className="page-404__content">
        <motion.h1 variants={fadeInVariants}>
          Oh no. <br />
          You got an <span className="text-purple">error</span>.
        </motion.h1>
        <motion.p variants={fadeInVariants}>
          Maybe Dracula has broken this page. Go back to the map.{" "}
        </motion.p>
        <div className="page-404__actions">
          <Button
            className="w-[13.75rem] mt-6 filled"
            link="/"
            onClick={resetErrorBoundary}
            animate
          >
            <Icon name="map" />
            Go To Map
          </Button>
          <Button
            className="w-[13.75rem] mt-6 text"
            onClick={resetErrorBoundary}
            animate
          >
            Refresh Page
          </Button>
        </div>
      </div>
    </motion.div>
  );
};

export default Error;
