import { Fragment } from "react";
import { Link } from "react-router-dom";

const ButtonContent = ({
  disabled,
  link,
  className = "filled",
  type,
  children,
  external,
  hideComingSoon,
  newTab,
  ...rest
}: ButtonProps) => {
  if (external) {
    return (
      <a
        href={external}
        target={newTab ? "_blank" : ""}
        className={`btn ${className}`}
      >
        {children}
      </a>
    );
  }

  if (link) {
    return (
      <Link to={link} className={`btn ${className}`} onClick={rest.onClick}>
        {children}
      </Link>
    );
  }

  return (
    <Fragment>
      <button
        className={`btn ${className}`}
        type={type}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
      {disabled && !hideComingSoon && (
        <div className="absolute p-2 text-xs rounded -right-2 z-100 bg-purple md:-right-6 -top-5">
          Coming Soon
        </div>
      )}
    </Fragment>
  );
};

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  containerClassname?: string;
  link?: string;
  external?: string;
  animate?: boolean;
  hideComingSoon?: boolean;
  onClick?: any;
  newTab?: boolean;
}

const Button = (props: ButtonProps) => {
  const { animate, containerClassname = "", children, ...rest } = props;
  return (
    <div className={`relative ${containerClassname}`}>
      <ButtonContent {...rest}>{children}</ButtonContent>
    </div>
  );
};

export default Button;
