import { sendTransactions } from "@multiversx/sdk-dapp/services";
import { refreshAccount } from "@multiversx/sdk-dapp/utils";
import { ApiNetworkProvider } from "@elrondnetwork/erdjs-network-providers/out";
import {
  Account,
  Address,
  AddressValue,
  BigUIntValue,
  BytesValue,
  ContractFunction,
  List,
  ListType,
  ResultsParser,
  SmartContract,
  TokenPayment,
  Transaction,
  TransactionPayload,
  U64Type,
} from "@elrondnetwork/erdjs";
import {
  CHAIN_ID,
  CONTRACT_ADDRESS,
  EGLDUSDCPairAddress,
  FAUCET_ADDRESS,
  liquidityAddress,
  MAIAR_ADDRESS,
  multiPairSwap,
  SHARD_0_WRAP,
  SHARD_1_WRAP,
  SHARD_2_WRAP,
  swapTokensFixedInput,
  TOKEN_ID,
  USDC_ID,
  WEGLD_ID,
} from "config";

export default class MintContract {
  contract: SmartContract;
  userAddress: Address;
  userAccount: Account;
  provider: ApiNetworkProvider;

  constructor(
    account: any,
    contract: SmartContract,
    provider: ApiNetworkProvider
  ) {
    this.userAddress = new Address(account.address);
    this.userAccount = account;
    this.contract = contract;
    this.provider = provider;
  }

  createEgldRandomMintTrasaction = async () => {};

  createRandomMintTransaction = async (
    tokenId: string,
    landAmount: number,
    isMeta: boolean,
    nonce: number,
    senderAddress: string,
    referralAddress?: string
  ) => {
    const args: any[] = !isMeta
      ? [
          BytesValue.fromUTF8(tokenId),
          new BigUIntValue(TokenPayment.egldFromAmount(landAmount).valueOf()),
          BytesValue.fromUTF8("mintRandomNft"),
        ]
      : [
          BytesValue.fromUTF8(tokenId),
          new BigUIntValue(nonce),
          new BigUIntValue(TokenPayment.egldFromAmount(landAmount).valueOf()),
          new AddressValue(new Address(CONTRACT_ADDRESS)),
          BytesValue.fromUTF8("mintRandomNft"),
        ];

    if (referralAddress)
      args.push(new AddressValue(new Address(referralAddress)));

    const payload = !isMeta
      ? TransactionPayload.contractCall()
          .setFunction(new ContractFunction("ESDTTransfer"))
          .setArgs(args)
          .build()
      : TransactionPayload.contractCall()
          .setFunction(new ContractFunction("ESDTNFTTransfer"))
          .setArgs(args)
          .build();

    let tx = new Transaction({
      receiver: !isMeta
        ? new Address(CONTRACT_ADDRESS)
        : new Address(senderAddress),
      gasLimit: 13000000 * Math.ceil(landAmount / 700),
      data: payload,
      chainID: CHAIN_ID,
      sender: this.userAddress,
    });
    tx.setNonce(this.userAccount.nonce);
    await refreshAccount();
    const { sessionId } = await sendTransactions({
      transactions: tx,
    });

    return sessionId;
  };

  createProxyRandomMintTransaction = async (
    tokenId: string,
    landAmount: number,
    decimals: number,
    swapFromAmount: number,
    usdcAmount: number,
    wegldAmount: number,
    tokenLpAddress: string,
    slippage: number,
    isEgld: boolean,
    isWegld: boolean,
    provider: any,
    userShard: number,
    isRandom: boolean,
    indexes: any,
    referralAddress?: string
  ) => {
    const proxyArgs: any[] = !(isEgld || isWegld)
      ? [
          BytesValue.fromUTF8(tokenId),
          new BigUIntValue(
            TokenPayment.fungibleFromAmount(
              tokenId,
              swapFromAmount,
              decimals
            ).valueOf()
          ),
          BytesValue.fromUTF8(multiPairSwap),
          new AddressValue(new Address(tokenLpAddress)),
          BytesValue.fromUTF8(swapTokensFixedInput),
          BytesValue.fromUTF8(WEGLD_ID),
          new BigUIntValue(
            TokenPayment.fungibleFromAmount(
              WEGLD_ID,
              wegldAmount - 0.003 * wegldAmount,
              18
            ).valueOf()
          ),
          new AddressValue(new Address(EGLDUSDCPairAddress)),
          BytesValue.fromUTF8(swapTokensFixedInput),
          BytesValue.fromUTF8(USDC_ID),
          new BigUIntValue(
            TokenPayment.fungibleFromAmount(
              USDC_ID,
              Number(usdcAmount - 0.003 * usdcAmount),
              6
            ).valueOf()
          ),
          new AddressValue(new Address(liquidityAddress)),
          BytesValue.fromUTF8(swapTokensFixedInput),
          BytesValue.fromUTF8(TOKEN_ID),
          new BigUIntValue(
            TokenPayment.fungibleFromAmount(TOKEN_ID, 533, 18).valueOf()
          ),
        ]
      : [
          BytesValue.fromUTF8(WEGLD_ID),
          new BigUIntValue(
            TokenPayment.fungibleFromAmount(
              WEGLD_ID,
              swapFromAmount,
              18
            ).valueOf()
          ),
          BytesValue.fromUTF8(multiPairSwap),
          new AddressValue(new Address(EGLDUSDCPairAddress)),
          BytesValue.fromUTF8(swapTokensFixedInput),
          BytesValue.fromUTF8(USDC_ID),
          new BigUIntValue(
            TokenPayment.fungibleFromAmount(
              USDC_ID,
              Number(usdcAmount - 0.003 * usdcAmount),
              6
            ).valueOf()
          ),
          new AddressValue(new Address(liquidityAddress)),
          BytesValue.fromUTF8(swapTokensFixedInput),
          BytesValue.fromUTF8(TOKEN_ID),
          new BigUIntValue(
            TokenPayment.fungibleFromAmount(TOKEN_ID, 533, 18).valueOf()
          ),
        ];

    const arg_indexes =
      indexes && new List(new ListType(new U64Type()), indexes[0]);
    const args: any[] = isRandom
      ? [
          BytesValue.fromUTF8(TOKEN_ID),
          new BigUIntValue(TokenPayment.egldFromAmount(533).valueOf()),
          BytesValue.fromUTF8("mintRandomNft"),
        ]
      : [
          BytesValue.fromUTF8(TOKEN_ID),
          new BigUIntValue(TokenPayment.egldFromAmount(800).valueOf()),
          BytesValue.fromUTF8("mintSpecificNft"),
          arg_indexes,
        ];

    if (referralAddress)
      args.push(new AddressValue(new Address(referralAddress)));

    const payload = TransactionPayload.contractCall()
      .setFunction(new ContractFunction("ESDTTransfer"))
      .setArgs(args)
      .build();

    const proxyPayload = TransactionPayload.contractCall()
      .setFunction(new ContractFunction("ESDTTransfer"))
      .setArgs(proxyArgs)
      .build();

    let tx = new Transaction({
      receiver: new Address(CONTRACT_ADDRESS),
      gasLimit: 13000000 * Math.round(landAmount / 700),
      data: payload,
      chainID: CHAIN_ID,
      sender: this.userAddress,
    });

    let proxyTx = new Transaction({
      receiver: new Address(MAIAR_ADDRESS),
      gasLimit: 300000000,
      data: proxyPayload,
      chainID: CHAIN_ID,
      sender: this.userAddress,
    });

    proxyTx.setNonce(this.userAccount.nonce);
    tx.setNonce(Number(this.userAccount.nonce) + 1);

    await refreshAccount();
    const { sessionId } = await sendTransactions({
      transactions: !isEgld ? [proxyTx, tx] : [proxyTx, tx],
    });

    return sessionId;
  };

  createSpecificMintTransaction = async (
    tokenId: string,
    indexes: any,
    landAmount: number,
    isMeta: boolean,
    nonce: number,
    senderAddress: string,
    referralAddress?: string
  ) => {
    const arg_indexes = new List(new ListType(new U64Type()), indexes[0]);

    const args: any[] = !isMeta
      ? [
          BytesValue.fromUTF8(tokenId),
          new BigUIntValue(TokenPayment.egldFromAmount(landAmount).valueOf()),
          BytesValue.fromUTF8("mintSpecificNft"),
          arg_indexes,
        ]
      : [
          BytesValue.fromUTF8(tokenId),
          new BigUIntValue(nonce),
          new BigUIntValue(TokenPayment.egldFromAmount(landAmount).valueOf()),
          new AddressValue(new Address(CONTRACT_ADDRESS)),
          BytesValue.fromUTF8("mintSpecificNft"),
          arg_indexes,
        ];

    if (referralAddress)
      args.push(new AddressValue(new Address(referralAddress)));

    const payload = !isMeta
      ? TransactionPayload.contractCall()
          .setFunction(new ContractFunction("ESDTTransfer"))
          .setArgs(args)
          .build()
      : TransactionPayload.contractCall()
          .setFunction(new ContractFunction("ESDTNFTTransfer"))
          .setArgs(args)
          .build();

    let tx = new Transaction({
      receiver: !isMeta
        ? new Address(CONTRACT_ADDRESS)
        : new Address(senderAddress),
      gasLimit: indexes[0].length * 15_000_000,
      data: payload,
      chainID: CHAIN_ID,
      sender: this.userAddress,
    });
    tx.setNonce(this.userAccount.nonce);

    await refreshAccount();
    const { sessionId } = await sendTransactions({
      transactions: [tx],
    });

    return sessionId;
  };

  createClaimTokenTransaction = async () => {
    const data = new TransactionPayload(`claimToken@544553542D653763613835`);

    let tx = new Transaction({
      receiver: new Address(FAUCET_ADDRESS),
      gasLimit: 2600000,
      data: data,
      chainID: CHAIN_ID,
      sender: this.userAddress,
    });

    tx.setNonce(this.userAccount.nonce);
    await refreshAccount();
    sendTransactions({
      transactions: tx,
    });
  };

  createWrapEGLDTransaction = async ({ swapFromAmount, userShard }: any) => {
    let wrapTx = new Transaction({
      data: new TransactionPayload("wrapEgld"),
      gasLimit: 10000000,
      receiver: new Address(
        userShard == 0
          ? SHARD_0_WRAP
          : userShard == 1
          ? SHARD_1_WRAP
          : SHARD_2_WRAP
      ),
      value: TokenPayment.egldFromAmount(swapFromAmount),
      chainID: CHAIN_ID,
      sender: this.userAddress,
    });

    wrapTx.setNonce(this.userAccount.nonce);
    await refreshAccount();
    const { sessionId } = await sendTransactions({
      transactions: [wrapTx],
    });

    return sessionId;
  };

  getReferralCount = async () => {
    let query = this.contract.createQuery({
      func: new ContractFunction("getRefCount"),
      args: [new AddressValue(this.userAddress)],
      caller: this.userAddress,
    });

    let queryResponse = await this.provider.queryContract(query);
    let parser = new ResultsParser();
    let bundle = parser.parseUntypedQueryResponse(queryResponse);

    return bundle.values;
  };

  getReferralMoney = async () => {
    let query = this.contract.createQuery({
      func: new ContractFunction("getRefMoney"),
      args: [new AddressValue(this.userAddress)],
      caller: this.userAddress,
    });

    let queryResponse = await this.provider.queryContract(query);
    let parser = new ResultsParser();
    let bundle = parser.parseUntypedQueryResponse(queryResponse);

    return bundle.values;
  };

  getDidMint = async () => {
    let query = this.contract.createQuery({
      func: new ContractFunction("getDidMint"),
      args: [new AddressValue(this.userAddress)],
      caller: this.userAddress,
    });
    try {
      let queryResponse = await this.provider.queryContract(query);
      let parser = new ResultsParser();
      let bundle = parser.parseUntypedQueryResponse(queryResponse);
      return bundle.values;
    } catch (e) {}
    return [true];
  };

  // getEquivalent = async () => {
  //   let contractAddress = new Address(PAIR_ADDRESS);
  //   let contract = new SmartContract({ address: contractAddress });

  //   let query = contract.createQuery({
  //     func: new ContractFunction("getEquivalent"),
  //     args: [BytesValue.fromUTF8("WEGLD-d7c6bb"), new BigUIntValue(100)],
  //     caller: new Address(this.userAddress),
  //   });

  //   let queryResponse = await networkProvider.queryContract(query);
  //   let bundle = new ResultsParser().parseUntypedQueryResponse(queryResponse);
  // };
}
