import {
  useGetAccountInfo,
  useGetNetworkConfig,
  useTrackTransactionStatus,
} from "@multiversx/sdk-dapp/hooks";
import { scaleFadeInVariants } from "animation/variants";
import { getBalance } from "api/transaction";
import axios from "axios";
import Info from "./Info";
import Input from "components/input";
import { API_URL, LKTOKEN_ID1, LKTOKEN_ID2, TOKEN_ID, WEGLD_ID } from "config";
import { AnimatePresence, motion } from "framer-motion";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";

import {
  allTokensAtom,
  balanceAtom,
  buyModalAtom,
  isLandAtom,
  isLkLandAtom,
  metaBalanceAtom,
  pairsAtom,
  referralCodeAtom,
  selectedTokenBalanceAtom,
  selectedTokenIdAtom,
  specificTotalAtom,
  transactionsAtom,
  userAtom,
} from "store/atoms";
import { notifyError, notifySuccess } from "utils/notifications";
import {
  getPairs,
  handleGetBalance,
  handleTokenChange,
  handleUpdateInputsReverse,
} from "utils/swapTransactions";
import useMintContract from "utils/useMintContract";
import { Icon } from "components/icons/Icon";
import Actions from "./Actions";

const BuyPlaceCard = () => {
  const [user] = useAtom(userAtom);
  const [buyModal, setBuyModal] = useAtom(buyModalAtom);
  const { network } = useGetNetworkConfig();
  const { address, account, shard, ...rest } = useGetAccountInfo();
  const [selectedTokenBalance, setSelectedTokenBalance] = useAtom(
    selectedTokenBalanceAtom
  );

  const [allTokens, setAllTokens] = useAtom(allTokensAtom);
  const [balance, setBalance] = useAtom(balanceAtom);
  const [metaBalance, setMetaBalance] = useAtom(metaBalanceAtom);
  const [totalPrice, setTotalPrice] = useState(800);
  const [landPair, setLandPair] = useState({});
  const [pairs, setPairs] = useAtom(pairsAtom);
  const [selectedTokenId, setSelectedTokenId] = useAtom(selectedTokenIdAtom);

  const [tokenAmount, setTokenAmount] = useState(0);
  const [usdcAmount, setUsdcAmount] = useState(0);
  const [wegldAmount, setWegldAmount] = useState(0);

  const [loading, setLoading] = useState(false);
  const [hasWrapped, setHasWrapped] = useState(false);

  const [transactions, setTransactions] = useAtom(transactionsAtom);
  const [referralCode, setReferralCode] = useAtom(referralCodeAtom);
  const [total, setTotal] = useAtom(specificTotalAtom);

  const [canReferral, setCanReferral] = useState(true);
  const { mintContract } = useMintContract(TOKEN_ID);
  const [price, setPrice] = useState(800);
  const [totalLandBalance, setTotalLandBalance] = useState(0);
  const [totalLkLandBalance, setTotalLkLandBalance] = useState<any>({
    "LKLAND-6cf78e": 0,
    "LKLAND-c617f7": 0,
  });
  const [sessionId, setSessionId] = useState<string | null>(null);

  const [isLand, setIsLand] = useAtom(isLandAtom);
  const [isLkLand, setIsLkLand] = useAtom(isLkLandAtom);

  const [loaded, setLoaded] = useState({ balance: false, stake_types: false });

  const { tiles } = buyModal;

  const transactionStatus = useTrackTransactionStatus({
    transactionId: sessionId,
    onSuccess: () => {
      notifySuccess("Transaction successful");
      if (selectedTokenId === "EGLD") {
        setSelectedTokenId(WEGLD_ID);
        setHasWrapped(true);
        setLoading(false);
      }
    },
    onFail: () => {
      notifyError("Transaction failed");
    },
  });

  useEffect(() => {
    user.address &&
      getBalance(user.address).then((res) => {
        setBalance(res.balance);
        setMetaBalance(res.metaBalance);
      });
  }, [user]);

  const handleClose = () => {
    setBuyModal({ isOpen: false });
  };

  useEffect(() => {
    if (user.address && mintContract) {
      axios
        .get(
          `${API_URL}/accounts/${user.address}/nfts/count?collections=TILE-4aaa4f%2C%20TILE-9d6c87`
        )
        .then((res) => {
          res.data !== 0 && setCanReferral(false);
        });
    }
  }, [user, mintContract]);

  useEffect(() => {
    getPairs().then((res) => {
      setPairs(res.data.data.pairs);
      setLandPair(
        res.data.data.pairs.filter(
          (pair: any) =>
            pair.firstToken.identifier === TOKEN_ID && pair.state === "Active"
        )[0]
      );
    });
  }, [TOKEN_ID, selectedTokenId]);

  useEffect(() => {
    selectedTokenBalance < total && setSelectedTokenId("EGLD");
  }, []);

  useEffect(() => {
    setPrice(selectedTokenId == TOKEN_ID ? 800 : 1080);
  }, [selectedTokenId]);

  const handleChangeReferralCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReferralCode(e.target.value);
  };

  useEffect(() => {
    if (account.address != "" && !loaded.balance) {
      setLoaded({ ...loaded, balance: true });
      axios
        .get(
          `${network.apiAddress}/accounts/${account.address}/tokens?size=100`
        )
        .then((res: any) => {
          if (res.data?.length > 0) {
            const tokens = res.data.filter(
              (a: any) => a?.identifier === TOKEN_ID || a?.ticker === TOKEN_ID
            );
            const lkLand1 = res.data.filter(
              (a: any) =>
                a?.identifier === LKTOKEN_ID1 || a?.ticker === LKTOKEN_ID1
            );
            const lkLand2 = res.data.filter(
              (a: any) =>
                a?.identifier === LKTOKEN_ID2 || a?.ticker === LKTOKEN_ID2
            );
            setTotalLandBalance(
              tokens.length > 0 ? Math.floor(tokens[0]?.balance / 10 ** 18) : 0
            );
            setTotalLkLandBalance({
              "LKLAND-6cf78e":
                lkLand1.length > 0
                  ? Math.floor(lkLand1[0]?.balance / 10 ** 18)
                  : 0,
              "LKLAND-c617f7":
                lkLand2.length > 0
                  ? Math.floor(lkLand2[0]?.balance / 10 ** 18)
                  : 0,
            });
          }
        });
    }
  }, [account]);

  useEffect(() => {
    handleTokenChange(selectedTokenId, setIsLand, setIsLkLand);
  }, [selectedTokenId]);

  useEffect(() => {
    handleGetBalance(
      setSelectedTokenBalance,
      selectedTokenId,
      account,
      balance
    );
  }, [selectedTokenId, balance]);

  useEffect(() => {
    landPair &&
      selectedTokenId &&
      selectedTokenId.split("-")[0] !== "LAND" &&
      selectedTokenId.split("-")[0] !== "LKLAND" &&
      Object.keys(allTokens).length > 1 &&
      handleUpdateInputsReverse(
        800,
        selectedTokenId,
        allTokens,
        pairs,
        landPair,
        setUsdcAmount,
        setWegldAmount,
        setTokenAmount,
        false
      );
  }, [landPair, selectedTokenId, allTokens, selectedTokenId]);

  useEffect(() => {
    tiles &&
      (isLkLand
        ? setTokenAmount(1080 * tiles.length)
        : setTokenAmount(800 * tiles.length));
  }, [isLkLand, isLand]);

  useEffect(() => {
    landPair &&
      selectedTokenId &&
      selectedTokenId.split("-")[0] !== "LAND" &&
      Object.keys(allTokens).length > 1 &&
      handleUpdateInputsReverse(
        800,
        selectedTokenId,
        allTokens,
        pairs,
        landPair,
        setUsdcAmount,
        setWegldAmount,
        setTokenAmount,
        false
      );
  }, [landPair, selectedTokenId, allTokens, selectedTokenId]);

  useEffect(() => {
    tiles && setTotal(isLand ? tokenAmount : tokenAmount * tiles.length);
  }, [isLand, isLkLand, referralCode, tiles, tokenAmount]);

  const hasEnoughEgldForGas = Number(account.balance) >= 13000000;
  const hasEnoughLandToMint =
    totalLandBalance >= price || totalLkLandBalance[selectedTokenId] >= price;

  const disabled = !hasEnoughEgldForGas || selectedTokenBalance < price;

  return (
    <motion.div
      className="buy-place-card"
      variants={scaleFadeInVariants}
      animate="visible"
      initial="hidden"
      exit="hidden"
    >
      <h1>
        Buy
        <span className="text-purple"> {tiles?.length}</span>{" "}
        {tiles?.length == 1 ? "tile" : "tiles"}
      </h1>
      <button className="buy-place-card__close" onClick={handleClose}>
        <Icon width={28} name="close" primary />
      </button>
      <Info />

      {disabled && (
        <div
          className="buy-place-card__info"
          style={{ color: "#fa3c4c", borderColor: "#fa3c4c" }}
        >
          <Icon name="info" color="#fa3c4c" />
          <span>
            It looks like you don't have enough LAND. Buy some{" "}
            <a
              href="https://jungledex.com/swap?firstToken=EGLD&secondToken=LAND-40f26f"
              className="font-bold"
              target="_blank"
            >
              here
            </a>{" "}
            and come back!
          </span>
        </div>
      )}
      {canReferral && (
        <Input
          value={referralCode}
          placeholder="landboard"
          label="Referral Code (herotag or address)"
          onChange={handleChangeReferralCode}
        />
      )}
      <Actions
        setTransactions={setTransactions}
        setSessionId={setSessionId}
        setLoading={setLoading}
        tokenAmount={tokenAmount}
        usdcAmount={usdcAmount}
        wegldAmount={wegldAmount}
        transactions={transactions}
        handleClose={handleClose}
        selectedTokenBalance={selectedTokenBalance}
        loading={loading}
        hasWrapped={hasWrapped}
      />
    </motion.div>
  );
};

export default BuyPlaceCard;
