import tilebelt from "@mapbox/tilebelt";
import { polygon } from "@turf/turf";

export const getExtentsGeom = (bounds: any) => {
	//get the geometry of the extent of the screen
	const box = [
		bounds.getSouthWest().toArray(),
		bounds.getNorthWest().toArray(),
		bounds.getNorthEast().toArray(),
		bounds.getSouthEast().toArray(),
		bounds.getSouthWest().toArray(),
	].map((coords) => {
		if (coords[0] < -180) return [-179.99999, coords[1]];
		if (coords[0] > 180) return [179.99999, coords[1]];
		return coords;
	});

	return {
		type: "Polygon",
		coordinates: [box],
	};
};

export const getTileFeature = (tile: any) => {
	const quadkey = tilebelt.tileToQuadkey(tile);
	const box = tilebelt.tileToBBOX(tile);
	const center = [(box[0] + box[2]) / 2, (box[1] + box[3]) / 2];
	const feature = {
		type: "Feature",
		properties: {
			quadkey: quadkey,
			center: center,
		},
		geometry: tilebelt.tileToGeoJSON(tile),
	};
	return feature;
};

export const mapFeatureToTile = (feature: any) => ({
	id: feature.id,
	polygon_coords: feature.geometry.coordinates,
	center: feature.properties.center,
	quad_key: feature.properties.quadkey,
});

export const romaniaBoundariesPolygon: any = polygon([
	[
		[26.722882000104278, 48.26311500035706],
		[26.475058000226568, 48.22149500019418],
		[26.3460590004226, 48.18086299972771],
		[26.169314000340876, 47.988460999775384],
		[25.94402099986928, 47.981507000147985],
		[25.80537700044829, 47.94957399974032],
		[25.627679999632335, 47.942607000412806],
		[25.226547000343828, 47.89918499988789],
		[25.114221999645622, 47.76015099956902],
		[24.896391999791035, 47.72563999988614],
		[24.834141999753513, 47.815044000280466],
		[24.708592999867108, 47.85134799991033],
		[24.581496999686863, 47.9665540003669],
		[24.424179999807222, 47.977497999633044],
		[24.365678999985903, 47.91829799995281],
		[24.223263000187387, 47.904338000098676],
		[23.951833999939197, 47.96955100007864],
		[23.66592700023091, 47.97996900026919],
		[23.607428999648675, 48.010933000057946],
		[23.42092700018094, 47.97847799985988],
		[23.141610000401272, 48.110278000229584],
		[23.076132999938068, 48.00931500008721],
		[22.92411899999189, 48.01816199977651],
		[22.84682400042732, 47.91388299960079],
		[22.76401200005381, 47.88413600017634],
		[22.68173500007026, 47.79038599991401],
		[22.267190000118433, 47.73203299972323],
		[22.174351999645125, 47.59328800014117],
		[22.024223000291272, 47.52365799971699],
		[22.03620199962262, 47.41757099980185],
		[21.891093999788325, 47.314105000245604],
		[21.852049999707788, 47.189918000215584],
		[21.781763999608927, 47.108328999583705],
		[21.645387000409812, 47.042152000114754],
		[21.679562999932557, 47.00392900012332],
		[21.603380000386778, 46.86951000038624],
		[21.51125300032578, 46.83910899958073],
		[21.530535000090254, 46.73962400014756],
		[21.40797200030761, 46.626461000374945],
		[21.32230999980669, 46.6310459995733],
		[21.254868999604696, 46.50887299978916],
		[21.313189000072327, 46.44178499967802],
		[21.22229300012191, 46.41659600000543],
		[21.178717999735966, 46.30677299957432],
		[21.09688300035026, 46.26722999986464],
		[20.77602899973857, 46.27671100001271],
		[20.711966999777246, 46.17012799970746],
		[20.620473000128925, 46.135952000184716],
		[20.49553799977889, 46.18759899959423],
		[20.457208000249068, 46.14395799976194],
		[20.353849000231207, 46.17319099976487],
		[20.272408000129133, 46.13582899990848],
		[20.35373899965515, 45.998680000181935],
		[20.561715000147217, 45.90565900026269],
		[20.693299000448576, 45.75266299999748],
		[20.790490000213367, 45.73400800036899],
		[20.760656000374183, 45.61507799994979],
		[20.82011000007651, 45.54275899962818],
		[20.77039499982942, 45.50038999999856],
		[20.867135000380017, 45.45498299966573],
		[20.96975999982419, 45.35298899964266],
		[21.20104899981152, 45.252986999750306],
		[21.39910599974212, 45.218683999766824],
		[21.508541000266973, 45.157274999841434],
		[21.413206999803265, 45.029829999754725],
		[21.42432699999108, 44.95644399969086],
		[21.540109000030384, 44.92154400005563],
		[21.517350000002523, 44.86789299996201],
		[21.41217399983026, 44.87001699988468],
		[21.356974000249977, 44.81558699984021],
		[21.412073999715346, 44.76706399970385],
		[21.534083000030478, 44.756823999627954],
		[21.601461000025154, 44.718016999684835],
		[21.630002999638748, 44.64807500016116],
		[21.77264299987462, 44.64567300000897],
		[21.982977000288372, 44.61534199973357],
		[22.05675500044299, 44.50819100021454],
		[22.16750100027099, 44.473723999862784],
		[22.2959410003769, 44.653412999910074],
		[22.45961700032433, 44.69892899987349],
		[22.599128000157123, 44.6086159998286],
		[22.677915000177165, 44.597263999733855],
		[22.748085000322533, 44.533317999725966],
		[22.685547999640505, 44.50567900038561],
		[22.56305400034188, 44.53187999996209],
		[22.450356999575945, 44.46508099968844],
		[22.51553799987886, 44.32907799974993],
		[22.565798000077677, 44.28989300036176],
		[22.686300000144968, 44.28425899955323],
		[22.685637000147494, 44.21524899984155],
		[22.779643999670895, 44.197131999841986],
		[23.04821300022985, 44.04604600042262],
		[22.892636999697856, 43.987836999677825],
		[22.84238900005238, 43.89604699986921],
		[22.875120000387767, 43.844581999859486],
		[23.108673999605458, 43.811583999801826],
		[23.287054999588634, 43.85816799955359],
		[23.446136999922658, 43.86486800005821],
		[23.637487999971256, 43.80372399976284],
		[23.746886999735295, 43.81746899959472],
		[23.896729000243226, 43.76429199977605],
		[23.976828999658665, 43.759047999865345],
		[24.184505999852036, 43.69651800040543],
		[24.350571000251534, 43.710514000121066],
		[24.55055099982883, 43.77959699960189],
		[24.73024500010672, 43.72797899973108],
		[24.990009000215252, 43.74951500001731],
		[25.098484999683023, 43.70410999977673],
		[25.26622499998382, 43.700539999721286],
		[25.379937999938818, 43.63978300018539],
		[25.59078100044286, 43.66476300011243],
		[25.663256999910686, 43.70611499960762],
		[25.783795999885513, 43.727682999570845],
		[25.925852000169698, 43.85115299981112],
		[26.125413000209846, 43.99798399996166],
		[26.309212000033824, 44.0473060000719],
		[26.606300000044996, 44.06770900027021],
		[26.780380000436708, 44.09668199997435],
		[26.895562999832634, 44.14823300035272],
		[27.13448500018268, 44.150108999630675],
		[27.260250000137432, 44.129920000307834],
		[27.28219300030679, 44.07734100018706],
		[27.388291999875946, 44.01842500011372],
		[27.599661000354956, 44.020964000288416],
		[27.701071000426396, 43.96597999987716],
		[27.918502999913585, 43.99142000033282],
		[27.983900000284848, 43.84010600029171],
		[28.226994999719523, 43.77020700005306],
		[28.470033000122896, 43.73562299970172],
		[28.56704699981924, 43.73986400012353],
		[28.59684399975083, 43.88205799957706],
		[28.655008000219084, 43.99483200029664],
		[28.62657300014388, 44.070636000351215],
		[28.64482500007375, 44.19068900039713],
		[28.61335400028696, 44.269490000163444],
		[28.760060000069018, 44.42637900009083],
		[28.78885000032733, 44.61663399995916],
		[28.769774000170003, 44.704367999783415],
		[28.91673199988196, 44.76209599993052],
		[28.91337699984871, 44.83176899963968],
		[28.852030000084483, 44.87114299964975],
		[28.850138000068682, 44.94046800039797],
		[28.998910999842792, 44.992347999760455],
		[29.08836499984494, 44.7409179999658],
		[29.242620999759367, 44.794418000290534],
		[29.341218999657144, 44.78876200026616],
		[29.58224199976854, 44.83010100006118],
		[29.670461000374928, 45.03913800001874],
		[29.651813000169852, 45.31974099974741],
		[29.53496599958129, 45.401947000054065],
		[29.412749999612856, 45.42979399999314],
		[29.248099999715294, 45.421981000322944],
		[29.101993999723334, 45.35938200037907],
		[28.954787000219937, 45.3191759997726],
		[28.94184799978541, 45.264850000027536],
		[28.792500999621495, 45.31824600005291],
		[28.771500999771376, 45.21971399960148],
		[28.57521499967254, 45.23740499974099],
		[28.345260000048995, 45.30770499958612],
		[28.279809999931615, 45.435264000433335],
		[28.202088999607724, 45.45517000019538],
		[28.163063000357226, 45.54966000040872],
		[28.166816999904654, 45.7257459997781],
		[28.108486999875197, 45.821364999894854],
		[28.127045999573284, 45.928767000196956],
		[28.086736999612867, 46.01365600012434],
		[28.141419999633342, 46.1693869997103],
		[28.1176539999322, 46.2255240002325],
		[28.208191999561166, 46.29645100021355],
		[28.194099999915124, 46.36239299963728],
		[28.26142600020978, 46.423547000393796],
		[28.212960000004045, 46.51470499983594],
		[28.26277799960502, 46.63657099985181],
		[28.211695000124223, 46.68349399994821],
		[28.188744000235488, 46.77917900041058],
		[28.128448000374988, 46.81636700033687],
		[28.1115980003475, 46.97041399960648],
		[28.065429999994535, 47.014736000266225],
		[27.896293000022013, 47.066153999907215],
		[27.72712799965751, 47.28764000018589],
		[27.651663999993673, 47.29829699970685],
		[27.54310899958074, 47.477443999940704],
		[27.32977200007781, 47.635344999771974],
		[27.245872999939024, 47.80190699970831],
		[27.226089999553892, 47.89966100025504],
		[27.14176699964736, 48.021016000088366],
		[27.09147299967924, 48.041863000077456],
		[26.9578079996395, 48.1930470004188],
		[26.820666000235747, 48.25352399963293],
		[26.722882000104278, 48.26311500035706],
	],
]);
