import "./shared/fonts.css";
import "./shared/tailwind.css";

import { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App";

ReactDOM.render(
	<StrictMode>
		<App />
	</StrictMode>,
	document.getElementById("app")
);
