import { Icon } from "components/icons/Icon";
import Lottie from "react-lottie-player";
import confirmationJson from "assets/lottie/confirmation.json";
import errorLottieJson from "assets/lottie/error.json";
import format from "date-fns/format";
import { ReactNode } from "react";

interface NotificationProps {
	handleClose: () => void;
	handleLocation?: () => void;
	handleView?: () => void;
	status?: string | "success" | "error";
	extra?: ReactNode;
	message: ReactNode;
	date: Date;
}

const Notification = ({ handleClose, handleLocation, handleView, message, status, date, extra }: NotificationProps) => {
	const isSuccess = status === "success";
	const isFailed = status === "error";

	return (
		<div className={`notification ${isSuccess ? "success" : "error"}`}>
			<div className="notification-header">
				<Lottie
					className="notification-animation"
					animationData={isSuccess ? confirmationJson : errorLottieJson}
					style={
						isSuccess ? { width: 50, height: 50, top: 36, left: 16 } : { width: 128, height: 128, top: 12, left: -20 }
					}
					loop={false}
					play
				/>
				<div className="notification-header__info">
					{extra && <span>{extra}</span>}
					<span>
						<Icon name="calendar" /> {format(date, "dd-MM-yyyy H:mm")}
					</span>
					<span>{message}</span>
				</div>
			</div>
			<div className="notification-actions">
				<button className="notification-close" onClick={handleClose}>
					<Icon name="close" color="#ffffff" width={36} />
				</button>
				<div className="notification-actions__extra">
					{handleView && (
						<button onClick={handleView}>
							<Icon name="search" />
						</button>
					)}
					{handleLocation && (
						<button onClick={handleLocation}>
							<Icon name="map-location" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default Notification;
