import { Icon } from "components/icons/Icon";
import React, { useState } from "react";
import useTimeUntil from "utils/useTimeUntil";

const Promo = ({ isMini }: any) => {
  const promoTime = "2022-12-04T19:40:00Z";

  const { timeLeft, days, hours, minutes, seconds } = useTimeUntil(
    new Date(promoTime).getTime()
  );

  return (
    <div className="p-5 rounded bg-gray-darker">
      <div className="mb-2">
        <span className="flex items-center mb-1 capitalize">
          <Icon name="gift" width="25" />
          <p className="ml-1 text-sm uppercase text-gray-light">
            Special promo
          </p>
        </span>
        <p className="text-xl">15% cashback for all Tiles.</p>
      </div>
      {!isMini && (
        <p className="text-sm text-gray-light">
          To celebrate Romania's National Day, a 15% cashback is applied to all
          mints.
        </p>
      )}
      <div className="mt-5">
        {!isMini ? (
          <p className="text-md">
            Expires in {days} days {hours} hours {minutes} minutes {seconds}{" "}
            seconds
          </p>
        ) : (
          <p className="text-md">
            Expires in {days}:{hours}:{minutes}:{seconds}{" "}
          </p>
        )}
      </div>
    </div>
  );
};

export default Promo;
