import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import CopyToClipboard from "components/buttons/CopyToClipboard";
import { Icon } from "components/icons/Icon";
import Input from "components/input";
import { useAtom } from "jotai";
import {
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { shareReferralModalAtom } from "store/atoms";

const ShareReferralCard = () => {
  const { address } = useGetAccountInfo();
  const [, setShareReferralModal] = useAtom(shareReferralModalAtom);

  const link = `${window.location.href.split("/rewards")[0]}?ref=${address}`;
  const title = `Hey! I own virtual land NFTs on Landboard, the newest metaverse application built on the Elrond blockchain. You should try it too! Use my referral code 3% discount on the first mint: ${link}`;

  const handleClose = () => {
    setShareReferralModal({ isOpen: false });
  };

  return (
    <div className="share-tile-card">
      <h2>Share your code to friends and both earn!</h2>
      <button className="buy-place-card__close" onClick={handleClose}>
        <Icon width={28} name="close" primary />
      </button>
      <Input
        label="Referral link"
        value={link}
        LabelButton={<CopyToClipboard text={title} />}
        readOnly
      />
      {/* <p>Share your referral code in a couple of clicks!</p> */}
      <div className="share-tile-card__socials">
        <TelegramShareButton url={link} title={title} className="">
          <TelegramIcon round={true} size={50} />
        </TelegramShareButton>
        <TwitterShareButton
          url={link}
          title={`Hey! I own virtual land NFTs on @landboard_io, the newest metaverse application on @ElrondNetwork. \n\nUse my code for 3% discount on the first mint:`}
          className=""
          hashtags={["landboard", "metaverse"]}
        >
          <TwitterIcon round={true} size={50} />
        </TwitterShareButton>
        <WhatsappShareButton
          url={link}
          title={title}
          separator=":: "
          className=""
        >
          <WhatsappIcon round={true} size={50} />
        </WhatsappShareButton>
      </div>
    </div>
  );
};

export default ShareReferralCard;
