import { fadeInVariants, motionContainerProps } from "animation/variants";
import Button from "components/buttons";
import Dracula from "components/icons/Dracula";
import { Icon } from "components/icons/Icon";
import NotFound from "components/notFound";
import { motion } from "framer-motion";

const NotFoundPage = () => {
  return (
    <motion.div className="page-404" {...motionContainerProps}>
      <NotFound />
    </motion.div>
  );
};

export default NotFoundPage;
