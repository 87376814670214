import { scaleFadeInVariants } from "animation/variants";
import Button from "components/buttons";
import Checkbox from "components/checkbox";
import { Select } from "components/dropdown";
import { Icon } from "components/icons/Icon";
import Input from "components/input";
import { motion } from "framer-motion";
import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { offerModalAtom } from "store/atoms";
import TinyPlaceCard from "./TinyPlaceCard";
import TokenSelector from "../TokenSelector";

const OfferPlaceCard = () => {
  const [offerModal, setOfferModal] = useAtom(offerModalAtom);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const isChecked = watch("isChecked");
  const amount = watch("amount");
  const days = watch("days");

  useEffect(() => {
    register("isChecked", {
      required: "You must agree to the terms and conditions",
    });
    register("amount", {
      // required: "You must enter an amount",
    });
    register("days", {
      // required: "You must enter the number of days",
    });
  }, []);

  const handleClose = () => {
    setOfferModal({ ...offerModal, isOpen: false });
  };

  const { name, tileCount, owner, lastPrice, image } = offerModal;

  const onSubmit = (data: unknown) => console.log(data);

  return (
    <motion.div
      className="offer-place-card"
      variants={scaleFadeInVariants}
      animate="visible"
      initial="hidden"
      exit="hidden"
    >
      <button className="offer-place-card__close" onClick={handleClose}>
        <Icon width={20} name="close" primary />
      </button>
      <h1>
        Make an <span className="text-purple">Offer</span>
      </h1>
      <TinyPlaceCard
        name={name}
        owner={owner}
        tileCount={tileCount}
        lastPrice={lastPrice}
        image={image}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TokenSelector />
        <Input
          label="Amount"
          placeholder="Token Amount"
          onChange={(e) => setValue("amount", e.target.value)}
        />
        <Input
          label="Expires in (DAYS)"
          placeholder="30"
          onChange={(e) => setValue("days", e.target.value)}
        />
        <div className="offer-place-card__info">
          <span>
            By sending this offer, you accept that we will put this amount into
            a smart contract deposit, until it is accepted or you cancel your
            offer.
          </span>
        </div>
        <Checkbox
          onChange={(isChecked) => {
            setValue("isChecked", isChecked);
          }}
          defaultChecked={isChecked}
          label="By checking this box, I agree to LandBoard’s Terms of Service."
        />
        <div className="offer-place-card__actions">
          <span>2200 LAND</span>
          <Button
            containerClassname="flex-1"
            className="filled"
            type="submit"
            disabled={!(isChecked && amount && days)}
            hideComingSoon
          >
            <Icon name="tag2" />
            Send
          </Button>
        </div>
      </form>
    </motion.div>
  );
};

export default OfferPlaceCard;
