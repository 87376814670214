import React from "react";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import { getNFTsByFilters, getUserNFTs } from "api/supabase-client";
import Button from "components/buttons";
import FiltersCard from "components/cards/FiltersCard";
import NFTCard from "components/cards/NFTCard";
import Checkbox from "components/checkbox";
import { Option, OptionGroup, Select } from "components/dropdown";
import Input from "components/input";
import Modal from "components/modals";
import NotFound from "components/notFound";
import Pagination from "components/pagination";
import { STORAGE_URL } from "config";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useDebounce, useMedia } from "react-use";
import { loadingAtom, totalRewardsAtom, userProfileAtom } from "store/atoms";
import { NFT } from "types/components";
import { ArrowDown } from "components/icons";
import { Icon } from "components/icons/Icon";
import RewardsCard from "components/cards/rewards/RewardsCard";

const sortByOptions = [
  {
    label: "Ranking",
    value: "rank",
  },
  {
    label: "Views",
    value: "view_count",
  },
];

const sortDirections = [
  {
    label: "Ascending",
    value: "asc",
  },
  {
    label: "Descending",
    value: "desc",
  },
];

const Nfts = () => {
  const [loading, setLoading] = useAtom(loadingAtom);

  const [sortBy, setSortBy] = useState(sortByOptions[0].value);
  const [sortDirection, setSortDirection] = useState(sortDirections[0].value);
  const [nfts, setNFTs] = useState<NFT[]>([]);
  const [totalNFTs, setTotalNFTs] = useState(0);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    search: "",
    location: "",
    owned: true,
  });
  const [total, setTotal] = useState(0);
  const [allTimeRewards, setAllTimeRewards] = useState(0);
  const [accumulated, setAccumulated] = useState(0);
  const [userProfile, setUserProfile] = useAtom(userProfileAtom);
  const [totalRewards, setTotalRewards] = useAtom(totalRewardsAtom);

  const { address, account } = useGetAccountInfo();
  // const { tiles, avatar, ...detailsProps } = userProfile;

  const isPhone = useMedia("(max-width: 767px)");

  useDebounce(
    () => {
      getNFTsByFilters({
        page,
        sortBy,
        sortDirection,
        perPage: 5,
        search: filters.search,
        owner: filters.owned ? address : "",
      })
        .then(({ nfts, count }: any) => {
          setNFTs(
            nfts.map((nft: any) => {
              return {
                ...nft,
                owner: (
                  nft.users.herotag ?? nft.users.address.slice(0, 6) + "..."
                ).replace(".elrond", ""),
                rank: nft.tiles?.rank,
                center: nft.tiles?.center,
                stake_timestamp: new Date(nft?.stake_timestamp),
                tiles: nft.tiles,
              };
            })
          );
        })
        .finally(() => setLoading(false));

      getUserNFTs(address)
        .then((res: any) => {
          setTotal(
            res.reduce((acc: number, nft: any) => {
              return acc + nft.final_reward;
            }, 0)
          );
          setAllTimeRewards(
            res.reduce((acc: number, nft: any) => {
              return acc + nft.reward;
            }, 0)
          );
          setTotalNFTs(res.length);
        })
        .finally(() => setLoading(false));
    },
    500,
    [page, address, sortBy, sortDirection, filters]
  );

  const handleResetFilters = () => {
    setSortBy(sortByOptions[0].value);
    setSortDirection("");
    setPage(1);
  };

  const handleUpdateFilter = (filter: any, value: any) =>
    setFilters({ ...filters, [filter]: value });

  // useEffect(() => {
  //   // console.log(nfts);
  //   setTotal(0);
  //   nfts.map((nft) => {
  //     setTotal((prevState) => prevState + nft.final_reward);
  //     setAccumulated((prevState) => prevState + nft.reward);
  //   });
  // }, [nfts]);

  // console.log("nfts", nfts);
  return (
    <div className="nfts-container bg-purple-darkest">
      {/* {isPhone && showFiltersModal && (
        <Modal onClose={() => setShowFiltersModal(false)}>
          <FiltersCard
            search={filters.search}
            location={filters.location}
            owned={filters.owned}
            updateFilter={handleUpdateFilter}
          />
        </Modal>
      )} */}

      {/* <Select
      value={sortBy}
      render={(selectedIndex: number) => (
        <div>{sortByOptions[selectedIndex]?.label ?? "Select..."} </div>
      )}
      onChange={setSortBy}
    >
      <OptionGroup label={""}>
        {sortByOptions.map(({ label, value }) => (
          <Option key={value} value={value}>
            <div>
              <span>{label}</span>
            </div>
          </Option>
        ))}
      </OptionGroup>
    </Select>
    <Select
      value={sortDirection}
      render={(selectedIndex: number) => (
        <div>{sortDirections[selectedIndex]?.label ?? "Select..."} </div>
      )}
      onChange={setSortDirection}
    >
      <OptionGroup label={""}>
        {sortDirections.map(({ label, value }) => (
          <Option key={value} value={value}>
            <div>
              <span>{label}</span>
            </div>
          </Option>
        ))}
      </OptionGroup>
    </Select> */}
      {/* <div>
        {totalNFTs > 0 && <p>{totalNFTs} NFTs owned.</p>}
        {totalNFTs > 0 && filters.owned && address && (
          <>
            <p>
              Total Accumulated Rewards: {Number(accumulated).toFixed(3)} LAND
            </p>
            <p>Aprox. Expected Rewards: {Number(total).toFixed(3)} LAND*</p>
          </>
        )}
      </div> */}
      <div className="mb-5 text-center">
        <h1 className="text-center">Tiles Rewards</h1>
        <p>Earn up to 60% APR every 2 weeks for each tile.</p>
      </div>

      <RewardsCard
        total={total}
        totalNFTs={totalNFTs}
        allTimeRewards={allTimeRewards}
      />
      <div className="filters">
        <Input
          label="Search"
          placeholder="Tile name or location"
          value={filters.search}
          onChange={(e: any) => handleUpdateFilter("search", e.target.value)}
        />
        {/* <Checkbox
          defaultChecked={filters.owned}
          onChange={(isChecked) => handleUpdateFilter("owned", isChecked)}
          label="Owned"
        /> */}
      </div>
      <div className="nfts-container__list">
        {nfts.map((nft) => {
          // console.log("nft", nft);
          return (
            <NFTCard
              key={nft.quad_key}
              img={STORAGE_URL + "/nft/" + nft.quad_key + ".webp"}
              // @ts-ignore
              tiles={nft.tiles}
              {...nft}
            />
          );
        })}
        {!loading && totalNFTs == 0 && (
          <NotFound>
            <p className="mb-2">No nfts were found</p>
            <Button className="filled" onClick={handleResetFilters}>
              Clear filters
            </Button>
          </NotFound>
        )}
        {!loading && totalNFTs > 0 && (
          <Pagination
            page={page}
            setPage={setPage}
            pageCount={Math.ceil(totalNFTs / 6)}
            perPage={6}
          />
        )}
        {/* <div className="filters-button">
                <Button className="filled" onClick={() => setShowFiltersModal(true)}>
                    Filters
                </Button>
            </div> */}
      </div>
    </div>
  );
};

export default Nfts;
