import cover from "@mapbox/tile-cover";
import * as turf from "@turf/turf";
import { Feature } from "@turf/turf";
import { request } from "api/request";
import { getNFTs } from "api/supabase-client";
import {
  getExtentsGeom,
  getTileFeature,
  mapFeatureToTile,
  romaniaBoundariesPolygon,
} from "components/map/helper";
import { imageLayer } from "components/map/layers";
import { STORAGE_URL } from "config";
import { useAtom } from "jotai";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounce, useInterval, useLocalStorage, useMedia } from "react-use";
import {
  loadingAtom,
  opacityAtom,
  refreshCountAtom,
  startLocationAtom,
} from "store/atoms";
import { PopupInfo } from "types/components";
import { NFT, Tile } from "types/Tiles";
import { notifyError } from "utils/notifications";

const gridZoom = 14.799;
const defaultZoom = window.innerWidth < 768 ? 12 : 12.75;

const cities = [
  {
    name: "Cluj",
    latitude: 46.770439,
    longitude: 23.591423,
  },
  {
    name: "Bucharest",
    latitude: 44.439663,
    longitude: 26.096306,
  },
  {
    name: "Sibiu",
    latitude: 45.798327,
    longitude: 24.125583,
  },
  {
    name: "Iasi",
    latitude: 47.151726,
    longitude: 27.587914,
  },
  {
    name: "Timisoara",
    latitude: 45.760696,
    longitude: 21.226788,
  },
];

const useMap = (mapRef: any) => {
  const [features, setFeatures] = useState<Feature[]>([]);

  const [startPoint, setStartPoint] = useState(null);
  const [time, setTime] = useState(Date.now());
  const [tiles, setTiles] = useState<Tile[]>([]);
  const [nfts, setNfts] = useState<NFT[]>([]);
  const [selecting, setSelecting] = useState(false);
  const [popupInfo, setPopupInfo] = useState<PopupInfo | null>(null);
  const [viewState, setViewState] = useState({
    ...cities[Math.floor(Math.random() * cities.length)],
    zoom: defaultZoom,
    bearing: 0,
    pitch: 0,
  });
  const [, setLoading] = useAtom(loadingAtom);
  const [refreshCount] = useAtom(refreshCountAtom);
  const [startLocation] = useAtom(startLocationAtom);
  const [opacityValue] = useAtom(opacityAtom);
  const [viewedTiles, setViewedTiles] = useLocalStorage<
    { id: string; timestamp: number }[]
  >("viewedTiles", []);

  const isPhone = useMedia("(max-width: 600px)");

  const tileCount = useMemo(() => tiles.length, [tiles]);

  const showPopup = async (nft?: NFT) => {
    let address = get(nft, "location", "Unknown");
    let name = get(nft, "name", "Unnamed Land");
    let owner = get(nft, "owner_address", undefined);
    let views = get(nft, "view_count", 0);
    let rank = get(nft, "rank", null);
    let centerPoints: any;
    let center: any = nft ? JSON.parse(nft?.center) : [];

    if (!nft) {
      centerPoints = tiles.map((tile) => turf.point(JSON.parse(tile.center)));
      center = turf.center(turf.featureCollection(centerPoints));

      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${center.geometry.coordinates[0]},${center.geometry.coordinates[1]}.json?types=poi&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
      );
      const data = await response.json();
      address = get(data, "features[0].place_name", null);
    }
    setPopupInfo({
      id: nft?.quad_key,
      name,
      owner,
      address,
      views,
      rank,
      nftId: nft?.image_nft_id,
      link: nft?.link,
      latitude: nft ? center[1] : center.geometry.coordinates[1],
      longitude: nft ? center[0] : center.geometry.coordinates[0],
    });
  };

  const handleClosePopup = () => setPopupInfo(null);

  const handleClick = useCallback(
    (event: any) => {
      const featureTile = event.features && event.features[0];

      if (opacityValue === 0) {
        notifyError("Please enable opacity to select tiles");
        return;
      }

      if (tileCount > 50 && selecting) {
        notifyError("You can only select a maximum of 50 tiles");
        return;
      }

      const mintedNFTs = nfts.filter(
        (nft) => nft.quad_key == featureTile.properties?.quadkey
      );
      const isMintedInSelection = nfts.some((nft) =>
        tiles.some((tile) => tile.quad_key == nft.quad_key)
      );
      const isMinted = mintedNFTs.length > 0;

      if (isMintedInSelection && selecting && !isPhone) {
        notifyError("One or more selected tiles are already minted.");
        setSelecting(false);
        setStartPoint(null);
        return;
      }

      if (isMinted && selecting && !isPhone) {
        setSelecting(false);
        notifyError("This tile is already minted.");
        setStartPoint(null);
        return;
      }

      if (isMinted) {
        showPopup(mintedNFTs[0]);
        setTiles([mapFeatureToTile(featureTile)]);
        const id = mintedNFTs[0].quad_key;

        const tileViewedTimestamp =
          viewedTiles && viewedTiles.find((tile) => tile?.id === id)?.timestamp;
        if (
          (tileViewedTimestamp && Date.now() - tileViewedTimestamp > 300000) ||
          !tileViewedTimestamp
        ) {
          const newViewedTiles = viewedTiles
            ? viewedTiles.filter((tile) => tile?.id !== id)
            : [];
          setViewedTiles([...newViewedTiles, { id, timestamp: Date.now() }]);
          request(`/nfts/${id}/view`, {});
        }
        return;
      }

      const tileCenter = turf.point(JSON.parse(featureTile.properties.center));
      const isInRomania = turf.booleanPointInPolygon(
        tileCenter,
        romaniaBoundariesPolygon
      );

      if (isInRomania) {
        if (featureTile && !selecting) {
          setStartPoint(event.point);
          setTiles([mapFeatureToTile(featureTile)]);
        }

        if (selecting) {
          showPopup();
        } else {
          setPopupInfo(null);
        }

        setSelecting(!selecting);
      } else {
        notifyError("You can only select tiles in Romania");
      }
    },
    [tiles, selecting, startPoint, tileCount, nfts, opacityValue]
  );

  const handleHover = useCallback(
    (event) => {
      if (isPhone) {
        const featureTile = event.features && event.features[0];

        if (opacityValue === 0) {
          notifyError("Please enable opacity to select tiles");
          return;
        }
        const tileCenter = turf.point(
          JSON.parse(featureTile.properties.center)
        );
        const isInRomania = turf.booleanPointInPolygon(
          tileCenter,
          romaniaBoundariesPolygon
        );

        if (!isInRomania) return;
      }
      if (selecting) {
        const features = mapRef.current.queryRenderedFeatures(
          [startPoint, event.point],
          {
            layers: ["grid-layer"],
          }
        );
        if (
          isPhone &&
          nfts.some((nft) =>
            features.some(
              (feature: any) => feature.properties?.quadkey == nft.quad_key
            )
          )
        ) {
          notifyError("This tile is already minted.");
          return;
        }
        setTiles(
          uniqBy(features.map(mapFeatureToTile), (tile) => tile.quad_key)
        );
      }
    },
    [startPoint, tiles, selecting, opacityValue]
  );

  const handleLoad = useCallback((event: any) => {
    initTiles(defaultZoom);
  }, []);

  const initTiles = useCallback(
    (zoom) => {
      if (zoom >= defaultZoom) {
        const tiles = cover.tiles(getExtentsGeom(mapRef.current.getBounds()), {
          min_zoom: gridZoom,
          max_zoom: gridZoom,
        });
        const features = tiles.map(getTileFeature);
        const newFeatures = [];
        for (let i = 0; i <= features.length; i++) {
          if (features[i]) {
            if (
              !features.some(
                (feature: any) =>
                  feature.properties.quadkey === features[i].properties.quadkey
              )
            ) {
              newFeatures.push(features[i]);
            }
          }
        }
        setFeatures([...features, ...newFeatures]);
      }
    },
    [mapRef, features]
  );

  const handleMove = useCallback((event) => {
    setViewState(event.viewState);
    const zoom = event.viewState.zoom;
    initTiles(zoom);
  }, []);

  const filter = useMemo(
    () => [
      "any",
      ...tiles.map((tileId) => ["==", ["get", "quadkey"], tileId?.quad_key]),
    ],
    [tiles]
  );

  const data: any = useMemo(() => turf.featureCollection(features), [features]);

  const bboxCoordinates: any = useMemo(() => {
    if (viewState.zoom >= 11 && opacityValue > 0) {
      const points = tiles
        .map((tile) => tile.polygon_coords[0].map((coord) => turf.point(coord)))
        .flat();
      const bbx = turf.bbox(turf.featureCollection(points));
      const bbxPolygon = turf.bboxPolygon(bbx);
      const bbxCoordinates = bbxPolygon.geometry.coordinates[0];

      return bbxCoordinates.slice(0, bbxCoordinates.length - 1).reverse();
    }
    return null;
  }, [tiles, viewState, opacityValue]);

  useInterval(() => setTime(Date.now()), 30 * 60000);

  useDebounce(
    () => {
      if (viewState.zoom >= 11 && opacityValue > 0) {
        setLoading(true);
        getNFTs(features.map((tile) => tile.properties?.quadkey))
          .then((tiles: NFT[]) => {
            setNfts(
              // @ts-ignore
              tiles.map((tile) => {
                const bbxCoordinates = JSON.parse(
                  // @ts-ignore
                  tile.polygon_coords
                ).reverse();
                let image = !tile.disabled
                  ? STORAGE_URL +
                    "/nft/" +
                    tile.quad_key +
                    ".webp" +
                    "?t=" +
                    time
                  : "/assets/images/illegal-placeholder.png";

                return {
                  ...tile,
                  image: image,
                  layer_info: {
                    ...imageLayer,
                    id: "l-" + tile.quad_key,
                    source: "s-" + tile.quad_key,
                  },
                  polygon_coords: bbxCoordinates.slice(
                    0,
                    bbxCoordinates.length - 1
                  ),
                };
              })
            );
          })
          .finally(() => setLoading(false));
      }
      return null;
    },
    500,
    [features, viewState, refreshCount, startLocation, opacityValue]
  );

  useEffect(() => {
    setPopupInfo(null);
  }, [refreshCount]);

  useEffect(() => {
    if (!isEmpty(startLocation) && mapRef?.current) {
      mapRef.current.flyTo({ center: startLocation, zoom: defaultZoom });
    }
  }, [startLocation, mapRef?.current]);

  return {
    tiles,
    data,
    viewState,
    handleClick,
    handleHover,
    handleLoad,
    handleMove,
    popupInfo,
    handleClosePopup,
    tileCount,
    filter,
    bboxCoordinates,
    nfts,
  };
};

export default useMap;
