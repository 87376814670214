import { fadeInVariants, notificationsVariants } from "animation/variants";
import { request } from "api/request";
import Button from "components/buttons";
import Dracula from "components/icons/Dracula";
import { Icon } from "components/icons/Icon";
import Notification from "components/notifications";
import { parseISO } from "date-fns";
import { motion } from "framer-motion";
import { useAtom } from "jotai";
import { notificationsAtom, userAtom } from "store/atoms";

interface NotificationCardProps {
  handleCloseNotification: () => void;
}

const NotificationsCard = ({
  handleCloseNotification,
}: NotificationCardProps) => {
  const [notifications] = useAtom(notificationsAtom);
  const [user] = useAtom(userAtom);

  const handleClose = (id: number) => {
    request("/notifications/view", { id, address: user.address });
  };

  const notificationsToShow = notifications.map((notification) => ({
    ...notification,
    date: parseISO(notification.created_at),
  }));

  return (
    <motion.div
      className="notifications-card-modal"
      variants={notificationsVariants}
      transition={{ duration: 0.3, type: "tween" }}
      exit="collapsed"
      {...{
        initial: "collapsed",
        whileInView: "expanded",
        viewport: { once: false },
      }}
    >
      <div className="notifications-card">
        <h2>Notifications</h2>
        <button
          className="notifications-card__close"
          onClick={handleCloseNotification}
        >
          <Icon width={40} name="close" />
        </button>
        {notificationsToShow.length > 0 && (
          <div>
            {notificationsToShow.map((notification, index) => (
              <Notification
                {...notification}
                handleClose={() => handleClose(notification.id)}
              />
            ))}
          </div>
        )}
        {notificationsToShow.length === 0 && (
          <div className="notifications-not-found">
            <Dracula />
            <motion.h1 variants={fadeInVariants}>
              Nothing to see here.
            </motion.h1>
            <motion.p variants={fadeInVariants}>
              Dracula will notify you when anything important comes up.{" "}
            </motion.p>
            <Button
              className="w-[13.75rem] mt-6 filled"
              onClick={handleCloseNotification}
              animate
            >
              <Icon name="close" />
              Close
            </Button>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default NotificationsCard;
