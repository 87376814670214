import { ReactComponent as ChevronDown } from "assets/icons/arrow-down.svg";
import cx from "classnames";
import React, { useState } from "react";
import RCSelect, { OnChangeValue } from "react-select";
import { Option } from "./types";
import { useSelectStyles } from "./useSelectStyles";

interface SelectProps {
	options: Option<any>[];
	label?: string;
	name?: string;
	className?: string;
	onChange?: (option: Option<any>) => void;
	defaultValue?: { value: string; label: string };
	startIcon?: React.ReactNode;
}

const CustomDropdownIndicator = ({ innerProps, innerRef, isFocused }: any) => {
	return <ChevronDown ref={innerRef} {...innerProps} className={isFocused ? "rotate-180" : ""} />;
};

const CustomSelectContainer = ({ innerProps, innerRef, isFocused, children, icon, isSmall }: any) => {
	return (
		<div ref={innerRef} {...innerProps} className={`select-container ${icon ? "with-icon" : ""}`}>
			<span className={`select-icon" : "bottom-3.5 "}`}>{icon}</span> {children}
		</div>
	);
};

const CustomOption = ({ innerRef, innerProps, label, isFocused, isSelected, isDisabled, ...rest }: any) => {
	return (
		<div
			className={`select-item ${cx({
				"is-selected": isSelected,
				"is-disabled": isDisabled,
			})}`}
			ref={innerRef}
			{...innerProps}>
			{label}
		</div>
	);
};

const Select = ({ label, name, options = [], defaultValue, onChange, startIcon, className = "" }: SelectProps) => {
	const [selectedOption, setSelectedOption] = useState<any>(defaultValue ?? options[0]);

	const handleChange = (selectedOption: OnChangeValue<Object, false>) => {
		// @ts-ignore
		if (onChange) onChange(selectedOption);
		setSelectedOption(selectedOption);
	};

	const selectStyles = useSelectStyles({ hasIcon: !!startIcon });

	return (
		<div className={`input-container select-container ${className}`}>
			{label && (
				<label className="input-label block mb-1.5">
					<span>{label}</span>
				</label>
			)}
			<RCSelect
				name={name}
				options={options}
				onChange={handleChange}
				styles={selectStyles}
				value={selectedOption}
				isSearchable
				components={{
					DropdownIndicator: CustomDropdownIndicator,
					Option: (props) => <CustomOption {...props} />,
					SelectContainer: (props) => <CustomSelectContainer {...props} icon={startIcon} />,
				}}
			/>
		</div>
	);
};

export default Select;
