import PopupContent from "components/popup";
import { AnimatePresence } from "framer-motion";
import useMap from "hooks/useMap";
import { useAtom } from "jotai";
import { forwardRef, useEffect } from "react";
import ReactMapGL, {
  GeolocateControl,
  Layer,
  Popup,
  Source,
} from "react-map-gl";
import { useMedia, useSessionStorage, useWindowSize } from "react-use";
import { loadingAtom, mapStyleAtom, opacityAtom } from "store/atoms";

import { gridDataLayer, highlightedDataLayer, imageLayer } from "./layers";

const Map = forwardRef((props, mapRef: any) => {
  const { height } = useWindowSize();
  const isPhone = useMedia("(max-width: 600px)");

  const [goBackToLocation, setGoBackToLocation] = useSessionStorage(
    "goBackToLocation",
    {}
  );

  const [mapStyle] = useAtom(mapStyleAtom);
  const [, setLoading] = useAtom(loadingAtom);
  const [opacityValue] = useAtom(opacityAtom);

  const {
    tiles,
    popupInfo,
    tileCount,
    viewState,
    handleClick,
    handleHover,
    handleMove,
    handleLoad,
    data,
    filter,
    bboxCoordinates,
    handleClosePopup,
    nfts,
  } = useMap(mapRef);

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <ReactMapGL
      {...viewState}
      ref={mapRef}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      mapStyle={mapStyle.value}
      style={{ width: "100vw", height: isPhone ? height - 72 : height }}
      onClick={handleClick}
      onMouseMove={handleHover}
      onMove={handleMove}
      onLoad={handleLoad}
      // onResize={handleLoad}
      interactiveLayerIds={["grid-layer"]}
    >
      <GeolocateControl position="top-left" />
      {/* <CustomCursor /> */}
      <Source type="geojson" id="grid-source" data={data} generateId>
        <Layer {...gridDataLayer} />
        <Layer {...highlightedDataLayer} filter={filter} />
      </Source>
      <AnimatePresence>
        {popupInfo && viewState.zoom >= 11.75 && (
          <Popup
            anchor="top"
            longitude={Number(popupInfo.longitude)}
            latitude={Number(popupInfo.latitude)}
            closeOnClick={false}
          >
            <PopupContent
              {...popupInfo}
              tiles={tiles}
              pricePerTile={800}
              totalPrice={(800 * tileCount).toFixed(2).toString()}
              handleClose={handleClosePopup}
            />
          </Popup>
        )}
      </AnimatePresence>
      {nfts.map((nft) => (
        <Source
          key={nft.quad_key + ""}
          id={nft.quad_key + ""}
          type="image"
          url={nft.image}
          // @ts-ignore
          coordinates={nft.polygon_coords}
        >
          {/* <Layer {...{ ...ownedHighlightedBorder, source: "s-" + nft.quad_key }} /> */}
          <Layer
            {...{
              ...nft.layer_info,
              paint: {
                "raster-opacity": opacityValue,
              },
            }}
          />
        </Source>
      ))}
      {bboxCoordinates && (
        <Source
          type="image"
          id="snoop"
          url="/assets/images/tile-placeholder.png"
          coordinates={bboxCoordinates}
        >
          <Layer {...imageLayer} />
        </Source>
      )}
    </ReactMapGL>
  );
});

export default Map;
