import { Icon } from "components/icons/Icon";
import { useAtom } from "jotai";
import { useMedia } from "react-use";
import { shareTileModalAtom } from "store/atoms";

interface ImageCardProps {
  image?: string;
  website?: string;
  nftId?: string;
  tiny?: boolean;
  handleGoToMap?: () => void;
}

const ImageCard = ({
  image = "/assets/images/placeholder.png",
  website,
  nftId,
  tiny,
  handleGoToMap,
}: ImageCardProps) => {
  const isPhone = useMedia("(max-width: 767px)");
  const [, setShareTileModal] = useAtom(shareTileModalAtom);

  const hasLinks = website || handleGoToMap;

  const handleShowShareModal = () => setShareTileModal({ isOpen: true });

  return (
    <div className={`image-card ${tiny ? "tiny" : ""}`}>
      <img
        src={image}
        width={tiny ? "100" : !isPhone ? "300" : "200"}
        height={tiny ? "100" : !isPhone ? "300" : "200"}
        alt=""
      />
      {nftId && (
        <a
          href={`https://explorer.elrond.com/nfts/${nftId}`}
          target="_blank"
          className="badge"
        >
          <Icon name="star" width={16} primary />
          {nftId}
        </a>
      )}
      {hasLinks && (
        <div className="image-card__links">
          {website && (
            <a href={website} target="_blank" rel="noopener noreferrer">
              <Icon name="chrome" primary />
            </a>
          )}
          {handleGoToMap && (
            <button onClick={handleGoToMap}>
              <Icon name="map-location" primary />
            </button>
          )}
          <button onClick={handleShowShareModal}>
            <Icon name="share" primary />
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageCard;
