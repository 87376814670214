import { useMemo } from "react";

interface UseSelectStylesProps {
	isSmall?: boolean;
	hasIcon?: boolean;
}

export const useSelectStyles = ({ hasIcon }: UseSelectStylesProps) => {
	const selectStyles = useMemo(
		() => ({
			container: (styles: any) => ({
				...styles,
			}),
			input: (styles: any) => ({
				...styles,
				color: "#ffffff",
			}),
			control: (styles: any, { isFocused }: any) => ({
				...styles,
				backgroundColor: isFocused ? "#30095E" : "#14073B",
				color: "#E0E3E5",
				border: "1px solid #8E2DE2",
				borderRadius: "4px",
				height: "50px",
				padding: hasIcon ? "0 14px 0 42px" : "0 14px",
				justifyContent: "center",
				boxShadow: "none",
			}),
			valueContainer: (styles: any) => ({
				...styles,
				padding: 0,
			}),
			indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
			menu: (styles: any) => ({
				...styles,
				backgroundColor: "#14073B",
				boxShadow:
					"0px 4px 6px -2px hsla(220, 43%, 11%, 0.05), box-shadow: 0px 12px 16px -4px hsla(220, 43%, 11%, 0.1)",
				marginTop: "6px",
				padding: "0",
				borderRadius: "8px",
			}),
			menuList: (styles: any) => ({
				...styles,
				height: "160px",
				overflowY: "auto",
			}),

			singleValue: (styles: any) => ({
				...styles,
				color: "#E0E3E5",
				padding: "12px 0",
			}),
		}),
		[]
	);
	return selectStyles;
};
