import { fadeInVariants, motionContainerProps } from "animation/variants";
import Button from "components/buttons";
import Dracula from "components/icons/Dracula";
import { Icon } from "components/icons/Icon";
import { motion } from "framer-motion";
import { Fragment } from "react";

interface NotFoundProps {
  children?: React.ReactNode;
}

const DefaultContent = () => (
  <Fragment>
    <motion.h1 variants={fadeInVariants}>
      Oh no. <br />
      Page not <span className="text-purple">found</span>.
    </motion.h1>
    <motion.p variants={fadeInVariants}>
      Maybe Dracula has broken this page. Go back to the map.{" "}
    </motion.p>
    <Button className="w-[13.75rem] mt-6 filled" link="/" animate>
      <Icon name="map" />
      Go To Map
    </Button>
    {/* <motion.h1 variants={fadeInVariants}>
			Maintenance <span className="text-purple">ongoing</span>.
		</motion.h1>
		<motion.p variants={fadeInVariants}>Phase 2 of testing will start soon. </motion.p> */}
  </Fragment>
);

const NotFound = ({ children }: NotFoundProps) => {
  return (
    <div className="not-found__container">
      <Dracula />
      <div className="not-found">{children ?? <DefaultContent />}</div>
    </div>
  );
};

export default NotFound;
