import {
  motionContainerProps,
  motionSlideUpContainerProps,
  scaleInVariants,
} from "animation/variants";
import { subscribeToNotifications } from "api/realtime-client";
import { getNotifications } from "api/supabase-client";
import { Icon } from "components/icons/Icon";
import Logo from "components/icons/Logo";
import { AnimatePresence, motion } from "framer-motion";
import { useAtom } from "jotai";
import { uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMedia } from "react-use";
import {
  notificationsAtom,
  showNotificationModalAtom,
  userAtom,
} from "store/atoms";
import { TransactionType } from "types/transactions";

function sortByDate(a: TransactionType, b: TransactionType) {
  if (a.timestamp < b.timestamp) {
    return 1;
  }
  if (a.timestamp > b.timestamp) {
    return -1;
  }
  return 0;
}

const Sidebar = () => {
  const [user] = useAtom(userAtom);
  const [showNotificationModal, setShowNotificationModal] = useAtom(
    showNotificationModalAtom
  );
  const [notifications, setNotifications] = useAtom(notificationsAtom);
  const [loadedNotifications, setLoadedNotifications] = useState(false);

  const isPhone = useMedia("(max-width: 767px)");

  const location = useLocation();
  const navigate = useNavigate();

  const isMap = location.pathname === "/";
  const isLeaderboard = location.pathname === "/leaderboard";
  const isRewards = location.pathname === "/rewards";

  const { avatar, address, herotag, username } = user;

  const handleGoToProfile = () => {
    closeNotificationModal();
    navigate("/users/" + (herotag ?? address));
  };

  const closeNotificationModal = () => setShowNotificationModal(false);

  useEffect(() => {
    if (user.address && loadedNotifications) {
      setLoadedNotifications(true);
      getNotifications(user.address).then((notifications) => {
        setNotifications(
          notifications.filter((notification: any) => !notification.viewed)
        );
      });
      subscribeToNotifications(user.address, (newNotification: any) => {
        const newNotifications = uniqBy(
          [...notifications, newNotification],
          (n: any) => n.id
        );
        setNotifications(
          newNotifications.filter((notification: any) => !notification.viewed)
        );
      });
    }
  }, [user, notifications]);

  return (
    <motion.nav className="sidebar" {...motionSlideUpContainerProps}>
      {!isPhone && (
        <AnimatePresence>
          <motion.a
            href="https://www.landboard.io/"
            variants={scaleInVariants}
            className="sidebar__logo-desktop"
          >
            <Logo />
          </motion.a>
        </AnimatePresence>
      )}
      <ul>
        <motion.li
          variants={scaleInVariants}
          className={isMap ? "text-purple" : ""}
          onClick={closeNotificationModal}
        >
          <Link to="/">
            <Icon name="map" primary={isMap} />
            {!isPhone && "Map"}
          </Link>
        </motion.li>
        <motion.li
          variants={scaleInVariants}
          className={isLeaderboard ? "text-purple" : ""}
          onClick={closeNotificationModal}
        >
          <Link to="/leaderboard">
            <Icon name="leaderboard" primary={isLeaderboard} />
            {!isPhone && "Leaderboard"}
          </Link>
        </motion.li>

        <motion.li variants={scaleInVariants} onClick={closeNotificationModal}>
          <Link to="/rewards">
            <Icon name="tile" primary={isRewards} />
            {!isPhone && "Rewards"}
          </Link>
        </motion.li>
        {/* <motion.li
          variants={scaleInVariants}
          className={isLeaderboard ? "text-purple" : ""}
          onClick={closeNotificationModal}
        >
          <Link to="/hunt">
            <Icon name="calendar" primary={isLeaderboard} />
            {!isPhone && "Treasure Hunt"}
          </Link>
        </motion.li> */}
        {/* <AnimatedTooltip label={"Coming soon."}>
          <motion.li
            variants={scaleInVariants}
            className="disabled"
            onClick={closeNotificationModal}
          >
            <Icon name="rocket" color="#2A445A" />
            {!isPhone && "Launchpad"}
          </motion.li>
        </AnimatedTooltip> */}

        <motion.li
          variants={scaleInVariants}
          onClick={closeNotificationModal}
          className="buy-land"
        >
          <a
            target="_blank"
            href="https://jungledex.com/swap?firstToken=EGLD&secondToken=LAND-40f26f"
            className="flex items-center justify-center"
          >
            <Icon name="transfer" />

            {!isPhone ? (
              <div className="flex">
                Buy LAND
                <div className="ml-2">
                  <Icon name="linkExternal" width={12} />
                </div>
              </div>
            ) : (
              <div className="absolute right-[-4px] top-[-4px]">
                <Icon name="linkExternal" width={12} />
              </div>
            )}
          </a>
        </motion.li>
        <AnimatePresence>
          {address && (
            <motion.li
              onClick={() => setShowNotificationModal(!showNotificationModal)}
            >
              <Icon name="notification" />
              {notifications.length > 0 && (
                <span className="notification-count">
                  {notifications.length > 9 ? "9+" : notifications.length}
                </span>
              )}
              {!isPhone && "Notifications"}
            </motion.li>
          )}
        </AnimatePresence>
      </ul>
      <div className="sidebar__social">
        <motion.a href="https://t.me/landboardio" variants={scaleInVariants}>
          <Icon name="telegram" />
        </motion.a>
        <motion.a
          href="https://twitter.com/landboard_io"
          variants={scaleInVariants}
        >
          <Icon name="twitter" />
        </motion.a>
      </div>
      <div className="sidebar__logo">
        <AnimatePresence>
          {address && (
            <motion.button
              {...motionContainerProps}
              variants={scaleInVariants}
              onClick={handleGoToProfile}
            >
              {!avatar && <Icon name="user" width={28} color="#7866C1" />}
              {avatar && <img src={avatar} alt="avatar" />}
              <span>
                {username ?? address.slice(0, 6) + "..." + address.slice(-4)}
              </span>
            </motion.button>
          )}
        </AnimatePresence>
        {/* {isPhone && (

          <AnimatePresence>
            <LogoSmall />
          </AnimatePresence>
        )} */}
      </div>
    </motion.nav>
  );
};

export default Sidebar;
