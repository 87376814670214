import { FillLayer } from "react-map-gl";

const defaultZoom = window.innerWidth < 768 ? 12 : 12.75;

export const gridDataLayer: FillLayer = {
	id: "grid-layer",
	type: "fill",
	source: "grid-source",
	// "source-layer": "romania-grid-cropped-2q8itl",
	paint: {
		"fill-outline-color": "rgba(137, 31, 226, 1)",
		"fill-color": "rgba(137, 31, 226, 0.10)",
	},
	minzoom: defaultZoom,
	maxzoom: 20,
};

export const highlightedDataLayer: FillLayer = {
	id: "grid-layer-highlighted",
	type: "fill",
	source: "grid-source",
	paint: {
		"fill-outline-color": "rgba(137, 31, 226, 1)",
		"fill-color": "rgba(137, 31, 226, 0.33)",
	},
	minzoom: defaultZoom,
	maxzoom: 20,
};

export const ownedHighlightedBorder: FillLayer = {
	id: "grid-layer-owned-highlighted",
	type: "fill",
	paint: {
		"fill-color": "rgba(137, 31, 226, 0.33)",
	},
	minzoom: defaultZoom,
	maxzoom: 20,
};

export const imageLayer: any = {
	id: "selection-layer",
	type: "raster",
	source: "selection",
	paint: {
		"raster-opacity": 0.8,
	},
	minzoom: defaultZoom,
	maxzoom: 20,
};
